
import React from 'react';
import { USER_PROFILE } from '../../../redux/action'
import { useQuery, gql } from "@apollo/client";
import { useSelector, useDispatch } from 'react-redux'
import Dashboardimg from "../../../assets/img/dashboard.png";
import Profile from '../../../assets/img/profile.png'
import './style.scss';
import { Img, Heading, AdminPanel, Widget, Search, Export } from '../../../shared';
const USER_QUERY = gql`
query{
    profile_view{
      name
      lastname
      email
      userId
      area
      post
      pin
      verificationtype
      subscribed
      isDue
      isSubMonth
      assetManagement
      {
        filename
      }
      account_refId{
        action
        _id
        role{
          _id
          name
        }
      }
    }
  }
`;
const Dashboard = () => {


  return (

    <section id="Dashboard">
      <div className='row top-dash-widget'>
        <div className="col-md-3">
          <Widget title="Total members" value={0} />
        </div>
        <div className="col-md-3">
          <Widget title="Waiting for Admin" value={0} />
        </div>
        <div className="col-md-3">
          <Widget title="Email verification" value={0} />
        </div>
        <div className="col-md-3">
          <Widget title="Active" value={0} />
        </div>
      </div>

    </section>



  )
}


export default Dashboard;
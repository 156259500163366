import React, { forwardRef, useRef, useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col, Alert, Modal } from "react-bootstrap";

import { Heading, ImageUpload, Img } from "../../shared";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const PROFILE_UPDATE_MUTATION = gql`
  mutation profile_update_admin(
    $name: String
    $lastname: String
    $date_of_birth: String
    $blood_group: String
    $blood_donation: String
    $ex_ServiceMan: String
    $contact: String
    $whatsapp: String
    $address: String
    $post: String
    $pin: String
    $district: String
    $subscribed: String
    $area: String
    $date_of_marriage: String
    $occupation: String
    $_id: String
    $family: JSON
  ) {
    profile_update_admin(
      obj: {
        name: $name
        lastname: $lastname
        date_of_birth: $date_of_birth
        blood_group: $blood_group
        blood_donation: $blood_donation
        district: $district
        ex_ServiceMan: $ex_ServiceMan
        contact: $contact
        whatsapp: $whatsapp
        address: $address
        post: $post
        pin: $pin
        family: $family
        subscribed: $subscribed
        area: $area
        occupation: $occupation
        date_of_marriage: $date_of_marriage
        _id: $_id
      }
    ) {
      status
    }
  }
`;

const USER_ID_MUTATION = gql`
  mutation profile_update_admin_userId(
    $_id: String
    $subscribed: String
    $action: String
  ) {
    profile_update_admin_userId(
      obj: { _id: $_id, subscribed: $subscribed, action: $action }
    ) {
      status
    }
  }
`;

const STATUS_EDIT_MUTATION = gql`
  mutation updateStatusForUser($action: String, $id: String) {
    updateStatusForUser(obj: { id: $id, action: $action }) {
      status
    }
  }
`;
const UPDATE_PROFILE_IMAGE_MUTATION = gql`
  mutation profile_update_profile_image($_id: String, $file: String) {
    profile_update_profile_image(obj: { _id: $_id, file: $file }) {
      status
    }
  }
`;
const UPDATE_DOC_MUTATION = gql`
  mutation profile_update_document_image($_id: String, $file: String) {
    profile_update_document_image(obj: { _id: $_id, file: $file }) {
      status
    }
  }
`;

const UpdateProfile = forwardRef((props, ref) => {
  const [updateProfile] = useMutation(PROFILE_UPDATE_MUTATION);
  let profileEdit = false;
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState("");
  const [inputForm, setForm] = useState({
    name: null,
    ex_ServiceMan: null,

    date_of_birth: null,
    blood_group: null,
    contact: null,
    whatsapp: null,
    address: null,
    post: null,
    pin: null,
    district: null,
    blood_donation: null,
    lastname: null,
    subscribed: null,
    area: null,
    date_of_marriage: null,
    occupation: null,
    docFile: null,
    _id: null,
    family: null,
  });
  const [fields, setFields] = useState(props.obj.family);

  useEffect(() => {
    setForm({
      name: props.obj.name ? props.obj.name : null,
      ex_ServiceMan: props.obj.ex_ServiceMan,

      date_of_birth: props.obj.date_of_birth ? props.obj.date_of_birth : null,
      blood_group: props.obj.blood_group ? props.obj.blood_group : null,
      contact: props.obj.contact ? props.obj.contact : null,
      whatsapp: props.obj.whatsapp ? props.obj.whatsapp : null,
      address: props.obj.address ? props.obj.address : null,
      post: props.obj.post ? props.obj.post : null,
      pin: props.obj.pin ? props.obj.pin : null,
      district: props.obj.district ? props.obj.district : null,
      blood_donation: props.obj.blood_donation
        ? props.obj.blood_donation
        : "no",
      lastname: props.obj.lastname ? props.obj.lastname : null,
      subscribed: props.obj.subscribed,
      area: props.obj.area,
      date_of_marriage: props.obj.date_of_marriage,
      occupation: props.obj.occupation,

      docFile: props.obj.docFile ? props.obj.docFile.filename : null,
      files: props.obj.assetManagement
        ? props.obj.assetManagement.filename
        : null,
      _id: props.obj._id ? props.obj._id : null,
      family: props.obj.family ? props.obj.family : null,
    });
  }, [props]);

  let formChange = (event) => {
    profileEdit = true;
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  function dateofbirthDateChange(date) {
    const values = { ...inputForm };
    values["date_of_birth"] = date;
    setForm(values);
  }
  function dateofmarriageDateChange(date) {
    const values = { ...inputForm };
    values["date_of_marriage"] = date;
    setForm(values);
  }
  function formCheckChange(event) {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;

    if (event.target.value.toLowerCase() === "true") {
      values[event.target.name] = true;
    }
    if (event.target.value.toLowerCase() === "false") {
      values[event.target.name] = false;
    }
    setForm(values);
  }
  function handleAdd() {
    const values = JSON.parse(JSON.stringify(fields));
    values.push({ name: null, value: null });
    setFields(values);
  }
  function handleChange(i, event) {
    const values = JSON.parse(JSON.stringify(fields));
    values[i].name = event.target.value;
    setFields(values);
  }

  function handleChangeSelect(i, event) {
    const values = JSON.parse(JSON.stringify(fields));
    values[i].relation = event.target.value;
    setFields(values);
  }

  function handleChangeBSelect(i, event) {
    const values = JSON.parse(JSON.stringify(fields));
    values[i].blood_group = event.target.value;
    setFields(values);
  }
  function handleRemove(i) {
    const values = JSON.parse(JSON.stringify(fields));
    values.splice(i, 1);
    setFields(values);
  }
  function familyDateChange(i, event, date) {
    const values = JSON.parse(JSON.stringify(fields));
    values[i]["date_of_birth"] = date;
    setFields(values);
  }
  let updateP = (event) => {
    event.preventDefault();
    const values = { ...inputForm };
    values["family"] = fields;
    debugger;
    values.id = props.obj.account_refId._id;
    //updateStatus({ variables: { ...obj } }).then(res=>{
    updateProfile({ variables: { ...values } }).then((res) => {
      if (res.data.profile_update_admin.status) {
        setIsValid("Updated  Successfully");
        props.refetch();
        props.handleClose();
      } else {
        setIsValid("please contact Admin ");
      }
    });

    setTimeout(() => {
      setIsValid("");
    }, 1500);
    //})
  };
  return (
    <>
      <form method="post" onSubmit={updateP}>
        <Form.Group className="" controlId="formBasicEmail">
          <Form.Label>
            Name <span className="mandatory">*</span>(Text and space are
            allowed)
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            value={inputForm.name}
            name="name"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.name}</span>
        </Form.Group>
        <Form.Group className="mt-3 p-0 p-0" controlId="formBasicEmail">
          <Form.Label>Last Name (Text and space are allowed)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Last Name"
            value={inputForm.lastname}
            name="lastname"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.lastname}</span>
        </Form.Group>
        <Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
          <Form.Label>
            Contact Number <span className="mandatory">*</span>(10 digit number)
          </Form.Label>
          <Form.Control
            type="text"
            pattern="[0-9]+"
            title="please enter number only"
            value={inputForm.contact}
            minlength="10"
            maxlength="10"
            placeholder="Contact Number"
            name="contact"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.contact}</span>
        </Form.Group>
        <Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
          <Form.Label>
            Whatsapp Number <span className="mandatory">*</span>(10 digit
            number)
          </Form.Label>
          <Form.Control
            type="text"
            pattern="[0-9]+"
            title="please enter number only"
            value={inputForm.whatsapp}
            minlength="10"
            maxlength="10"
            placeholder="Whatsapp Number"
            name="whatsapp"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.whatsapp}</span>
        </Form.Group>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            className="mt-3 p-0"
            controlId="formBasicPassword"
          >
            {/* <Form.Label>
              Date of Birth <span className="mandatory">*</span>
            </Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Date of Marriage"
              selected={new Date(inputForm.date_of_birth)}
              onChange={(date, e) => dateofbirthDateChange(date)}
            /> */}
            {/* <Form.Control type="date" placeholder="Date of Birth" name="date_of_birth" value={inputForm.date_of_birth}  onChange={(e) => formChange(e)} /> */}
            <span className="mandatory">{errors.date_of_birth}</span>
          </Form.Group>
          <Form.Group
            as={Col}
            className="mt-3 p-0"
            controlId="formBasicPassword"
          >
            {/* <Form.Label>Date of Marriage</Form.Label>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Date of Marriage"
              selected={new Date(inputForm.date_of_marriage)}
              onChange={(date, e) => dateofmarriageDateChange(date)}
            /> */}

            {/* <Form.Control type="date" placeholder="Date of Marriage" name="date_of_marriage" value={inputForm.date_of_marriage}  onChange={(e) => formChange(e)} /> */}
            <span className="mandatory">{errors.date_of_marriage}</span>
          </Form.Group>
        </Row>

        {/* <Form.Group className="mt-3 p-0" controlId="file">
				<Form.Label>Profile Picture<span className="mandatory">*</span></Form.Label>
				<ImageUpload ref={upload_Ref} />
				<span className='mandatory'>{errors.upload_Ref}</span>
			</Form.Group> */}

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>
            Blood Group <span className="mandatory">*</span>
          </Form.Label>
          <Form.Select
            name="blood_group"
            onChange={(e) => formChange(e)}
            value={inputForm.blood_group}
          >
            <option value="none" selected>
              Select Blood Group
            </option>
            <option value="A +ve">A +ve</option>
            <option value="A -ve">A -ve</option>
            <option value="B +ve">B +ve</option>
            <option value="B -ve">B -ve</option>
            <option value="AB +ve">AB +ve</option>
            <option value="AB -ve">AB -ve</option>
            <option value="O +ve">O +ve</option>
            <option value="O -ve">O -ve</option>
          </Form.Select>
          <span className="mandatory">{errors.blood_group}</span>
        </Form.Group>
        <Row className="mt-3 p-0">
          <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
            <Form.Label>
              Willing for blood donation? <span className="mandatory">*</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="donations">
         
              {inputForm.blood_donation}
              <Form.Check
                type="radio"
                label="No"
                value="no"
                id="custom-switch1"
                name="blood_donation"
                checked={inputForm.blood_donation == "no" ? true : false}
                onChange={(e) => formCheckChange(e)}
              />
              <Form.Check
                type="radio"
                label="Yes"
                value="yes"
                id="custom-switch2"
                name="blood_donation"
                checked={inputForm.blood_donation == "yes" ? true : false}
                onChange={(e) => formCheckChange(e)}
              />
            </div>
            <span className="mandatory">{errors.blood_donation}</span>
          </Form.Group>
        </Row>
        <Row className="mt-3 p-0">
          <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
            <Form.Label>
              Ex-ServiceMan?<span className="mandatory">*</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="ex_service">
              <Form.Check
                type="radio"
                label="no"
                id="custom-switch1"
                value="no"
                checked={inputForm.ex_ServiceMan == "no" ? true : false}
                name="ex_ServiceMan"
                onChange={(e) => formCheckChange(e)}
              />
              <Form.Check
                type="radio"
                label="yes"
                id="custom-switch2"
                value="yes"
                checked={inputForm.ex_ServiceMan == "yes" ? true : false}
                name="ex_ServiceMan"
                onChange={(e) => formCheckChange(e)}
              />
            </div>
            <span className="mandatory">{errors.ex_ServiceMan}</span>
          </Form.Group>
        </Row>
        {/* *********************Occupation*********************/}

        <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
          <Form.Label>
            Occupation <span className="mandatory">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Current Occupation"
            name="occupation"
            value={inputForm.occupation}
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.occupation}</span>
        </Form.Group>

        {/* *********************in service?*********************/}
        {/* <Row className="mt-3 p-0">
				<Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
					<Form.Label>Currently in Service?<span className="mandatory">*</span></Form.Label>
				</Form.Group>
				<Form.Group as={Col}>
					<div className='ex_service'>
						<Form.Check type="radio" label="no" id="custom-switch1"  checked={inputForm.in_Service=='no'?true:false}  value="no" name="in_Service" onChange={(e) => formCheckChange(e)} />
						<Form.Check type="radio" label="yes" id="custom-switch2" value="yes" checked={inputForm.in_Service=='yes'?true:false} name="in_Service" onChange={(e) => formCheckChange(e)} />
					</div>
					<span className='mandatory'>{errors.in_Service}</span>
				</Form.Group>

			</Row> */}
        <Form.Group className="mt-2" controlId="formBasicPassword">
          <Form.Label>
            Address <span className="mandatory">*</span>(Text and space are
            allowed)
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="House Name"
            name="address"
            value={inputForm.address}
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.address}</span>
        </Form.Group>

        <Row className="mt-3 p-0">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>
              Area <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Area"
              name="area"
              value={inputForm.area}
              onChange={(e) => formChange(e)}
            />
            <span className="mandatory">{errors.area}</span>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="formGridCity">
            <Form.Label>
              District <span className="mandatory">*</span>
            </Form.Label>
            <Form.Select
              className="mb-3"
              name="district"
              value={inputForm.district}
              onChange={(e) => formChange(e)}
            >
              <option value="none" selected>
                Select District
              </option>
              <option value="kozhikode">kozhikode</option>
            </Form.Select>
            <span className="mandatory">{errors.district}</span>
          </Form.Group>
        </Row>

        <Row className="mt-3 p-0">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>
              Post <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Post"
              name="post"
              value={inputForm.post}
              onChange={(e) => formChange(e)}
            />
            <span className="mandatory">{errors.post}</span>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>
              Pin <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]+"
              title="please enter number only"
              minlength="6"
              maxlength="6"
              value={inputForm.pin}
              placeholder="Pin"
              name="pin"
              onChange={(e) => formChange(e)}
            />
            <span className="mandatory">{errors.pin}</span>
          </Form.Group>
        </Row>

        {/**************Family Details****************/}
        <Form.Label>Add Family Members</Form.Label>

        <i onClick={() => handleAdd()} class="fas fa-plus-circle mt-3 p-0"></i>
        {fields.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <Row className="col">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="Name"
                    name="name"
                    value={field.name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Relation</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="relation"
                    value={field.relation}
                    onChange={(e) => handleChangeSelect(idx, e)}
                  >
                    <option value="none" selected>
                      Select Relation
                    </option>
                    <option value="Wife" selected>
                      Wife
                    </option>
                    <option value="Husband">Husband</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Grand Mother">Grand Mother</option>
                    <option value="Grand Father">Grand Father</option>
                  </Form.Select>
                  <i
                    onClick={() => handleRemove(idx)}
                    class="fas fa-trash-alt"
                  ></i>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Blood Group <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    name="blood_group"
                    value={field.blood_group}
                    onChange={(e) => handleChangeBSelect(idx, e)}
                  >
                    <option value="none" selected>
                      Select Blood Group
                    </option>
                    <option value="A+ve">A +ve</option>
                    <option value="A-ve">A -ve</option>
                    <option value="B+ve">B +ve</option>
                    <option value="B-ve">B -ve</option>
                    <option value="AB+ve">AB +ve</option>
                    <option value="AB-ve">AB -ve</option>
                    <option value="O+ve">O +ve</option>
                    <option value="O-ve">O -ve</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Date Of Birth <span className="mandatory">*</span>
                  </Form.Label>

                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Click to select a date"
                    selected={new Date(fields[idx].date_of_birth)}
                    onChange={(date, e) => familyDateChange(idx, e, date)}
                  />
                </Form.Group>
              </Row>
            </div>
          );
        })}

        <input
          type="submit"
          className="add-btn me-2 btn btn-primary"
          value="Update Profile"
        />
      </form>
    </>
  );
});

export default UpdateProfile;

import {USER_ROLE } from '../action'
const initialState = {
    isLoading: false,
   isrole:[{
      name:null
   }],

 };
 const role = (state = initialState, action) => {
    switch (action.type) {
       case USER_ROLE:
         return { ...state, isrole: action.payload}
       default:
          return state;
    }
 }
 export default role;
import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Img, DateSpan } from "../../shared";

import { Link } from "react-router-dom";
// import Img from './img'
// import DateSpan from './date'
const NEWS_S_QUERY = gql`
  {
    news_list(obj: { page: 1, skip: 0 }) {
      title
      body
      createdAt
      _id
      assetManagement {
        filename
      }
    }
  }
`;
// const EVENT_S_QUERY = gql`
//   {
//     event_list(obj:{page:1 skip:0}){
//       title
//       body
//       createdAt
//       start_date
//       _id
//       assetManagement{
//         filename}
//     }
//   }
// `;
export default function Onenews() {
  const { data, loading, error, refetch } = useQuery(NEWS_S_QUERY);

  let { body, _id, createdAt, title, assetManagement } = data
    ? data.news_list[0]
    : {
        body: null,
        _id: null,
        createdAt: null,
        title: null,
        assetManagement: [{ filename: null }],
      };
  console.log(data, _id);
  return (
    <section>
      <div className="story">
        <div className="row">
          <div className=" col-12 col-sm-12 col-lg-6 col-md-12  col-lg-6">
            <Img
              src={assetManagement ? assetManagement.filename : null}
              width={180}
              height={180}
            />
          </div>
          <div className=" col-12 col-sm-12 col-lg-6 col-md-12 col-lg-6">
            <span className="created-by">
              {" "}
              <DateSpan dat={createdAt} type="MMMM Do YYYY" />{" "}
            </span>
            <Link className="nav-link" to={"/news_details/" + _id}>
              {" "}
              <h1>{title} </h1>{" "}
            </Link>
            <p>{body}</p>

            <div className="footer-area">
              <span className="more">
                {" "}
                <Link className="more-btn" to={"/news_details/" + _id}>
                  {" "}
                  More{" "}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState, Fragment, useEffect } from "react";
import { Heading } from "../../../shared";
import AdminPanel from "../../../shared/adminpanel";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Spinner, Button, Alert } from "react-bootstrap";
import moment from "moment";
import "./style.scss";
import Add from "./Add";
import AclPermission from "../../../lib/acl";
const EVENT_QUERY = gql`
  {
    event_list(obj: { page: 0, skip: 0 }) {
      title
      body
      start_date
      end_date
      _id
      createdAt
      status
      assetManagement {
        filename
      }
    }
  }
`;
const EVENT_DELETE_QUERY = gql`
  mutation event_deleteById($id: ID!) {
    event_deleteById(id: $id) {
      status
    }
  }
`;

const AdminEventMangement = () => {
  const [deleteEvent] = useMutation(EVENT_DELETE_QUERY);
  const { data, loading, error, refetch } = useQuery(EVENT_QUERY);

  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({
    title: null,
    body: null,
    start_date: null,
    end_date: null,
    files: null,
  });
  const [isValid, setIsValid] = useState("");

  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return `Error! ${error.message}`;

  const handleDeleteClick = (_id) => {
    deleteEvent({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
        refetch();
      });
  };
  const handleEdit = (obj) => {
    setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };
  //   const columns = [
  //     {
  //         name: 'Title',
  //         cell:(row) =><span className="table_link">{row.title}</span>,
  //         sortable: true,
  //     },
  //     {
  //       name: 'Start Date',
  //       selector: row => row.start_date,
  //     },
  //     {
  //       name: 'End Date',
  //       selector: row => row.end_date,
  //     },
  //     {
  //       name: 'Status',
  //       cell: (row) =>
  //         <span className={row.status ? 'status published' : 'status not-published'}> {row.status ? 'published' : 'not-published'}</span>
  //       ,
  //       sortable: true,
  //     },
  //     {
  //       name: 'Edit',
  //       cell: (row) =>
  //         <div className="edit" onClick={(_id) => handleEdit(row._id)}>
  //         </div>
  //       ,
  //       ignoreRowClick: true,
  //       allowOverflow: true,
  //       button: true,
  //     },
  //     {
  //       name: 'Delete',
  //       cell: (row) => <div className="trash" onClick={(_id)=>handleDeleteClick(row._id)}>
  //       </div>,
  //       ignoreRowClick: true,
  //       allowOverflow: true,
  //       button: true,
  //     }
  // ];

  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    {
      name: "Start Date",
      cell: (row) => (
        <span>{moment(row.start_date).format(" DD-MM-YYYY")}</span>
      ),
      sortable: true,
    },
    {
      name: "End Date",
      cell: (row) => <span>{moment(row.end_date).format(" DD-MM-YYYY")}</span>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(_id) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      start_date: null,
      end_date: null,
      files: null,
    });
    setShow(true);
    setId(null);
    setEditAble(false);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };
  return (
    <div id="event-mangement">
      <AclPermission isActive={["admin", "President", "Media Cell"]} showMessage={false}>
        <div className=" tbl">
          {isValid ? <Alert variant="success">{isValid}</Alert> : ""}
          <Button className={"add-btn"} onClick={handleShow}>
            create
          </Button>

          <AdminPanel
            show={show}
            handleClose={(e) => {
              handleClose();
            }}
            title="Event Details"
          >
            <Add
              editAble={editAble}
              obj={obj}
              id={_id}
              refetch={(e) => {
                refetch();
              }}
              handleClose={(e) => {
                handleClose();
              }}
            />
          </AdminPanel>
          <Heading title="Event Management" />
          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data ? data.event_list : []}
          />
        </div>
      </AclPermission>
    </div>
  );
};

export default AdminEventMangement;

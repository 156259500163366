


import React from 'react'

export default function Widget({ title, icon, value }) {
    return (

        <div className='widget card border-left-primary shadow h-100 py-2 p-2'>
            <span className={'icon ' + icon}> </span>
            <h3 className='text-xs font-weight-bold text-primary text-uppercase mb-1'>{title} </h3>
            <div className='value'>{value}</div>
        </div>
    )
}

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
} from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Button, DropdownButton, Dropdown, Alert, Form } from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import { Heading } from "../../../shared";

import DataTable from "react-data-table-component";
//const GalleryCard = lazy(() => import('./gallery_Card'));

//const Add = lazy(() => import('./Add'))

const GALLERY_FOLDER_GET = gql`
  {
    getFolderList {
      title
      folderYear
      id
    }
  }
`;

const GALLERY_FOLDER_CREATE = gql`
  mutation folderCreate($title: String!, $folderYear: String) {
    folderCreate(obj: { title: $title, folderYear: $folderYear }) {
      status
    }
  }
`;
const DELETE_FOLDER_MUTATION = gql`
  mutation folderDelete($folderDeleteId: ID) {
    folderDelete(id: $folderDeleteId) {
      status
    }
  }
`;
function Folders() {
  const { data, loading, error, refetch } = useQuery(GALLERY_FOLDER_GET, {});
  const [deleteFolder] = useMutation(DELETE_FOLDER_MUTATION);
  const [createGallery] = useMutation(GALLERY_FOLDER_CREATE);
  const [inputForm, setForm] = useState({
    title: null,
    folderYear: null,
  });
  let edit = () => {};
  let deletes = (row) => {
    deleteFolder({ variables: { folderDeleteId: row.id } }).then((res) => {
      alert("Deleted successfully");
      refetch();
    });
  };

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };

  const submitForm = () => {
    const values = { ...inputForm };

    createGallery({ variables: { ...values } }).then((res) => {
      alert("Created successfully");
      refetch();
    });
  };

  const columns = [
    {
      name: "Folder Name",
      cell: (row) => row.title,
      //sortable: true,
      width: "60%",
    },
    {
      name: "Year",
      cell: (row) => new Date(row.folderYear).getFullYear(),
      //sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Button className={"add-btn me-2"} onClick={() => edit(row)}>
            edit
          </Button>
          <Button className={"add-btn me-2"} onClick={() => deletes(row)}>
            delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div id="galleryFolder">
      <div className="tbl">
        <Heading title="Gallery folder" />
        <div>
          <Form.Group className="mt-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="title"
              value={inputForm.title}
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="formBasicEmail">
            <Form.Label>
              Date <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              placeholder="Enter Year"
              name="folderYear"
              value={inputForm.folderYear}
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="mt-3"
            onClick={(e) => submitForm()}
          >
            Save
          </Button>
        </div>

        <DataTable
          columns={columns}
          data={data ? data.getFolderList : []}
          defaultSortField="name"
          striped
          pagination
        />
        <div />
      </div>
    </div>
  );
}

export default Folders;

import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import AclPermission from "../../lib/acl";
const AdminNavbar = () => {
  return (
    <>
      <div className="mob-menu">
        <nav class="navbar">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
            <span class="navbar-toggler-icon"></span>
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav adminNav ">
              <Link className="nav-link" to="/">
                {" "}
                <div
                  class="sidebar-brand-text mx-3"
                  style={{
                    color: " #fff",
                    textAlign: " center",
                    fontSize: "25px",
                    fontWeight: 500,
                  }}
                >
                  Calicutdefence
                </div>
              </Link>

              {/* <li class="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/dashboard"
                >
                  <span>Dashboard</span>{" "}
                </NavLink>
              </li> */}

              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/user-list"
                >
                  {" "}
                  <span>Users List</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/group"
                >
                  {" "}
                  <span>Group</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/payment-manage"
                >
                  {" "}
                  <span>Payment Management</span>{" "}
                </NavLink>
              </li>

              <li class="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/admin-activities"
                >
                  {" "}
                  <span>Activities</span>{" "}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/admin-news"
                >
                  {" "}
                  <span>News</span>{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/admin-gallery"
                >
                  {" "}
                  <span>Gallery </span>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/assets"
                >
                  {" "}
                  <span>Assets</span>{" "}
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/admin-event"
                >
                  {" "}
                  <span>Event </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/admin-donation"
                >
                  {" "}
                  <span>Donation</span>{" "}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/adadministration"
                >
                  {" "}
                  <span>Administration</span>{" "}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/acl"
                >
                  {" "}
                  <span>Acl</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/admin/Ads"
                >
                  {" "}
                  <span>Ads</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="desktop-menu">
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion adminNav">
          <Link className="nav-link" to="/">
            {" "}
            <div
              class="sidebar-brand-text mx-3"
              style={{
                color: " #fff",
                textAlign: " center",
                fontSize: "1.rem",
                fontWeight: 500,
              }}
            >
              Calicutdefence
            </div>
          </Link>

          {/* <li class="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/dashboard"
            >
              <span>Dashboard</span>{" "}
            </NavLink>
          </li> */}

          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/user-list"
            >
              {" "}
              <span>Users List</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/group"
            >
              {" "}
              <span>Group</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/payment-manage"
            >
              {" "}
              <span>Payment Management</span>{" "}
            </NavLink>
          </li>

          <li class="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/admin-activities"
            >
              {" "}
              <span>Activities</span>{" "}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/admin-news"
            >
              {" "}
              <span>News</span>{" "}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/admin-gallery"
            >
              {" "}
              <span>Gallery </span>
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/assets"
            >
              {" "}
              <span>Assets</span>{" "}
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/admin-event"
            >
              {" "}
              <span>Event </span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/admin-donation"
            >
              {" "}
              <span>Donation</span>{" "}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/adadministration"
            >
              {" "}
              <span>Administration</span>{" "}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/acl"
            >
              {" "}
              <span>Acl</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/admin/Ads"
            >
              {" "}
              <span>Ads</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdminNavbar;

import React from "react";
import "./style.scss";
import Onenews from "./one";
import { Link } from "react-router-dom";
import { DateSpan } from "../../shared";
import { Img } from "../../shared";

import { useQuery, gql, useMutation } from "@apollo/client";

const NEWS_QUERY = gql`
  {
    news_list(obj: { page: 0, skip: 1 }) {
      title
      body
      createdAt
      _id
      assetManagement {
        filename
      }
    }
  }
`;
// const EVENT_QUERY = gql`
//   {
//     event_list(obj:{page:3,skip:0}){
//       title
//       body
//       start_date
//       _id
//     }
//   }
// `;
const ADS_QUERY = gql`
  {
    ads_list_byuser(obj: { page: 10, skip: 0, place: "news_left" }) {
      title
      _id
      assetManagement {
        filename
      }
    }
  }
`;
export default function News() {
  const { data: ads } = useQuery(ADS_QUERY);
  let advt = [];
  if (ads) {
    if (ads.ads_list_byuser) {
      advt = ads.ads_list_byuser.map((item, key) => {
        return (
          <Img
            src={item.assetManagement ? item.assetManagement.filename : ""}
            width={300}
            height={300}
            alt="CALICUT DEFENCE"
            key={key}
          />
        );
      });
    }
  }
  const { data, loading, error, refetch } = useQuery(NEWS_QUERY);

  const news = data
    ? data.news_list.map((data, id) => (
        <News_All_list
          key={id}
          obj={data}
          className={"col-6 col-sm-6 col-md-6"}
        />
      ))
    : "null";

  return (
    <div id="news">
      <section className="news">
        <div className="container">
          <h1> News</h1>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-9">
              <Onenews />
              <h6> News</h6>
              <p>Latest news happening under Calicut Defence Trust and Care</p>

              <div className="row">{news}</div>
            </div>
            <div className="col-12 col-sm-12 col-md-3">{advt}</div>
          </div>
        </div>
      </section>
    </div>
  );
}

function News_All_list({ className, obj }) {
  return (
    <div className={className}>
      <div className="news-box me-2">
        <Img
          src={obj.assetManagement ? obj.assetManagement.filename : ""}
          width={180}
          height={180}
        />
        <h5>{obj.title} </h5>
        <div className="footer-area">
          <span className="created-by">
            <DateSpan dat={obj.createdAt} type="MMMM Do YYYY" />{" "}
          </span>
          <span className="more">
            {" "}
            <Link className="more-btn" to={"/news_details/" + obj._id}>
              More
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
//import placeholder  from '../assets/img/place_holder.jpg';
export default function Img({src,width,height,format}) {
    return (
<div className='img-pre'> 
<img className='img-fluid' src={`${process.env.REACT_APP_STATIC}/${src?src:'no-img.png'}`} alt="calicutdefence " />
        
</div>
     
   
    )
}
  

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
export default function AclPermission({isActive,children,is_show=true}) {
    const role = useSelector((state) => state.role.isrole);

    let message=is_show?<div className='accessdenied'> <h1>"Access Denied" </h1> <p> please contact admin </p></div>:''

// let arrys=[];
// if(role){
//     arrys = role.filter((val, index) => {
//         console.log('index', index) // Stops at array1.length - 1
//         return props.isActive.includes(val)
//       })
//       console.log(arrys)
// }
function getMatch(a, b) {
    var matches = [];
	
    if(b[0]=='all'){
        matches.push( 'all' );
      return matches
    }
	
	let roles= b.map(v => v.toLowerCase().replace(/ /g, "")); ///b.toLocaleString().toLowerCase().replace(/ /g, "") //.split(',')
    for ( var i = 0; i < a.length; i++ ) {
		let roleName=a[i].name;
		  let string = roleName?.toLowerCase().replace(/ /g, "");

		if( roles.includes(string)){
			 matches.push( string );
		}
		
    }
    return matches;
}


    

    return (<> 
   {getMatch(role, isActive).length>=1?children:message}

  
    </>
        
     
    )
}

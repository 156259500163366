import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

import user from '../reducers/index'

 import profile from '../reducers/user'
 import role from '../reducers/role'
// import BookingReducer from '../reducers/booking'
import thunk from 'redux-thunk';

const Middleware = [thunk]

const allReducers = combineReducers({
    user,
    profile,
    role
})
const store = createStore(allReducers, compose(applyMiddleware(thunk),
//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
)

//
export default store
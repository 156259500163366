

import React from 'react'
import './style.scss';
export default function Terms() {
    return (
        <div id="Terms">
            <div className="banner">
                <h1>Terms</h1>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Far far away, behind the word

                    mouConsonantia, there live the blind textsFar far away, behind the word mountains, far from the co
                </p>
            </div>
            <div className="container">
                <h4>  Use of the website is provided by CARE India subject to the following Terms and Conditions</h4>

                <ul>
                    <li>


                        <p>By using this website you are accepting the terms and conditions. They are effective from your first visit. </p>
                    </li>

                    <li>

                        <p> CARE India may change these terms and conditions from time to time; your use of the website indicates your acceptance of the terms and conditions at the time of use.
                        </p>
                    </li>

                    <li>

                        <p>CARE India reserves the right to change the content of the website at any time.</p>
                    </li>

                    <li>

                        <p>            CARE India has taken every precaution to make sure the content of this website is accurate and legally correct at the time of appearance. If you believe the content of any of our pages is inaccurate please contact us, email contactus(at)careindia.org</p>
                    </li>

                    <li>

                        <p>            accepts no liability for loss or damage, including personal injury, resulting from use of this website. CARE India makes all reasonable efforts to make sure malware or viruses are not transmitted from this website, however this cannot be guaranteed. We recommend that you safeguard your IT equipment before downloading information and files. CARE India will not accept liability for damage caused by viruses.</p>
                    </li>

                    <li>

                        <p>     When we provide links to other websites it does not mean that we approve of or endorse the views and information contained in the website. We accept no liability for damage caused by malware or viruses on websites that we have linked to.</p>
                    </li>

                    <li>

                        <p>
                            Please request permission before using our logos or other branded material. We reserve the right to require our logo to be removed from websites or printed material if it is not in our interests.

                        </p>
                    </li>
                    <li>

                        <p>
                            Our downloadable documents, brochures and web pages are provided for your use. You may copy or print the information for private purpose including teaching. You may not amend the content or use it for commercial purposes without CARE India’ written consent.
                        </p>
                    </li>
                    <li>

                        <p>
                            Images and photos used on this website are the property of CARE India or other Copyright owners. The may not be reproduced or used without CARE India’ or the copyright owner’s written consent
                        </p>
                    </li>

                    <li>

                        <p>
                            If there is any conflict between these terms and conditions and rules or specific terms of use relating to specific material then the latter shall prevail.
                        </p>
                    </li>
                </ul>





            </div>


        </div>
    )
}

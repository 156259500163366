import React, { forwardRef, useState, Fragment, useEffect, Suspense, lazy, useRef, useImperativeHandle } from 'react';
import { useQuery, gql, useMutation } from "@apollo/client";
import placeholder from '../assets/img/place_holder.jpg';
// const MUTATION = gql`
// mutation($file: Upload!) {
//   singleUpload(file: $file) {
//     filename
//     mimetype
//     encoding

//   }
// }
// `;
const ImageUpload = forwardRef((props, ref) => {
  //const [mutate, { loading, error }] = useMutation(MUTATION);
  let  url
  var reader = new FileReader();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [currentFile, setCurrentFile] = useState();
  
  // const onFileChange = ({
  //   target: {
  //     validity,
  //     files: [file]
  //   }  
  // }) => validity.valid && mutate({ variables: { file } });

  /*{
    target: {
      validity,
      files: [file]
    }
  }

  */
  const onFileChange = (e) => {
  url = <img src={placeholder} />
       let file =e.target.files[0];
  

     setSelectedFiles(file);
     setCurrentFile(URL.createObjectURL(file))
  }

  useImperativeHandle(
    ref,
    () => ({
      getSelected() {
        return selectedFiles
      }
    }),
  )


  return (
    <div class="form-group">
      <div className="file-preview"> 
      <img src={currentFile}/>
      </div>
      
      <div className="file-upload-wrapper" data-text="Select your file!">
      <label className='filelabel' for="file" >{selectedFiles?selectedFiles.name:''}</label> 
        <input  id="file"  className="file-upload-field" accept="image/png, image/jpeg" type="file"  onChange={(e) => onFileChange(e)} />
      </div>
    </div>

  );
})

export default ImageUpload
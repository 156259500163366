import React, { useState } from "react";
import "./invoice.scss";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import moment from "moment";
const PAYMENT_QUERY = gql`
  query getinvoice($id: ID!) {
    getinvoice(id: $id) {
      amount
      type
      validity
      razorpayPaymentId
      orderCreationId
      _id
      createdAt
      month
    }
  }
`;

function Invoice() {
  const { id } = useParams();

  const { data: payment } = useQuery(PAYMENT_QUERY, { variables: { id: id } });
  let { createdAt, orderCreationId, type, validity, amount,month } = {};
  if (payment) {
    createdAt = payment.getinvoice.month;
    orderCreationId = payment.getinvoice.orderCreationId;
    type = payment.getinvoice.type;
    validity = payment.getinvoice.validity;
    amount = payment.getinvoice.amount;
  }

  function print() {
    var printContents = document.getElementById("printDiv").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    window.history.back();
  }

  //  ******************working type for print*****************
  const printReceipt = () => {
    print();
  };
  {
    /***************************/
  }

  return (
    <div id="invoice-page">
      <div class="container">
        <div class="col-md-12 mt-3">
          <div class="invoice-company text-inverse f-w-600">
            <span class="pull-right hidden-print">
              {/* <a class="btn btn-sm btn-white m-b-10 p-l-5" onClick={()=>printDocument()}><i class="fa fa-file t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a> */}
              <button
                onClick={() => printReceipt()}
                class="btn btn-sm btn-white m-b-10 p-l-5"
              >
                <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print
              </button>
            </span>
          </div>
          <div class="invoice" id="printDiv">
            <div class="invoice-header mt-3">
              <div class="invoice-from" id="section-to-print">
                <address class="m-t-5 m-b-5">
                  <strong class="text-inverse">
                    CALICUT DEFENCE TRUST & CARE
                  </strong>
                  <br />
                  Cherukulam , Calicut,673632
                  <br />
                  Phone: +91 90372 15011
                  <br />
                  email:calicutdefenceinfo@gmail.com
                </address>
              </div>

              <div class="invoice-date">
                <div class="date text-inverse m-t-5">
                  {moment(createdAt).format("DD-MM-YYYY")}{" "}
                </div>
                <div class="invoice-detail">
                  <small>Invoice Id :{orderCreationId}</small>
                </div>
              </div>
            </div>
            <div class="invoice-content">
              <div class="table-responsive">
                <table class="table table-invoice">
                  <thead>
                    <tr>
                      <th>DESCRIPTION</th>
                      <th class="text-center" width="10%">
                        validity
                      </th>
                      <th class="text-center" width="10%"></th>
                      <th class="text-right" width="20%">
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span class="text-inverse">
                          {" "}
                          {type}for Payment Calicut Defence{" "}
                        </span>
                        <br />
                        <strong class="text-inverse"></strong>
                      </td>
                      <td class="text-center">{validity} Month</td>
                      <td class="text-center"></td>
                      <td class="text-right">{amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="invoice-note">
              * Make all cheques payable to Calicut Defence <br />
              * Payment is due within 30 days
              <br />* If you have any questions concerning this invoice, contact
              to Our Address
            </div>

            <div class="invoice-footer">
              <p class="text-center m-b-5 f-w-600">
                THANK YOU FOR YOUR SERVICE
              </p>
              <p class="text-center">
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-globe"></i> www.calicutdefence.com
                </span>
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-phone-volume"></i> 91*987654321
                </span>
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-envelope"></i>{" "}
                  calicutdefenceinfo@gmail.com
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;

import React, { useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Img } from "../../../shared";
import Chil from "../../../assets/img/chil.PNG";
import { Alert } from "react-bootstrap";
const GALLERY_DELETE_MUTATION = gql`
  mutation gallery_delete($id: ID!) {
    gallery_delete(id: $id) {
      status
    }
  }
`;
export default function GalleryList(props) {
  const [deleteGallery, { loading, error }] = useMutation(
    GALLERY_DELETE_MUTATION
  );
  const [isdelete, setDelete] = useState("");

  const handleDeleteClick = (e) => {
    deleteGallery({ variables: { id: e._id } })
      .then((res) => {
        setDelete("Deleted Successfully");
      })
      .then((res) => {
        props.refetch();
      });
    setTimeout(() => {
      setDelete(null);
    }, 1500);
  };


  return (
    <>
      {isdelete ? <Alert variant="success">{isdelete}</Alert> : ""}
      <div className="col-2 col-sm-2 col-md-2 gallery">
        <div style={{
          position: "absolute",
          left: "0px",
          backgroundColor: "#198754",
          color: "#fff",
          fontSize:" 0.7rem",
          padding: "0px 6px",
        }}> {props?.obj?.type} </div>
        <div
          className="gallery-trash"
          onClick={() => handleDeleteClick(props.obj)}
        ></div>
        <Img
          src={
            props.obj.assetManagement
              ? props.obj.assetManagement.filename
              : null
          }
          class="img-fluid"
        />
          <p> {props?.obj?.title}</p>
        {/* <img src={Chil} className='img-fluid'/> */}
      </div>
    </>
  );
}


import React from 'react'

export default function Contact() {
    return (
       <>
         <section>
    <div className="map">
      <h1>Contact us</h1>
      <p>Far far away, behind the word mountains, far from the countries Vokalia and Far far away, behind the word
       
        mouConsonantia, there live the blind textsFar far away, behind the word mountains, far from the co
      </p>
    </div>
  </section>
  <section className="contact-address">

    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-6">
          <div className="input-item">
            <div className="firstinput">
              <input type="text" placeholder="First name"/>
              <input type="text" placeholder="Fast name" />
            </div>
            <div className="secondinput">
              <input type="text" placeholder="Email id" />
            </div>
            <div className="thirdinput">
              <textarea placeholder="Enter your message here..."> </textarea>
            </div>
            <div className="submit-btn">
              <button type="submit">submit</button>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6">
          <div className="input-contact-box">
            <h4>Customer Support</h4>
            <p className="address-location"> <i className="fas fa-map-marker-alt"></i>Cherukulam</p>


            <p className="address-mob"> <i className="fas fa-phone-alt"></i> +91 90372 15011</p>
            <p className="address-email"> <i className="far fa-envelope"></i> calicutdefenceinfo@gmail.com</p>

          </div>
          <div className="input-contact-box ">
            <h4>Connect With Us</h4>
            <p className="address-mob"> <i className="fas fa-phone-alt"></i>Anil PK President - 9113838907</p>


            <p className="address-mob"> <i className="fas fa-phone-alt"></i> Sareesh PK Secretary - 7306664619</p>
            <p className="address-email"> <i className="far fa-envelope"></i> calicutdefenceinfo@gmail.com</p>

          </div>
        </div>
      </div>
    </div>



  </section>
  <section>
    <div className="proper-map">
    </div>


  </section>
       </>
    )
}

import React, { forwardRef, useRef, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col, Alert, Modal } from "react-bootstrap";
import "./style.scss";
import { Heading, ImageUpload, Img } from "../../../shared";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const GROUP_QUERY = gql`
  {
    group_list {
      _id
      title
      adminrefId {
        name
        userId
        _id
      }
      memberfId {
        name
        userId
        _id
      }
    }
  }
`;

const GROUP_MEMBER_MUTATION = gql`
  mutation groupMember_updateById($userId: [String], $_id: String) {
    groupMember_updateById(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;
const PROFILE_UPDATE_MUTATION = gql`
  mutation profile_update_admin(
    $name: String!
    $lastname: String!
    $date_of_birth: String!
    $blood_group: String!
    $blood_donation: String
    $ex_ServiceMan: String!
    $contact: String!
    $whatsapp: String!
    $address: String!
    $post: String!
    $pin: String!
    $district: String!
    $verificationtype: String!
    $subscribed: String
    $area: String!
    $date_of_marriage: String!
    $occupation: String!
    $_id: String!
    $family: JSON
  ) {
    profile_update_admin(
      obj: {
        name: $name
        lastname: $lastname
        date_of_birth: $date_of_birth
        blood_group: $blood_group
        blood_donation: $blood_donation
        district: $district
        ex_ServiceMan: $ex_ServiceMan
        contact: $contact
        whatsapp: $whatsapp
        address: $address
        post: $post
        pin: $pin
        family: $family
        verificationtype: $verificationtype
        subscribed: $subscribed
        area: $area
        occupation: $occupation
        date_of_marriage: $date_of_marriage
        _id: $_id
      }
    ) {
      status
    }
  }
`;

const USER_ID_MUTATION = gql`
  mutation profile_update_admin_userId(
    $_id: String
    $subscribed: String
    $action: String
  ) {
    profile_update_admin_userId(
      obj: { _id: $_id, subscribed: $subscribed, action: $action }
    ) {
      status
    }
  }
`;

const STATUS_EDIT_MUTATION = gql`
  mutation updateStatusForUser($action: String, $id: String) {
    updateStatusForUser(obj: { id: $id, action: $action }) {
      status
    }
  }
`;
const UPDATE_PROFILE_IMAGE_MUTATION = gql`
  mutation profile_update_profile_image($_id: String, $file: String) {
    profile_update_profile_image(obj: { _id: $_id, file: $file }) {
      status
    }
  }
`;
const UPDATE_DOC_MUTATION = gql`
  mutation profile_update_document_image($_id: String, $file: String) {
    profile_update_document_image(obj: { _id: $_id, file: $file }) {
      status
    }
  }
`;

const Update = forwardRef((props, ref) => {
  let profileEdit = false;

  const [fields, setFields] = useState(props.obj.family);
  const upload_Ref = useRef();
  const doc_upload_Ref = useRef();
  const [inputForm, setForm] = useState({
    name: props.obj.name ? props.obj.name : null,
    ex_ServiceMan: props.obj.ex_ServiceMan,

    date_of_birth: props.obj.date_of_birth ? props.obj.date_of_birth : null,
    blood_group: props.obj.blood_group ? props.obj.blood_group : null,
    contact: props.obj.contact ? props.obj.contact : null,
    whatsapp: props.obj.whatsapp ? props.obj.whatsapp : null,
    address: props.obj.address ? props.obj.address : null,
    post: props.obj.post ? props.obj.post : null,
    pin: props.obj.pin ? props.obj.pin : null,
    district: props.obj.district ? props.obj.district : null,
    blood_donation: props.obj.blood_donation ? props.obj.blood_donation : "no",
    lastname: props.obj.lastname ? props.obj.lastname : null,
    subscribed: props.obj.subscribed,
    area: props.obj.area,
    date_of_marriage: props.obj.date_of_marriage,
    occupation: props.obj.occupation,
    verificationtype: props.obj.verificationtype
      ? props.obj.verificationtype
      : null,
    docFile: props.obj.docFile ? props.obj.docFile.filename : null,
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
    _id: props.obj._id ? props.obj._id : null,
    family: props.obj.family ? props.obj.family : null,
  });
  const [isValid, setIsValid] = useState("");
  const [errors, setErrors] = useState({});

  const [userstatus, setUserStatus] = useState({
    action: props.obj.account_refId.action,
  });
  const [updateProfile] = useMutation(PROFILE_UPDATE_MUTATION);
  const [updateStatus] = useMutation(STATUS_EDIT_MUTATION);
  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE_MUTATION);
  const [updateDoc] = useMutation(UPDATE_DOC_MUTATION);
  const [userId] = useMutation(USER_ID_MUTATION);
  const [assinMember] = useMutation(GROUP_MEMBER_MUTATION);
  const [UserGroup, setUserGroup] = useState({});
  const { data: groups, refetch: loadEntries } = useQuery(GROUP_QUERY);

  let formChange = (event) => {
    profileEdit = true;
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };

  let StatusChange = (event) => {
    setUserStatus({ action: event.target.value });
  };

  let GroupChange = (event) => {
    setUserGroup(event.target.value);
  };
  let registration = (event) => {
    event.preventDefault();
    const values = { ...inputForm };
    values["family"] = fields;
    if (validateForm()) {
      const obj = { ...userstatus };
      obj.id = props.obj.account_refId._id;
      debugger
      //updateStatus({ variables: { ...obj } }).then(res=>{
      updateProfile({ variables: { ...values } }).then((res) => {
        if (res.data.profile_update_admin.status) {
          setIsValid("Updated  Successfully");
          props.refetch();
          props.handleClose();
        } else {
          setIsValid("please contact Admin ");
        }
      });

      setTimeout(() => {
        setIsValid("");
      }, 1500);
      //})
    }
  };
  const addGroup = (event) => {
    event.preventDefault();

    assinMember({
      variables: {
        userId: [props.obj._id],
        _id: UserGroup,
      },
    }).then((res) => {
      if (res.data.groupMember_updateById.status) {
        props.refetch();
        props.handleClose();
      }
    });
  };
  let SubmitStatus = (event) => {
    event.preventDefault();
    const values = { ...inputForm };
    const obj = { ...userstatus };
    obj.id = props.obj.account_refId._id;
    updateStatus({ variables: { ...obj } }).then((res) => {
      userId({
        variables: {
          _id: values._id,
          subscribed: values.subscribed,
          ...userstatus,
        },
      }).then((res) => {
        if (res.data.profile_update_admin_userId.status) {
          setIsValid("Updated  Successfully");
          props.refetch();
          props.handleClose();
        } else {
          setIsValid("please contact Admin ");
        }
      });
    });
  };

  function handleAdd() {
    const values = [...fields];
    values.push({ name: null, value: null });
    setFields(values);
  }
  function handleChange(i, event) {
    const values = [...fields];
    values[i].name = event.target.value;
    setFields(values);
  }

  function handleChangeSelect(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }
  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  function validateForm() {
    let errors = {};
    let formIsValid = true;
    if (!inputForm["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter your Name.";
    }

    if (!inputForm["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "*Please enter your Last Name.";
    }

    if (!inputForm["contact"]) {
      formIsValid = false;
      errors["contact"] = "*Please enter your mobile no.";
    }

    if (typeof inputForm["contact"] !== "undefined") {
      if (!inputForm["contact"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["contact"] = "*Please enter valid mobile no.";
      }
    }

    if (!inputForm["whatsapp"]) {
      formIsValid = false;
      errors["whatsapp"] = "*Please enter your whatsapp mobile no.";
    }

    if (typeof inputForm["whatsapp"] !== "undefined") {
      if (!inputForm["whatsapp"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["whatsapp"] = "*Please enter valid whatsapp mobile no.";
      }
    }

    if (!inputForm["date_of_birth"]) {
      formIsValid = false;
      errors["date_of_birth"] = "*Please enter date of birth.";
    }

    if (!inputForm["blood_group"]) {
      formIsValid = false;
      errors["blood_group"] = "*Please select blood group";
    }
    if (inputForm["blood_donation"] == null) {
      formIsValid = false;
      errors["blood_donation"] = "*Please select blood donation.";
    }

    if (inputForm["ex_ServiceMan"] == null) {
      formIsValid = false;
      errors["ex_ServiceMan"] = "*Please select ex ServiceMan";
    }
    // if (inputForm["in_Service"]==null) {
    // 	formIsValid = false;
    // 	errors["in_Service"] = "*Please select Service";
    // }

    if (!inputForm["occupation"]) {
      formIsValid = false;
      errors["occupation"] = "*Please select occupation";
    }

    if (!inputForm["address"]) {
      formIsValid = false;
      errors["address"] = "*Please Enter  your address";
    }

    if (!inputForm["area"]) {
      formIsValid = false;
      errors["area"] = "*Please Enter  your area";
    }

    if (!inputForm["district"]) {
      formIsValid = false;
      errors["district"] = "*Please Enter  district";
    }

    if (!inputForm["post"]) {
      formIsValid = false;
      errors["post"] = "*Please Enter  post";
    }

    if (!inputForm["pin"]) {
      formIsValid = false;
      errors["pin"] = "*Please Enter  pin";
    }

    if (!inputForm["verificationtype"]) {
      formIsValid = false;
      errors["verificationtype"] = "*Please Enter  verification Type";
    }

    if (!profileImage) {
      formIsValid = false;
      errors["upload_Ref"] = "*Please select Profile Pic.";
    }
    // if (upload_Ref.current.getSelected()) {
    //   const files = Math.round((upload_Ref.current.getSelected().size / 1024));

    //   if (files >= 50048) {
    //     formIsValid = false;
    //     errors["upload_Ref"] = "* Profile File too Big, please select a file less than 30mb";
    //   }
    // }

    if (!docImage) {
      formIsValid = false;
      errors["doc_upload_Ref"] = "*Please select  document file.";
    }
    // if (doc_upload_Ref.current.getSelected()) {
    //   const filess = Math.round((doc_upload_Ref.current.getSelected().size / 1024));
    //   if (filess >= 50048) {
    //     formIsValid = false;
    //     errors["upload_Ref"] = "*Document File too Big, please select a file less than 30mb";
    //   }
    // }

    setErrors(errors);
    return formIsValid;
  }

  function formCheckChange(event) {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;

    if (event.target.value.toLowerCase() === "true") {
      values[event.target.name] = true;
    }
    if (event.target.value.toLowerCase() === "false") {
      values[event.target.name] = false;
    }
    setForm(values);
  }
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const updateProfileBtn = () => {
    const values = { ...inputForm };

    values["file"] = profileImage;
    if (profileImage) {
      updateProfileImage({ variables: { ...values } }).then((res) => {
        if (res.data.profile_update_profile_image.status) {
          alert("file upload successfully");
          setShow(false);
        }
      });
    } else {
      alert("files are not selected");
    }
  };
  const handleShow = () => setShow(true);

  /************************ */

  const [docshow, docShow] = useState(false);
  const dochandleClose = () => {
    docShow(false);
  };
  const updateDocBtn = () => {
    const values = { ...inputForm };

    values["file"] = docImage;

    if (docImage) {
      updateDoc({ variables: { ...values } }).then((res) => {
        if (res.data.profile_update_document_image.status) {
          alert("file upload successfully");
          docShow(false);
        }
      });
    } else {
      alert("files are not selected");
    }
  };
  const dochandleShow = () => docShow(true);
  function dateofbirthDateChange(date) {
    const values = { ...inputForm };
    values["date_of_birth"] = date;
    setForm(values);
  }
  function dateofmarriageDateChange(date) {
    const values = { ...inputForm };
    values["date_of_marriage"] = date;
    setForm(values);
  }

  /****
   *
   *
   *
   */

  const [cropper, setCropper] = useState();
  const [showCrop, setCropShow] = useState(false);
  const [srcCrop, setSrc] = useState();
  const [profileImage, setProfile] = useState(
    props.obj.assetManagement
      ? props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.assetManagement.filename
        : null
      : null
  );

  const [cropperDoc, setDocCropper] = useState();

  const [srcDocCrop, setDocSrc] = useState();
  const [docImage, setDoc] = useState(
    props.obj.docFile
      ? props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.docFile.filename
        : null
      : null
  ); //https://www.calicutdefence.com/asset/' + props.obj.docFile ? 'https://www.calicutdefence.com/asset/' + props.obj.docFile ? props.obj.docFile.filename : null : null

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const onDocFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setDocSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCropClose = () => {
    if (cropper.cropped) {
      setProfile(cropper.getCroppedCanvas().toDataURL());
    }
    setCropShow(false);
    setShow(false);
  };
  const handleCropShow = () => setCropShow(true);

  const handleDocCropClose = () => {
    if (cropperDoc.cropped) {
      setDoc(cropperDoc.getCroppedCanvas().toDataURL());
    }
    docShow(false);
  };
  const handleCropDocShow = () => docShow(true);
  return (
    <>
      <form method="post" onSubmit={registration}>
        <div class="user-avatar">
          <img
            src={profileImage}
            style={{
              height: "200px",
            }}
          />

          <Button variant="primary" onClick={handleShow}>
            change Profile
          </Button>
          <Button variant="primary" onClick={updateProfileBtn}>
            Update
          </Button>
        </div>

        <Form.Group className="" controlId="formBasicEmail">
          <Form.Label>
            Name <span className="mandatory">*</span>(Text and space are
            allowed)
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            value={inputForm.name}
            name="name"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.name}</span>
        </Form.Group>
        <Form.Group className="mt-3 p-0 p-0" controlId="formBasicEmail">
          <Form.Label>Last Name (Text and space are allowed)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Last Name"
            value={inputForm.lastname}
            name="lastname"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.lastname}</span>
        </Form.Group>
        <Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
          <Form.Label>
            Contact Number <span className="mandatory">*</span>(10 digit number)
          </Form.Label>
          <Form.Control
            type="text"
            pattern="[0-9]+"
            title="please enter number only"
            value={inputForm.contact}
            minlength="10"
            maxlength="10"
            placeholder="Contact Number"
            name="contact"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.contact}</span>
        </Form.Group>
        <Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
          <Form.Label>
            Whatsapp Number <span className="mandatory">*</span>(10 digit
            number)
          </Form.Label>
          <Form.Control
            type="text"
            pattern="[0-9]+"
            title="please enter number only"
            value={inputForm.whatsapp}
            minlength="10"
            maxlength="10"
            placeholder="Whatsapp Number"
            name="whatsapp"
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.whatsapp}</span>
        </Form.Group>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            className="mt-3 p-0"
            controlId="formBasicPassword"
          >
            <Form.Label>
              Date of Birth <span className="mandatory">*</span>
            </Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Date of Marriage"
              selected={new Date(inputForm.date_of_birth)}
              onChange={(date, e) => dateofbirthDateChange(date)}
            />
            {/* <Form.Control type="date" placeholder="Date of Birth" name="date_of_birth" value={inputForm.date_of_birth}  onChange={(e) => formChange(e)} /> */}
            <span className="mandatory">{errors.date_of_birth}</span>
          </Form.Group>
          <Form.Group
            as={Col}
            className="mt-3 p-0"
            controlId="formBasicPassword"
          >
            <Form.Label>Date of Marriage</Form.Label>
            {inputForm.date_of_marriage ? (
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Date of Marriage"
                selected={new Date(inputForm.date_of_marriage)}
                onChange={(date, e) => dateofmarriageDateChange(date)}
              />
            ) : (
              "null"
            )}
            {/* <Form.Control type="date" placeholder="Date of Marriage" name="date_of_marriage" value={inputForm.date_of_marriage}  onChange={(e) => formChange(e)} /> */}
            <span className="mandatory">{errors.date_of_marriage}</span>
          </Form.Group>
        </Row>

        {/* <Form.Group className="mt-3 p-0" controlId="file">
				<Form.Label>Profile Picture<span className="mandatory">*</span></Form.Label>
				<ImageUpload ref={upload_Ref} />
				<span className='mandatory'>{errors.upload_Ref}</span>
			</Form.Group> */}

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>
            Blood Group <span className="mandatory">*</span>
          </Form.Label>
          <Form.Select
            name="blood_group"
            onChange={(e) => formChange(e)}
            value={inputForm.blood_group}
          >
            <option value="none" selected>
              Select Blood Group
            </option>
            <option value="A +ve">A +ve</option>
            <option value="A -ve">A -ve</option>
            <option value="B +ve">B +ve</option>
            <option value="B -ve">B -ve</option>
            <option value="AB +ve">AB +ve</option>
            <option value="AB -ve">AB -ve</option>
            <option value="O +ve">O +ve</option>
            <option value="O -ve">O -ve</option>
          </Form.Select>
          <span className="mandatory">{errors.blood_group}</span>
        </Form.Group>
        <Row className="mt-3 p-0">
          <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
            <Form.Label>
              Willing for blood donation? <span className="mandatory">*</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="donations">
              {inputForm.blood_donation}
              <Form.Check
                type="radio"
                label="No"
                value="no"
                id="custom-switch1"
                name="blood_donation"
                checked={inputForm.blood_donation == "no" ? true : false}
                onChange={(e) => formCheckChange(e)}
              />
              <Form.Check
                type="radio"
                label="Yes"
                value="yes"
                id="custom-switch2"
                name="blood_donation"
                checked={inputForm.blood_donation == "yes" ? true : false}
                onChange={(e) => formCheckChange(e)}
              />
            </div>
            <span className="mandatory">{errors.blood_donation}</span>
          </Form.Group>
        </Row>
        <Row className="mt-3 p-0">
          <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
            <Form.Label>
              Ex-ServiceMan?<span className="mandatory">*</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="ex_service">
              <Form.Check
                type="radio"
                label="no"
                id="custom-switch1"
                value="no"
                checked={inputForm.ex_ServiceMan == "no" ? true : false}
                name="ex_ServiceMan"
                onChange={(e) => formCheckChange(e)}
              />
              <Form.Check
                type="radio"
                label="yes"
                id="custom-switch2"
                value="yes"
                checked={inputForm.ex_ServiceMan == "yes" ? true : false}
                name="ex_ServiceMan"
                onChange={(e) => formCheckChange(e)}
              />
            </div>
            <span className="mandatory">{errors.ex_ServiceMan}</span>
          </Form.Group>
        </Row>
        {/* *********************Occupation*********************/}

        <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
          <Form.Label>
            Occupation <span className="mandatory">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Current Occupation"
            name="occupation"
            value={inputForm.occupation}
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.occupation}</span>
        </Form.Group>

        {/* *********************in service?*********************/}
        {/* <Row className="mt-3 p-0">
				<Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
					<Form.Label>Currently in Service?<span className="mandatory">*</span></Form.Label>
				</Form.Group>
				<Form.Group as={Col}>
					<div className='ex_service'>
						<Form.Check type="radio" label="no" id="custom-switch1"  checked={inputForm.in_Service=='no'?true:false}  value="no" name="in_Service" onChange={(e) => formCheckChange(e)} />
						<Form.Check type="radio" label="yes" id="custom-switch2" value="yes" checked={inputForm.in_Service=='yes'?true:false} name="in_Service" onChange={(e) => formCheckChange(e)} />
					</div>
					<span className='mandatory'>{errors.in_Service}</span>
				</Form.Group>

			</Row> */}
        <Form.Group className="mt-2" controlId="formBasicPassword">
          <Form.Label>
            Address <span className="mandatory">*</span>(Text and space are
            allowed)
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="House Name"
            name="address"
            value={inputForm.address}
            onChange={(e) => formChange(e)}
          />
          <span className="mandatory">{errors.address}</span>
        </Form.Group>

        <Row className="mt-3 p-0">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>
              Area <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Area"
              name="area"
              value={inputForm.area}
              onChange={(e) => formChange(e)}
            />
            <span className="mandatory">{errors.area}</span>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="formGridCity">
            <Form.Label>
              District <span className="mandatory">*</span>
            </Form.Label>
            <Form.Select
              className="mb-3"
              name="district"
              value={inputForm.district}
              onChange={(e) => formChange(e)}
            >
              <option value="none" selected>
                Select District
              </option>
              <option value="kozhikode">kozhikode</option>
            </Form.Select>
            <span className="mandatory">{errors.district}</span>
          </Form.Group>
        </Row>

        <Row className="mt-3 p-0">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>
              Post <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Post"
              name="post"
              value={inputForm.post}
              onChange={(e) => formChange(e)}
            />
            <span className="mandatory">{errors.post}</span>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>
              Pin <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]+"
              title="please enter number only"
              minlength="6"
              maxlength="6"
              value={inputForm.pin}
              placeholder="Pin"
              name="pin"
              onChange={(e) => formChange(e)}
            />
            <span className="mandatory">{errors.pin}</span>
          </Form.Group>
        </Row>
        <Form.Group className="mt-3 p-0" controlId="formGridCity">
          <Form.Label>
            Verification Document Details<span className="mandatory">*</span>
          </Form.Label>
          <Form.Select
            defaultValue="Choose..."
            className="mb-3"
            name="verificationtype"
            value={inputForm.verificationtype}
            onChange={(e) => formChange(e)}
          >
            <option value="none" selected>
              Select Document
            </option>
            <option value="Passport">Passport </option>
            <option value="Aadhaar">Aadhaar</option>
            <option value="Driving Licence">Driving Licence</option>
            <option value="Bank Passbook">Bank Passbook</option>
          </Form.Select>
          <span className="mandatory">{errors.verificationtype}</span>
        </Form.Group>

        {/**************Family Details****************/}
        <Form.Label>Add Family Members</Form.Label>

        <i onClick={() => handleAdd()} class="fas fa-plus-circle mt-3 p-0"></i>
        {fields?.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <Row className="col">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="Name"
                    name="name"
                    value={field.name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Relation</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="relation"
                    value={field.relation}
                    onChange={(e) => handleChangeSelect(idx, e)}
                  >
                    <option value="none" selected>
                      Select Relation
                    </option>
                    <option value="Wife" selected>
                      Wife
                    </option>
                    <option value="Husband">Husband</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Grand Mother">Grand Mother</option>
                    <option value="Grand Father">Grand Father</option>
                  </Form.Select>
                  <i
                    onClick={() => handleRemove(idx)}
                    class="fas fa-trash-alt"
                  ></i>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Blood Group <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    name="blood_group"
                    value={field.blood_group}
                    onChange={(e) => formChange(e)}
                  >
                    <option value="none" selected>
                      Select Blood Group
                    </option>
                    <option value="A+ve">A +ve</option>
                    <option value="A-ve">A -ve</option>
                    <option value="B+ve">B +ve</option>
                    <option value="B-ve">B -ve</option>
                    <option value="AB+ve">AB +ve</option>
                    <option value="AB-ve">AB -ve</option>
                    <option value="O+ve">O +ve</option>
                    <option value="O-ve">O -ve</option>
                  </Form.Select>
                </Form.Group>
              </Row>
            </div>
          );
        })}

        <div className="doc-file">
          <img
            src={docImage}
            style={{
              height: "200px",
            }}
          />
          <Button variant="primary" onClick={dochandleShow}>
            update Doc file
          </Button>
          <Button variant="primary" onClick={updateDocBtn}>
            Update
          </Button>
        </div>

        <input
          type="submit"
          className="add-btn me-2 btn btn-primary"
          value="Update Profile"
        />
      </form>
      <form method="post" onSubmit={SubmitStatus}>
        <Form.Group className="mt-3 p-0" controlId="formBasicPassword">
          <Form.Label>
            Are you Subscribed?<span className="mandatory">*</span>{" "}
          </Form.Label>
          <Form.Select
            value={inputForm.subscribed}
            name="subscribed"
            onChange={(e) => formChange(e)}
          >
            <option value="none" selected>
              Select
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mt-3 p-0" controlId="formGridCity">
          <Form.Label>
            Status<span className="mandatory">*</span>
          </Form.Label>
          <Form.Select
            className="mt-3 p-0 mb-3"
            name="action"
            value={userstatus.action}
            onChange={(e) => StatusChange(e)}
          >
            <option value="none" selected>
              Select Status
            </option>
            <option value="Active">Active</option>
            <option value="Rejected">Rejected</option>
            <option value="Blocked">Blocked</option>
            <option value="Waiting_for_admin">Waiting For Admin</option>
            <option value="email_verification">Email Verification</option>
          </Form.Select>
        </Form.Group>
        <input
          type="submit"
          className="add-btn me-2 btn btn-primary"
          value="Update Status"
        />
      </form>
      Update Group
      <Group obj={props?.obj?.group_refId} />
      <form method="post" onSubmit={addGroup}>
        <Form.Select
          className="mt-3 p-0 mb-3"
          name="action"
          value={UserGroup}
          onChange={(e) => GroupChange(e)}
        >
          <option value="none" selected>
            Select Status
          </option>
          {groups?.group_list.map((res) => (
            <option value={res._id}>{res.title}</option>
          ))}
        </Form.Select>

        <input
          type="submit"
          className="add-btn me-2 btn btn-primary"
          value="Assigin Group"
        />
      </form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change User profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={onFileChange} />
          <Cropper
            style={{ height: 282, width: 450 }}
            aspectRatio={4 / 6}
            guides={false}
            src={srcCrop}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            viewMode={1}
            dragMode="move"
            cropBoxMovable={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCropClose}>
            Update Image
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={docshow} onHide={dochandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={onDocFileChange} />
          <Cropper
            style={{ height: 282, width: 450 }}
            aspectRatio={4 / 6}
            guides={false}
            src={srcDocCrop}
            onInitialized={(instance) => {
              setDocCropper(instance);
            }}
            viewMode={1}
            dragMode="move"
            cropBoxMovable={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDocCropClose}>
            Update Image
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default Update;

function Group({ obj }) {
  if (obj != null) {
    let re = obj.map((items) => {
      return <div id="group-tag"> {items.title}</div>;
    });
    return re;
  } else {
    return <> no Group</>;
  }
}

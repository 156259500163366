import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import {
  Spinner,
  Tabs,
  Tab,
  Modal,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import { Heading } from "../../../shared";
import Add from "./Add";
import "./style.scss";
import GalleryList from "./GalleryList";
//const GalleryCard = lazy(() => import('./gallery_Card'));
import AclPermission from "../../../lib/acl";
//const Add = lazy(() => import('./Add'))

const GALLERY_LIST_QUERY = gql`
  {
    list_Images {
      title

      type
      _id
      assetManagement {
        filename
        path
      }
    }
  }
`;

function Gallery() {
  const { data, loading, error, refetch } = useQuery(GALLERY_LIST_QUERY, {});
  const [editAble, setEditAble] = useState(false);
  const [_id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const [obj, setobj] = useState({
    title: null,
    body: null,
    url: null,
    files: null,
  });

  const Gallery = data
    ? data.list_Images.map((data, id) => {
        return (
          <GalleryList
            refetch={(e) => {
              refetch();
            }}
            key={id}
            obj={data}
          />
        );
      })
    : "null";

  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      url: null,
      files: null,
    });

    setEditAble(false);
    setId(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  return (
    <div id="gallery">
      <AclPermission isActive={["admin", "President", "Media Cell"]} showMessage={false}>
        <div className="tbl">
          <Link
            to="/admin/gallery-folder"
            style={{
              float: "right",
              marginLeft: "10px",
              padding: "7px 10px",
              backgroundColor: " #427744",
              borderRadius: "4px",
              color: "#fff",
              fontSize: "0.8rem",
              marginTop: "5px",
              cursor: "pointer",
            }}
          >
            add Folder
          </Link>
          <Button className={"add-btn"} onClick={handleShow}>
            create
          </Button>

          <AdminPanel
            show={show}
            handleClose={(e) => {
              handleClose();
            }}
            title="Gallery Details"
          >
            <Add
              editAble={editAble}
              obj={obj}
              refetch={(e) => {
                refetch();
              }}
              handleClose={(e) => {
                handleClose();
              }}
            />
          </AdminPanel>

          <Heading title="Gallery" />
          <div className=" row">{Gallery}</div>
          <div />
        </div>
      </AclPermission>
    </div>
  );
}

export default Gallery;

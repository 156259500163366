// import React, { useState } from 'react';
// import './profile.scss';
// import { Button, Tab, Tabs, Alert, Modal } from 'react-bootstrap';
// function Donate() {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     return (
//         < div className='donate'>
//             <Button className='Donate_btn' onClick={handleShow}>
//             Donate
//             </Button>

//             <Modal show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Donate </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>

//                     <div className='Subscribe_list'>
//                         <ul>
//                             <li>
//                                 <span className='pakgName'> </span>
//                                 <span className='amount'> </span>
//                                 <span className='validity'> </span>
//                                 <input />
//                             </li>
//                         </ul>
//                     </div>


//                 </Modal.Body>
//                 <Modal.Footer>

//                     <Button variant="primary" onClick={handleClose}>
//                         Save Changes
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// }

// export default Donate



import React, { useState } from 'react';
import './profile.scss';
import { Button, Tab, Tabs, Alert, Modal,Form,  Row, Col, } from 'react-bootstrap';
import {Img,RrazopayUserDonnation}from '../../shared'
import paisa from 'paisa.js';

function Donate() {
    let gst=0.18
    const [show, setShow] = useState(false);
    const [gstamount, setGst] = useState(0);
  

    const [sucess, setSucess] = useState(false);
    const [inputForm, setForm] = useState({
        amount: 0.00,
   
    
      })
    const handleClose = () => {
        setShow(false)
        setSucess(false)
        setForm({amount:0.00})
    }
    const handleShow = () => setShow(true);
    let formChange = (event) => {
       
        const values = { ...inputForm };
       
        values[event.target.name] = parseFloat((100*event.target.value)) //
     
        setForm(values)
    
      }
      const submitForm = () => {
        setSucess(true)

    }
      
    return (
        <div className='subscrib'>
            <Button className='Subscribe_btn' onClick={handleShow}>
            <i class="fas fa-hand-holding-heart" aria-hidden="true"></i> DONATE NOW
            </Button>

            <Modal className="subscrib_form" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Donate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {sucess?
                    <div id="Success">
            <div className='icon'></div>
       <h2>Donate successfully  </h2>
       <p> please  check  your Email</p>
        </div>:<>
        <div className='Subscribe_list'>
                
         <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Donate Amount <span className="mandatory">*</span></Form.Label>
            <Form.Control type="number"  min="100" step='0.01' title="Currency"  placeholder="Donate Amount"    name="amount" onChange={(e) => formChange(e)} />
          </Form.Group> 
                    </div>
                    <div className="total"> 
                    <span> Amount : {paisa.formatWithSymbol(inputForm.amount,2)}</span>
                    <span> Gst : 0 %</span>
                    <span> Total:{paisa.formatWithSymbol(inputForm.amount,2)}</span>
                    </div>
                    </>
                    
                    }


                </Modal.Body>
                <Modal.Footer>
              
                <RrazopayUserDonnation type="Donation" amountt={inputForm.amount} />
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default Donate
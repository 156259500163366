import React, { Fragment, useState } from "react";
import { CardContainor, Heading } from "../../../shared";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Spinner,
  Modal,
  Button,
  Alert,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import Add from "./Add";
import { useParams } from "react-router-dom";
import Delete from "../../../assets/img/trash.svg";
import "./style.scss";
import AclPermission from "../../../lib/acl";
const SUBACTIVITIES_QUERY = gql`
  {
    sub_activity_list(obj: { page: 0, skip: 0 }) {
      title
      body
      meta_keyword
      meta_description
      seo_title
      _id
      createdAt
      status
      assetManagement {
        filename
      }
    }
  }
`;

const ACTIVITIES_DELETE_QUERY = gql`
  mutation sub_activity_deleteById($id: ID!) {
    sub_activity_deleteById(id: $id) {
      status
    }
  }
`;

const ACTIVITIES_VIEW_QUERY = gql`
  query activity_viewById($id: ID!) {
    activity_viewById(id: $id) {
      title
      body
      _id
      meta_keyword
      meta_description
      seo_title
      createdAt
      status
      assetManagement {
        filename
      }
      child {
        title
        body
        meta_keyword
        meta_description
        seo_title
        createdAt
        status
        _id
        assetManagement {
          filename
        }
      }
    }
  }
`;

export default function AdminActivitylist() {
  const { id } = useParams();
  const { data, refetch } = useQuery(ACTIVITIES_VIEW_QUERY, {
    variables: { id: id },
  });

  const [deleteActivities] = useMutation(ACTIVITIES_DELETE_QUERY);
  //const { data, loading, error, refetch } = useQuery(SUBACTIVITIES_QUERY);

  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [_id, setId] = useState(null);
  let {
    title: MainTitle,
    body,
    meta_keyword,
    meta_description,
    status,
    assetManagement,
  } = data ? data.activity_viewById.child : {};
  const [obj, setobj] = useState({
    title: null,
    body: null,
    seo_title: null,
    meta_description: null,
    meta_keyword: null,
    files: null,
  });
  const [isValid, setIsValid] = useState("");
  // let lists = data ? data.activity_list.map((res,key)=><Add {...res} key={key}/>):null;
  //  if (DATA){DATA.activity_list.map((res,key)=><Add {...res} key={key}/>)}
  // let list = DATA ? DATA.activity_list: null

  // const histrory = useNavigate ()
  // if (loading) return <Spinner animation="border" size="sm" />;
  // if (error) return `Error! ${error.message}`;

  console.log();
  const handleDeleteClick = (_id) => {
    deleteActivities({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        refetch();
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
      });
  };

  const handleEdit = (obj) => {
    setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };

  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    {
      name: "createdAt",
      cell: (row) => <span> {row.createdAt}</span>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(_id) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      seo_title: null,
      meta_description: null,
      meta_keyword: null,
      files: null,
    });

    setEditAble(false);
    setId(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  return (
    <div id="activityView">
      <AclPermission isActive={["admin", "President", "Media Cell"]} showMessage={false}>
        {isValid ? (
          <Alert className="mt-3" variant="success">
            {isValid}
          </Alert>
        ) : (
          ""
        )}

        {/* <div className="main-activity-title mb-3 mt-3"><h6>Main Activity Title: &nbsp;{MainTitle}</h6></div> */}
        <Button className={"add-btn"} onClick={handleShow}>
          create
        </Button>

        <AdminPanel
          show={show}
          handleClose={(e) => {
            handleClose();
          }}
          title="create new Activity"
        >
          <Add
            editAble={editAble}
            obj={obj}
            id={_id}
            MainID={id}
            refetch={(e) => {
              refetch();
            }}
            handleClose={(e) => {
              handleClose();
            }}
          />
        </AdminPanel>

        <Heading title={MainTitle} />

        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={data ? data.activity_viewById.child : []}
        />
      </AclPermission>
    </div>
  );
}

import React, { Fragment, useState } from "react";
import { CardContainor, Heading } from "../../../shared";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Spinner, Modal, Button, Alert } from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import Add from "./Add";
import "./style.scss";
import AclPermission from "../../../lib/acl";
import "./style.scss";
const DONATION_QUERY = gql`
  {
    donations_list(obj: { page: 0, skip: 0 }) {
      title
      body
      amount
      _id
      status
      assetManagement {
        filename
      }
    }
  }
`;

const DONATION_DELETE_QUERY = gql`
  mutation donations_deleteById($id: ID!) {
    donations_deleteById(id: $id) {
      status
    }
  }
`;

const AdminDonation = () => {
  const [deleteDonation] = useMutation(DONATION_DELETE_QUERY);
  const { data, loading, error, refetch } = useQuery(DONATION_QUERY);
  const [isValid, setIsValid] = useState("");
  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);

  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({
    title: null,
    body: null,
    seo_title: null,
    meta_description: null,
    meta_keyword: null,
    files: null,
  });

  const handleDeleteClick = (_id) => {
    deleteDonation({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
        refetch();
      });
  };
  const handleEdit = (obj) => {
    setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };
  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },

    {
      name: "Amount",
      cell: (row) => <span>{row.amount}</span>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(e) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      seo_title: null,
      meta_description: null,
      meta_keyword: null,
      files: null,
    });
    setEditAble(false);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  return (
    <div id="donation">
      <AclPermission isActive={["admin", "President", "Media Cell"]} showMessage={false}>
        <div className=" tbl">
          {isValid ? <Alert variant="success">{isValid}</Alert> : ""}
          <Button className={"add-btn"} onClick={handleShow}>
            create
          </Button>

          <AdminPanel
            show={show}
            handleClose={(e) => {
              handleClose();
            }}
            title="Donation Details"
          >
            <Add
              editAble={editAble}
              obj={obj}
              id={_id}
              refetch={(e) => {
                refetch();
              }}
              handleClose={(e) => {
                handleClose();
              }}
            />
          </AdminPanel>

          <Heading title="Donation" />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data ? data.donations_list : []}
          />
        </div>
      </AclPermission>
    </div>
  );
};

export default AdminDonation;

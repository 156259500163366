import React from 'react'
import { News_list, Advt, Img, Share_btn, DateSpan } from '../../shared';
import './style.scss';
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";

const ACTIVITY_VIEW_ID_QUERY = gql`
query activity_viewById($id:ID!) {
    activity_viewById(id: $id){
      title
      body
      _id
      meta_keyword
      meta_description
      seo_title
      createdAt
      status
      assetManagement
       {
        filename
       }
      child
      {
        title
        body
        meta_keyword
        meta_description
        seo_title
        createdAt
        status
        _id
        assetManagement
         {
          filename
         }
      }
    }
  }
`;
const ADS_QUERY = gql`
  {
    ads_list_byuser(obj:{page:10  skip:0,place:"activity_detals"}){
      title
      _id
      assetManagement{
        filename
      }
    
    }
  }
`;


export default function Activity_details() {
    const { id } = useParams();

    const { data: ads } = useQuery(ADS_QUERY);
    let advt = [];
    if (ads) {

        if (ads.ads_list_byuser) {
            advt = ads.ads_list_byuser.map((item, key) => {
                return (
                    <Img src={item.assetManagement ? item.assetManagement.filename : ''} width={300} height={300} alt="CALICUT DEFENCE" key={key} />
                )
            })

        }
    }


    const { data, loading, error, refetch } = useQuery(ACTIVITY_VIEW_ID_QUERY, { variables: { id: id } });

    let { body, createdAt, title, assetManagement, child } = data ? data.activity_viewById : {}

    {/************************SubActivity List**********************/ }
    let SubActivityList = data ? data.activity_viewById.child.map((res, key) =>
        <div className='col-12'>
            <div className='activity_details-container'>

                <div className="row">
                    <div className='col-3'>

                        <Img src={res.assetManagement ? res.assetManagement.filename : ''} width={200} height={200} />
                    </div>
                    <div className='col-9'><h1> {res.title}</h1>
                        <p> {res.body}</p> </div>
                </div>
            </div>
        </div>
    ) : null;
    {/**********************************************/ }
    return (

        <section id='activity_details'>
            <div className="container">
                <div className="row">
                    <div className='col-9'>
                        <div className='activity_details-container'>
                            <div className="row">
                                <div className='col-2'>
                                    <span className='createdAt'> <DateSpan dat={createdAt} type='MMMM Do YYYY' /></span>
                                    <span className='social' > <Share_btn /></span>
                                </div>
                                <div className='col-10'>
                                    <Img src={assetManagement ? assetManagement.filename : ''} width={300} height={300} />
                                    <h1> {title}</h1>
                                    <p> {body}</p>
                                    <div className='sub'>
                                        {SubActivityList}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        {advt}
                    </div>
                </div>

            </div>
        </section>

    )
}


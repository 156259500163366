import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import AclPermission from "../../../lib/acl";
import {
  Img,
  Heading,
  AdminPanel,
  Widget,
  Search,
  Export,
} from "../../../shared";

import "./style.scss";

import {
  Form,
  Row,
  Col,
  Spinner,
  Modal,
  Button,
  Alert,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import Create from "./create";
import Update from "./update";
import moment from "moment";
import Admin from "..";
import SetRole from "./SetRole";
import { data } from "jquery";
import FilterComponent from "./filter";
import { buildSegCompareObj } from "@fullcalendar/core";

const FILMS_QUERY = gql`
  {
    profile_list {
      name
      lastname
      ex_ServiceMan
      in_Service
      date_of_birth
      blood_group
      blood_donation
      contact
      whatsapp
      address
      post
      createdAt
      family {
        name
        relation
        blood_group
      }
      pin
      district
      email
      subscribed
      verificationtype
      _id
      account_refId {
        action
        _id
        role {
          _id
          name
        }
      }
      area
      date_of_marriage
      occupation
      subscribed
      userId

      docFile {
        filename
      }
      assetManagement {
        filename
      }
      group_refId {
        title
      }
    }
  }
`;

const ROLE_QUERY = gql`
  {
    rolelist {
      name
      _id
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation profile_delete($userId: String, $profileid: String) {
    profile_delete(obj: { userId: $userId, profileid: $profileid }) {
      status
    }
  }
`;


//  const RESET_ID = gql`

// `;

const UsersList = () => {
  const actionsMemo = React.useMemo(
    () => (
      <button
        onExport={() => {
          console.log(data);
        }}
      >
        Wxpoert
      </button>
    ),
    []
  );
  const { data: userrole, refetch: refetching } = useQuery(ROLE_QUERY);
  const [deleteProfile] = useMutation(DELETE_MUTATION);

    //const [resetId] = useMutation(RESET_ID);
  const [editAble, setEditAble] = useState(false);
  const [shownew, setShowNew] = useState(false);
  const [show, setShow] = useState(false);
  const [showrole, setShowRole] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({});
  const [rolename, setRoleName] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [filater, setfilter] = useState([]);

  const { refetch: refetchs } = useQuery(FILMS_QUERY, {
    onCompleted: (data) => {
      if (data.profile_list.length >= 1) {

let datas=JSON.parse(JSON.stringify(data.profile_list))


const result = Array.isArray(datas) ? datas.sort((a,b)=>{

   let a1=a.userId.split('-')
  let b1=b.userId.split('-')
return Number(a1[2])-Number(b1[2])

}) : [];
         
        setfilter(result.reverse());
      }
    },
  });





  const [refetch, { loading, error, data }] = useLazyQuery(FILMS_QUERY);
  let lists = data ? data.profile_list : [];
  
  const [groupName, setGroupName] = useState(null);

  const [groupList, setGroupList] = useState([]);
  
  const [active_v, setactive_v] = useState(0);
 
  const [email_verification, setEmail_verification] = useState(0);
  const [Waiting_for_admin, setWaiting_for_admin] = useState(0);
  const [blockedU, setblockedU] = useState(0);
  const [rejected, setRejected] = useState(0);

  let roleList = userrole ? userrole.rolelist : null;

  const rolefilter = (name) => {
    if (name == "all") {
      setfilter(data.profile_list);
      return;
    }
    lists = [];

    data.profile_list.forEach((element) => {
      element.account_refId.role.forEach((i) => {
        if (i._id == name) {
          console.log(element);
          lists.push(element);
        }
      });
    });
    setfilter(lists);
  };

  useEffect(() => {
    refetch().then((res) => {
       let GroupName=localStorage.getItem('group');
      let groupList=[]
      if (res.data.profile_list.length > 0) {

        let datas=JSON.parse(JSON.stringify(res.data.profile_list))


        const result = Array.isArray(datas) ? datas.sort((a,b)=>{
        
           let a1=a.userId.split('-')
          let b1=b.userId.split('-')
        return Number(a1[2])-Number(b1[2])
        
        }) : [];


        setfilter(result.reverse());
        let activeCount = 0,
          emailCount = 0,
          adminCount = 0,
          blocked = 0,
          rejected = 0;
        for (let index = 0; index < res.data.profile_list.length; index++) {
          const element = res.data.profile_list[index];

          if (element.account_refId?.action === "Active") {
            activeCount++;
          }
          else if (element.account_refId?.action === "email_verification") {
            emailCount++;
          }
          else if (element.account_refId?.action === "Waiting_for_admin") {
            adminCount++;
          }
         else  if (element.account_refId?.action === "Blocked") {
            blocked++;
          }
         else  if (element.account_refId?.action === "Rejected") {
            rejected++;
          }

          if(element.group_refId){
            if( element.group_refId.length>0) {
               if(element.group_refId[0].title==GroupName) {
              groupList.push(element)
            }
          }
            
          }



        }
        setactive_v(activeCount);
        setEmail_verification(emailCount);
        setWaiting_for_admin(adminCount);
        setblockedU(blocked);
        setRejected(rejected);
        setGroupName(GroupName)
        setGroupList(groupList)


      }
    });
  }, []);

  // var Active = filater
  //   ? filater.filter(function (items) {
  //      // return items.account_refId.action === "Active";
  //     }).length
  //   : 0;

  // var email_verification = filater
  //   ? filater.filter(function (items) {
  //       //return items.account_refId.action === "email_verification";
  //     }).length
  //   : 0;

  // var Waiting_for_admin = filater
  //   ? filater.filter(function (items) {
  //       //return items.account_refId.action === "Waiting_for_admin";
  //     }).length
  //   : 0;

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );

  const filteredItems = filater.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText("");
    //   }
    // };

    return (
      <div className="table-toolbar">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          //onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: " ",
      cell: (row) => <i class="fa fa-ellipsis-v" aria-hidden="true"></i>,
      //sortable: true,
    },

    {
      name: "",
      width: 200,
      cell: (row) => (
        <Link
          className="userPf"
          to={"/admin/userProfile/" + row?.account_refId?._id}
        >
          <Img
            src={
              row.assetManagement != null ? row.assetManagement?.filename : ""
            }
            alt="CALICUT DEFENCE"
          />
        </Link>
      ),
      //sortable: true,
    },
    {
      name: "Date",
      cell: (row) => <> {moment(row.createdAt).format("DD-MM-YYYY")}</>,
      sortable: false,
    },
    {
      name: "ID",
      //cell: (row) => row.userId,
      selector: "userId",
      sortable: true,
      sortbyOrder: "desc"
    },
    {
      name: "Name",
      cell: (row) => row.name,
      //sortable: true,
    },

    {
      name: "Groupd",
      cell: (row) => <Group obj={row.group_refId} />,
      //sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <div className={"adminstatus " + row?.account_refId?.action}>
          {" "}
          {row?.account_refId?.action}
        </div>
      ),

      //sortable: true,
    },

    {
      name: "subscribed",
      cell: (row) => (
        <div
          className={row.subscribed == "yes" ? "subscribed" : "unsubscribed"}
        >
          {" "}
          {row.subscribed == "yes" ? "subscribed" : " Un subscribed"}
        </div>
      ),
      //sortable: true,
    },

    {
      name: "whatsapp",
      selector: (row) => (
        <a
          className="whatp"
          target="_blank"
          href={"https://wa.me/" + row.whatsapp}
        >
          {row.whatsapp}{" "}
        </a>
      ),
    },

    {
      name: "Contact Number",
      selector: (row) => (
        <a className="call" target="_blank" href={"tel:" + row.contact}>
          {row.contact}{" "}
        </a>
      ), //call
    },
    // {
    //   name: 'DOB',
    //   selector: row => row.date_of_birth,
    // },
    {
      name: "Blood Group",
      selector: (row) => row.blood_group,
    },

    // {
    //   name: 'Role',
    //   selector: row =>  <Button className={'add-btn me-2'} onClick={()=>roleShow(row)} >
    //   Set Role
    // </Button>,
    // },
    {
      name: "Edit",
      cell: (row) => (
        <div class="btn-group" role="group" aria-label="Basic outlined example">
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => handleEdit(row)}
          >
            <i class="bi bi-pencil-fill"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => handleDelete(row)}
          >
            <i class="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: 200,
    },
  ];

  const handleEdit = (obj) => {
    setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };

  const handleDelete = (obj) => {
    if (window.confirm("Are  you ok with delete this User") == true) {
      deleteProfile({
        variables: {
          userId: obj.account_refId._id,
          profileid: obj._id,
        },
      }).then((res) => {
        window.location.reload();
        alert("user is  deleted");
      });
    }
  };

  const handleShow = () => {
    setShowNew(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };
  const handleCreateClose = () => {
    setShowNew(false);
  };

  {
    /* ***********Role*************** */
  }

  let list = userrole
    ? userrole.rolelist.map((res, key) => (
        <Button
          style={{ marginRight: "10px" }}
          onClick={(e) => {
            rolefilter(res._id);
          }}
        >
          {res.name}
        </Button>
      ))
    : null;
  const roleShow = (obj) => {
    setobj({ ...obj });
    setRoleName({
      name: null,
    });
    setShowRole(true);
    setEditAble(false);
  };
  const roleClose = () => {
    setShowRole(false);
    setEditAble(false);
  };

  const resetIdBtn=()=>{
    console.log('fff')
  }

  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return `Error! ${error.message}`;
  return (
    <div id="users-list">
      <AclPermission isActive={["admin", "President","Counsilor"]}>
        <div className="tbl">
          <div className="row">
            <div className="col-md-3">
              <Widget title="Total members" value={lists.length} />
            </div>
            <div className="col-md-3">
              <Widget title="Waiting for Admin" value={Waiting_for_admin} />
            </div>
            <div className="col-md-3">
              <Widget title="Email verification" value={email_verification} />
            </div>
            <div className="col-md-3">
              <Widget title="Active" value={active_v} />
            </div>
            <div className="col-md-3">
              <Widget title="blocked" value={blockedU} />
            </div>
            <div className="col-md-3">
              <Widget title="Rejected" value={rejected} />
            </div>
          </div>

          {/* ***************Role Button***************** */}

          {/* <div className='row'>
          <div className="col-md-6">
            <Search />
          
             </div>
             <div className="col-md-6">
            
             </div>
          </div> 
         
        
        </div>
        */}

          {/* ************************** */}

          <AdminPanel
            show={shownew}
            handleClose={(e) => {
              handleCreateClose();
            }}
            title="User Details"
          >
            <Create
              refetch={(e) => {
                refetchs();
              }}
              handleClose={(e) => {
                handleCreateClose();
              }}
            />
          </AdminPanel>

          <AdminPanel
            show={show}
            handleClose={(e) => {
              handleClose();
            }}
            title="User Details"
          >
            <Update
              obj={obj}
              edit={editAble}
              refetch={(e) => {
                refetchs();
              }}
              handleClose={(e) => {
                handleClose();
              }}
            />
          </AdminPanel>

          {/* *****************Role AdminPanel********************* */}
          <AdminPanel
            show={showrole}
            handleClose={(e) => {
              roleClose();
            }}
            title="User Details"
          >
            {/* <SetRole obj={obj} rol={roleList} refetch={e => { refetchs() }} handleClose={(e) => { roleClose() }} /> */}
          </AdminPanel>
          {/* ************************** */}

          <div className="users-list-table mt-3">
            {/* <div className='filter'>
  <Dropdown >
  <Dropdown.Toggle variant="success" id="dropdown-basic">
  <i class="fa fa-filter" aria-hidden="true"></i>
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
  </div>  */}

            {/* <Heading title="Users List" /> */}
     
         
         

            <ul class="nav nav-tabs" id="myTab" role="tablist">

             <AclPermission is_show={false} isActive={["President", "admin"]}>  
             <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Admin User List</button>
  </li>   
  
 </AclPermission> 
 {groupName?<AclPermission  is_show={false} isActive={["admin","Counsilor"]}>  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
      {groupName?groupName:''}
      </button>
  </li> </AclPermission> :""}

</ul>
<div class="tab-content" id="myTabContent">


<AclPermission is_show={false} isActive={["President", "admin"]}> <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

              <Button
                className={"add-btn"}
                onClick={handleShow}
                style={{ marginRight: "10px" }}
              >
                create
              </Button>
              <Export obj={filater} fileName="Members List" />


<DataTable
              // pagination
              // highlightOnHover
              columns={columns}
              // data={filater?filater:[]}
              // actions={actionsMemo}
              data={filteredItems}
              defaultPageSize={100}
              defaultSorted={[
                {
                  id: "Date",
                  desc: true,
                },
              ]}
              // defaultPageSize={30}
              //striped
              pagination
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
  
  
  </div></AclPermission>
  <AclPermission is_show={false}  isActive={["admin","Counsilor"]}>  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">


    {groupList.length>0?
  <DataTable
              // pagination
              // highlightOnHover
              columns={columns}
              // data={filater?filater:[]}
              // actions={actionsMemo}
              data={groupList}
              defaultPageSize={100}
              defaultSorted={[
                {
                  id: "Date",
                  desc: true,
                },
              ]}
              // defaultPageSize={30}
              //striped
              pagination
              subHeader
              subHeaderComponent={subHeaderComponent}
            /> 
            :""}
    
    
    </div></AclPermission>

</div>


        
          </div>
        </div>
      </AclPermission>
    </div>
  );
};

export default UsersList;

function Group({ obj }) {
  if (obj != null) {
    let re = obj.map((items) => {
      return <div id="group-tag"> {items.title}</div>;
    });
    return re;
  } else {
    return <> no Group</>;
  }
}

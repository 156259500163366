
import React from 'react'
import './style.scss';
import bannar4 from '../../assets/img/who we are.png'

import story from '../../assets/img/story.PNG'

import { Service_banner } from '../../shared'
export default function About() {
  return (
    <div id="aboutus">
      <section>
        <div className="who-we p-3 ">
          <div className="container ">
            <div className="row ">
              <h1>Who we are</h1>
              <p className="ps-1">There are numerous social and welfare communities functioning around us by means of politics, cast, religion, sports, arts etc to survive, share even for drugs, scam also.  All these communities  implies good and bad impacts in the society.  In this scenario, introducing a community that is entirely different from others with most effective outcomes. It is a companionship of serving and retired soldiers of defence as well as Para Military forces of Kozhikode district who have pledged to give their lives to protect the freedom and integrity of India.  The main objective of this community is to uphold the moral of defence personnel and provide support and shade to the socially and economically weaker public of Kozhikode district.  It is called 'Calicut Defence Trust and Care'."Nation Service is our Duty...People Service is our Happiness" is our vision.</p> <p className="ps-1">A soldier is always a soldier.  He is the one who can deliver service to common public beyond cast, creed,colour, religion and politics with utter dedication and sacrifice. Since, last three years we are on ground to fullfill our vision through our missions.The trust is leaded by Mr.Anil TK as President, Mr. Gireesh the Vice President, Mr. Sareesh the Secretary, Mr. Shidhin as Jt Secretary and Mr. Sarin as treasurer.  Moreover, it also includes approximately 1500  vibrant members to accomplish the task in a disciplined manner.  It is a great honour to inform that the source of income for all the task is from the salary and pension of its members.</p>
            </div>
          </div>
        </div>

        <div className="miltory-img ">
          <img src={bannar4} className="img-fluid" alt="" />
        </div>
      </section>

      <Service_banner />
      <section id="about">

        <div className="discription-box">
          <div className="container ">
            <div className="row ">
              <div className="   col-lg-4 col-md-12  ">
                <div className="vision">
                  <h2>Vision</h2>
                  <p >To protect the boarders, freedom and integrity of India is our basic duty and to serve the public with love and compassion is our happiness. To Serve the public beyond cast, creed, colour, religion and politics is part of life. What is a life time adventure for you Is a daily routine for us.... We regret...We have only one life To give for our country and serve the public. We are not doing any great job, but whatever we are doing is with our mind, heart and soul.</p>
                </div>
              </div>
              <div className="  col-lg-4 col-md-12 ">
                <div className="mision">
                  <h2>Mission</h2>
                  <p >A soldier is always a soldier. He is professional, hardworking, dedicated, passionate and ready to sacrifice his life for the country. However, it is a hard faith that his sacrifice is ignoring our society unless he dies. Our first priority is to uphold the values, moral and respect of a soldier and educate the society about the values of soldiers. A group of experienced soldiers are ever ready to deliver service to the state as well as common public beyond cast, creed, colour, religion and politics with dedication and passion. Not last but at the least</p>
                </div>
              </div>
              <div className=" col-lg-4 col-md-12 ">
                <div className="future">
                  <h2>Future</h2>
                  <p >To protect the boarders, freedom and integrity of India is our basic duty and to serve the public with love and compassion is our happiness. To Serve the public beyond cast, creed, colour, religion and politics is part of life. What is a life time adventure for you Is a daily routine for us.... We regret...We have only one life To give for our country and serve the public. We are not doing any great job, but whatever we are doing is with our mind, heart and soul.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section >
        <div className="story pt-3 ">
          <div className="container">

            <div className="row">
              <div className=" col-12 col-sm-12  col-md-12  col-lg-6 col-xl-6">
                <img src={story} className="img-fluid mb-3 mt-5" />
              </div>
              <div className=" col-12 col-sm-12  col-md-12  col-lg-6 col-xl-6">
                <h2 >Story</h2>

                <p className="ps-1">കോഴിക്കോട് ജില്ലയിലെ പട്ടാളക്കാരുടെ ഏറ്റവും വലിയ കൂട്ടായ്മ ആയ കാലിക്കറ്റ് ഡിഫെൻസ് എന്ന സംഘടന ഇന്ന് കാണുന്ന വളർചയിലേക്ക് എത്തിയ വഴികളിലൂടെയുള്ള ഒരു  തിരിഞ്ഞു നോട്ടം.....
 
 കർമ്മ ധീരനും വിശാല മനസ്‌കനുമായ ശ്രീ സരിൻ എന്ന സൈനികന്റെ ആശയത്തിൽ ജന്മം കൊണ്ട ഒരു WhatsApp കൂട്ടായ്മ, കോഴിക്കോട് ജില്ലയുടെ അഭിമാനമായ  ഒരു സൈനിക കൂട്ടായ്മ......അതാണ് കാലിക്കറ്റ് ഡിഫെൻസ് ട്രസ്റ്റ് & കെയർ
 
 കോഴിക്കോട് ജില്ലയിലെ വിരലിൽ എണ്ണാവുന്ന കുറച്ച് ആളുകൾ മാത്രം ആയിരുന്നു ആദ്യ ദിനങ്ങളിൽ  കൂട്ടായ്മയിൽ ഉണ്ടായിരുന്നത്. തമാശയും ട്രോളുകളും  മാത്രമായി തുടങ്ങി  പിന്നീട് ചെറിയ ദുരിതാശ്വാസ പ്രവർത്തനവും ചില അഗതി മന്ദിര  സന്ദർശനവും അവരുടെ ഒരു നാളത്തെ വിശപ്പടക്കാനുള്ള ഭക്ഷണം, ജില്ലയിലെ വഴിയോരങ്ങളിൽ രാത്രി അന്തിയുറങ്ങുന്ന ആരോരുമില്ലാത്തവർക്ക് പുതപ്പു നൽകിയും മറ്റും ചെറിയ രീതിയിൽ ഉള്ള കാരുണ്യ പ്രവർത്തനത്തിന് തുടക്കം കുറിച്ചു.</p>
 
 <p className="ps-1">ചെറിയ ചില പ്രവർത്തനങ്ങൾ നടത്തി മുന്നോട്ട് പോകുന്തോറും ഈ വിവരങ്ങൾ എല്ലാം കേട്ടറിഞ്ഞ്   മറ്റു സൈനികർ  ഒറ്റയായും കൂട്ടമായും ഈ കൂട്ടായ്മയുടെ ഭാഗം ആവാൻ തുടങ്ങി.. സോഷ്യൽ മീഡിയ വഴി അല്ലാതെ നേരിൽ കണ്ടിട്ടില്ലാത്ത ഇവർ ഒരു സായാഹ്നത്തിൽ കോഴിക്കോട് ബീച്ചിൽ  ഒത്തുചേർന്ന് 'കാലിക്കറ്റ് ഡിഫെൻസ് 'എന്ന പുതിയ അധ്യായത്തിന്റെ തുടക്കം കുറിക്കുകയായിരുന്നു
 
 കാലിക്കറ്റ് ഡിഫെൻസ് അതിന്റെ ഉദ്ദേശ്യ ലക്ഷ്യത്തിലേക്കുള്ള ആദ്യ പാത കണ്ടെത്തുന്നത്,
 ഫേസ് ബുക്കിൽ കണ്ട താമരശ്ശേരി പൂവൻമലയിൽ ബാലൻ ചേട്ടന്റെ  ദയനീയ അവസ്ഥ അറിഞ്ഞപ്പോൾ ആണ്... ബാലൻ ചേട്ടനെ സഹായിക്കുക എന്ന ആദ്യ ലക്ഷ്യത്തിൽ ശ്രീ.സരിന്റെ പേർസണൽ അക്കൗണ്ട് ഉപയോഗിച്ച് ഫണ്ട് കലക്ഷൻ നടത്തുകയും അതിൽ നിന്ന് കിട്ടിയ തുക ഉപയോഗിച്ച്  ബാലൻചേട്ടന് ഭക്ഷണ  സാധനങ്ങൾ,  വസ്ത്രങ്ങൾ, വിരി, പുതപ്പ് ഉൾപ്പെടെ  വലിയൊരു കിറ്റ് കൊടുത്തു കൊണ്ട് ആദ്യ സഹായത്തിനു തുടക്കം കുറിക്കുകയും ചെയ്തു.കടൽ തിരമാലകളെ പോലെ ഒരിക്കലും അടങ്ങാത്ത ആവേശത്തിന്റെ തുടക്കം ആയിരുന്നു ആ സംഭവം ആ കൂട്ടായ്മക്ക് സമ്മാനിച്ചത്...അങ്ങിനെ ആ ഒരു സംഭവം  ഈ പട്ടാളക്കാരുടെ മനസ്സിൽ ഒരു വല്ലാത്ത ആനന്ദം ആണ് സമ്മാനിച്ചത്..</p></div>
 
<div>
  <p className="ps-1">.അവർ കാരണം ഒരു സാധു മനുഷ്യന്റെ ജീവിതത്തിൽ ഇത്തിരി പുഞ്ചിയുടെ വെട്ടം കൊടുക്കാൻ ആയല്ലോ എന്നത് അവരെ സംബന്ധിച്ചിടത്തോളം വളരെ വലിയ പുണ്യപ്രവർത്തി ആയിരുന്നു. 
 ആ സംഭവം അറിഞ്ഞതിനു ശേഷം സംഭാവനകൾ ചെയ്യുന്നവരുടെ എണ്ണവും തുകയും കൂടാൻ  തുടങ്ങി. അക്കൗണ്ട് ബാലൻസ് ഒന്നര ലക്ഷം ആയി ഉയർന്നു. അതോടെ ആവേശം തിരതല്ലി.
 
 സോഷ്യൽ മീഡിയ പ്ലാറ്റ്ഫോം ആയ WhatsApp ൽ ആളുകളെ ചേർത്താൻ ഉള്ള  ലിമിറ്റ് 257 മാത്രം ആയത് കൊണ്ട്  ഒരു ഗ്രൂപ്പിൽ നിന്നും ഗ്രൂപ്പുകൾ 2 ആയി 3 ആയി....ഇപ്പോൾ അത് 6 വരെ എത്തി നിൽക്കുന്നു.
 
 പിന്നീടുള്ള വർഷങ്ങളിൽ.. മാറി മാറി വന്ന വെള്ളപൊക്കം, നാട്ടിലെ ചെറിയ ദുരന്തങ്ങൾ ,അവിടെയെല്ലാം സൈനികന്റെ മനോധൈര്യം ഉപയോഗിച്ചു വേറിട്ട ഒരു മുഖമായി മാറി ആ നാട്ടുകാരുടെ സങ്കടങ്ങളിലും സന്തോഷത്തിലും തോളോട് തോൾ ചേർന്ന് നിന്നുകൊണ്ട് അവരുടെയെല്ലാം പ്രശംസ പിടിച്ചു പറ്റി   ചെറിയ രീതിയിൽ പിച്ച വെച്ച് തുടങ്ങിയ സംഘടന.. ഇന്ന് കാണുന്ന അതിന്റെ പ്രതാപത്തോടെ വരുന്നത്, 2020-ൽ ലോകത്താകമാനം മാനവരാശിയുടെ ജീവിതലക്ഷ്യത്തെ  താളം തെറ്റിച്ച, സാമ്പത്തിക ഭദ്രത അടി മുടി  തകർത്ത Covid മഹാ മാരിയിൽ സർവവും നഷ്ടപ്പെട്ട  നമ്മുടെ നാട്ടിലെ ജനതയ്ക്ക് തങ്ങളാൽ കഴിയുന്ന സഹായവും ദിശാ ബോധവും നൽകി കൊണ്ട് ആയിരുന്നു..
 
 ലോക്ക് ഡൗൺ കാലത്ത് സാമ്പത്തിക ബുദ്ധിമുട്ട് അനുഭവിച്ച ആയിരത്തിലധികം കുടുംബങ്ങൾക്ക് പലവ്യഞ്ജന കിറ്റുകളും, മുന്നൂറിൽ അധികം രോഗികൾക്ക് ആവശ്യമുള്ള മരുന്നും എത്തിച്ചു നൽകി. 
 
 ഇരുന്നൂറോളം മാറാരോഗികൾക്കും, നിർധന കുടുംബത്തിലെ  സഹോദരിമാരുടെ കല്ല്യാണത്തിനും,  വീട് നിർമാണത്തിനും, ജീവിതം വഴിമുട്ടി ആത്മഹത്യ ചെയ്യേണ്ടി വന്നവരുടെ കുടുംബത്തിനും തങ്ങളാൽ കഴിയുന്ന സഹായധനം കൈമാറി.
 
 കോവിഡ് പ്രതിരോധത്തിൽ ഏർപ്പെട്ട ആരോഗ്യ പ്രവർത്തകരെ അനുമോദിക്കുകയും അവർക്കുള്ള പ്രതിരോധകിറ്റുകൾ ജില്ലാകളക്ടർക്ക് കൈമാറുകയും ചെയ്തു.
 
 </p>
 <p className="ps-1">സ്ഥിരമായി Blood Donation Camp സംഘടിപ്പിച്ചു ജില്ലയിലെ ആശുപതികളിലെ ബ്ലഡ് ബാങ്കിലെ രക്തക്ഷാമം ഒരു പരിധി വരെ പരിഹരിക്കുവാൻ ശ്രമിക്കുന്നു.കൂടാതെ അത്യാവശ്യ സമയത്തു രക്തം ആവശ്യമായി വരുമ്പോൾ  നിസ്സഹായരായി നിൽക്കുന്ന ആളുകൾക്ക്  രക്തദാനം നടത്തി അവരുടെ ആശങ്കയും പരിഹരിക്കാൻ ശ്രമിക്കുന്നു .
 
 പ്രധാനപ്പെട്ട വാർഷിക ആഘോഷ സമയങ്ങളിൽ അനാഥമന്ദിരങ്ങളിൽ ഭക്ഷണം വിതരണം ചെയ്യുകയും ചെയ്യുന്നു
 
 ഓണത്തിന്റെ സമയത്ത്  മാത്രം മെമ്പർമാരുടെ സംഭാവന വഴി സമാഹരിച്ച  2 lakhs രൂപ 35 ഓളം കുടുംബത്തിന് സഹായധനമായി  കൈമാറി
 
 നമ്മുടെ നാട്ടിലെ കഷ്ടത അനുഭവിക്കുന്ന പട്ടാളക്കാരെ കണ്ടെത്തി അവർക്ക് വേണ്ട എല്ലാ സഹായങ്ങളും എത്തിച്ചു നൽകുന്നു
 
 ദീപാവലി / ശിശുദിനം തുടങ്ങിയ വിശേഷ ദിനങ്ങളിൽ കുട്ടികൾ മാത്രമുള്ള അനാഥ മന്ദിരങ്ങളിൽ  ഭക്ഷണവും അവർക്ക് പഠനകിറ്റ് എന്നിവ നൽകി അവരോടൊത്ത് ആഘോഷിച്ചു. കൂടാതെ ആദിവാസി കോളനിയിൽ ഉള്ള കുട്ടികൾക്ക് പഠന സാമഗ്രികൾ നൽകി മാതൃക കാട്ടി..
 
 സർവീസിൽ നിന്നും  വിരമിച്ചു ജന്മനാട്ടിലേക്ക് തിരികെ എത്തുന്ന വീര ജവാന്മാരെ സ്വീകരിച്ചു ആനയിച്ചു അവരുടെ വീടുകളിൽ എത്തിക്കുന്നതും മെമ്പർമാരുടെയും ആശ്രിതരുടെയും വിവാഹം, ഗൃഹപ്രവേശം എന്നീ സന്തോഷ മുഹൂർത്തങ്ങളിലും,  സൈനികരുടെ ഉറ്റവരുടെ വിയോഗത്തിലും അവർക് താങ്ങായും തണലായും കൂടെ നിൽക്കാറുണ്ട്.
 
 ഇന്ന് കോഴിക്കോട് ഉള്ള  സൈനികരുടെ ഏറ്റവും വലിയ ജീവകാരുണ്യ ട്രസ്റ്റ് ആയി രജിസ്റ്റർ ചെയ്ത ഈ സംഘടനയെ നയിക്കുന്നത് പ്രസിഡന്റ് അനിൽ ടി.കെ, വൈസ് പ്രസിഡന്റ് ഗിരീഷ്, സെക്രട്ടറി സരീഷ്‌, ജോയിന്റ് സെക്രട്ടറി ഷിധിൻ, ട്രഷററായി സരിൻ നടുവണ്ണൂരും കൂടാതെ എന്തിനും തയ്യാർ ആയ 1400 ഓളം സൈനികരും ആണ്.
 
 സാമ്പത്തിക സ്രോതസ്സുകളുടെ ഉറവിടം സ്നേഹ മനസ്കരായ ഞങ്ങളുടെ അംഗങ്ങളായ സൈനികരിൽ നിന്നുമുള്ള സംഭാവനകളാണ്. പുറത്ത് നിന്നുള്ള ആളുകളുടെ സാമ്പത്തിക സഹായമോ, സർക്കാർ സഹായങ്ങളോ ഇതിൻ്റെ പിന്നിലില്ല. തൻ്റെ രാജ്യത്തിൻ്റെ സുരക്ഷിതത്വത്തോടൊപ്പം  തന്നെ സഹജീവികളെ സ്നേഹിക്കാനും സഹായിക്കാനും മനസ്സു കാണിക്കുന്ന സ്തുത്യർഹമായ സേവനമാണ് ഞങ്ങൾ    സമൂഹത്തിന് കാഴ്ചവെക്കുന്നത് എന്നാണ് വിശ്വാസം.
 
 ഇതുവരെ ചെയ്തതിൽ നിന്നും ഉപരിയായി എന്തൊക്കെയോ ഈ സമൂഹത്തിനു വേണ്ടി ചെയ്യാനുണ്ട് എന്ന തിരിച്ചറിവ് ആണ് ഈ സംഘടനക്ക് മുന്നോട്ടു പോകാനുള്ള വെളിച്ചവും പ്രചോദനവും നൽകുന്നത്
 
  ...തന്റെ സഹജീവികളുടെ ജീവൽപ്രശ്നങ്ങൾ തന്റെ കൂടി പ്രശ്നമാണെന്ന തിരിച്ചറിവോടെ...
 അവരെ ആശ്വസിപ്പിക്കാൻ,അവർക്ക് ഒരു ശരിയായ ദിശാബോധം നല്കാൻ കഴിയും എന്ന വിശ്വാസത്തോടെ......മുന്നോട്ട്...</p>
              </div>
            </div>
          </div>
        </div>

      </section>

    </div>
  )
}
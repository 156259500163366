import React from 'react'
import './style.scss'
export default function Success() {
    return (
        <div id="Success">
            <div className='icon'></div>
       <h2>Account  successfully registered  & complete your account  verification </h2>
       <p> please  check  your Email</p>
        </div>
    )
}

import React from "react";
import { Link } from "react-router-dom";
import Img from "./img";
import DateSpan from "./date";
import img from "../assets/img/add1.PNG";
export default function Activity_list({
  title,
  _id,
  body,
  assetManagement,
  createdAt,
}) {
  return (
    <div className="col-12 col-sm-12 col-md-12">
      <div className="activity-row-items-box">
        <div className="row">
          <div className="col-3 col-sm-3 col-md-3">
            <Img
              src={assetManagement ? assetManagement.filename : ""}
              width={547}
              height={500}
            />
          </div>
          <div className="col-9 col-sm-9 col-md-9">
            <Link className="nav-link" to={"/activity_details/" + _id}>
              <h5>{title} </h5>
            </Link>
            <p>{body}</p>
            <div className="footer-area">
              <div className="footer-area">
                <span className="created-by">
                  {" "}
                  <DateSpan dat={createdAt} type="MMMM Do YYYY" />{" "}
                </span>
                <span className="more">
                  {" "}
                  <Link className="more-btn" to={"/activity_details/" + _id}>
                    {" "}
                    More{" "}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";
import Advt from "./advt_top";
import { Link } from "react-router-dom";
import logo from "../assets/img/cdimg1.PNG";
import banner from "../assets/img/banner.png";
import Donate from "../page/profile/donate";
import { gql, useQuery, useMutation } from "@apollo/client";
import User from "./user";
import { Img } from "../shared";
import {Adsense} from '@ctrl/react-adsense';
const ADS_QUERY = gql`
  {
    ads_list_byuser(obj: { page: 1, skip: 0, place: "home_top" }) {
      title
      _id
      assetManagement {
        filename
      }
    }
  }
`;
const Navbar = () => {
  const { data: ads, loading, error, refetch } = useQuery(ADS_QUERY);
  let advt = banner;
  if (ads) {
    if (ads.ads_list_byuser.length > 0) {
      advt =
        process.env.REACT_APP_STATIC +
        ads.ads_list_byuser[0].assetManagement.filename;
    }
  }
  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <div className="head-portion-img-txt">
                <div className="head-portion-img">
                  <Link to="home">
                    <img src={logo} alt="CALICUT DEFENCE" />{" "}
                  </Link>
                </div>
                <div className="head-portion-txt">
                  <Link to="home">
                    {" "}
                    <h1>CALICUT DEFENCE</h1>
                    <h1> TRUST & CARE</h1>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-9 top">
              <div className="row">
                <div className="col-6 col-sm-12 col-md-10">
                  {/* <img src={advt} width={700} height={80} /> */}
                
<Adsense
  client="ca-pub-5617874027951228"
  slot="8241758304"
  style={{ width: 700, height: 90 }}
  format=""
/>
                </div>

                <div className="col-sm-12 col-md-2">
                  <div className="contact-callus">
                    <h3>Call Us</h3> {Advt}
                    <p>
                      <a href="tel:9037215011">+91 90372 15011</a>
                    </p>
                  </div>
                  <div className="social-me-icons">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/groups/273514820725673/?ref=share"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://chat.whatsapp.com/IWL8JnZRDNnFdMhRiYH1Fg"
                    >
                      <i className="fab fa-whatsapp-square"></i>
                    </a>
                    <a target="_blank" href="https://t.me/+rx92d-OdF8g1NTM1">
                      <i className="fab fa-twitter-square"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://youtube.com/channel/UCk9Z6Mbq61xqLZ-ajezV6Rw"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="green-nav">
        <div className="container">
          <div className="row">
            <div className="menu col-12 col-sm-12 col-md-9">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link className="nav-link" to="/home">
                          Home{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/about">
                          About{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/administration">
                          Administration{" "}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/event">
                          {" "}
                          Event
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/gallery">
                          Gallery{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/news">
                          News{" "}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/activity">
                          Activity{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact">
                          Contact{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-12 col-sm-12 col-md-3">
              <div className="sign-in-box Donate-btn ">
                <Donate />

                <User />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;

import React from "react";
import { News_list, Advt, Img, Share_btn, DateSpan } from "../../shared";
import "./style.scss";
import Onenews from "./one";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";

const NEWS_VIEW_QUERY = gql`
  query news($id: ID!) {
    news_viewById(id: $id) {
      title
      body
      createdAt
      assetManagement {
        filename
      }
    }
  }
`;
const ADS_QUERY = gql`
  {
    ads_list_byuser(obj: { page: 10, skip: 0, place: "news_detals" }) {
      title
      _id
      assetManagement {
        filename
      }
    }
  }
`;
// const EVENT_VIEW_QUERY = gql`
// query events($id:ID!) {
// 	event_viewById(id: $id){
//         title
//         body
//         createdAt
//         start_date
//         #assetManagement{
//           #filename}
//         }
//     }
// `;

// const NEWS_DETAILS_QUERY = gql`
//   {
//     query news_viewById($id:ID!) {
//     news_viewById(id:$id){
//         title
//         body
//         createdAt
//         assetManagement{
//           filename
//         }
//       }
//     }
// `;

export default function News_details() {
  const { id } = useParams();

  const { data: ads } = useQuery(ADS_QUERY);
  let advt = [];
  if (ads) {
    if (ads.ads_list_byuser) {
      advt = ads.ads_list_byuser.map((item, key) => {
        return (
          <Img
            width={300}
            height={300}
            src={item.assetManagement ? item.assetManagement.filename : ""}
            alt="CALICUT DEFENCE"
            key={key}
          />
        );
      });
    }
  }
  const { data, loading, error, refetch } = useQuery(NEWS_VIEW_QUERY, {
    variables: { id: id },
  });

  let { body, createdAt, title, assetManagement } = data
    ? data.news_viewById
    : {};
  return (
    <section id="news_details">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-9">
            <div className="news_details-container">
              <Img src={assetManagement ? assetManagement.filename : ""} />
              <div className="row">
                <div className="col-2">
                  <span className="createdAt">
                    {" "}
                    <DateSpan dat={createdAt} type="MMMM Do YYYY" />
                  </span>
                  <span className="social">
                    {" "}
                    <Share_btn />
                  </span>
                </div>
                <div className="col-12 col-sm-12 col-md-10">
                  <h1> {title}</h1>
                  <p> {body}</p>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="col-3">{advt}</div>
        </div>
      </div>
    </section>
  );
}

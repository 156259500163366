import React, { forwardRef, useState, Fragment, useEffect, Suspense, lazy, useRef, useImperativeHandle } from 'react';
import { Form, Button,Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.scss'
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

const FORGOT_MUTATION = gql`
  mutation forgotPasswordToken(
    $email: String!

    ) {
      forgotPasswordToken(
        email: $email

    ) {
        status
    }
  }
`;


function Forget() {
  const [querys, { loading, error }] = useMutation(FORGOT_MUTATION);
  const [isValid, setIsValid] = useState('');
  const [inputForm, setForm] = useState({
    email: null,

  })
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values)

  }
  const submitForm = () => {

    const values = { ...inputForm };
    if (!values.email)  {
      return(setIsValid('Please fill the field'));
    }
  else{
      setIsValid(null);
  }

    querys({ variables: { ...values } }).then(res => {
    
    if(res.data.forgotPasswordToken.status)
    {
    return(setIsValid('Request Successfull'))
    }
    else{
      setIsValid('Request failed');
  }
  })

  }

  return (
    <div id="forget">
      {isValid ? <Alert variant="danger">{isValid}</Alert> : "" }
      <div className="d-flex align-items-center auth px-0 mt-5">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
         
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => formChange(e)} />
              </Form.Group>
              <div class="ctdc-btn">
                <Button className="submit-btn" type="submit" onClick={(e) => submitForm()}>
                  Submit
                </Button>
              </div>
         
            <Link to="login">Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forget

import React, { useState } from 'react';
import { useQuery, gql, useMutation } from "@apollo/client";
import { Img } from '../../../shared'
import Chil from '../../../assets/img/chil.PNG'
import { Alert } from 'react-bootstrap';
import './style.scss';

const ASSET_DELETE_MUTATION = gql`
  mutation gallery_delete(
    $id: ID!
    ) {
        gallery_delete(id:$id) {
        status
    }
  }
`;

export default function AssetList(props) {
  const [deleteAsset, { loading, error }] = useMutation(ASSET_DELETE_MUTATION);
  const [isdelete, setDelete] = useState('');

  const handleDeleteClick = (e) => {
    deleteAsset({ variables: { id: e._id } }).then(res => {
      setDelete('Deleted Successfully')
    }).then(res => {
      props.refetch()
    })
    setTimeout(() => {
      setDelete(null)
    }, 1500)
  }

  console.log(props.obj)

  return (

    <>
      {isdelete ? <Alert variant='success'>{isdelete}</Alert> : ""}
      <div className="col-3 col-sm-3 col-md-3 asset" >
        <div className="asset-trash" onClick={() => handleDeleteClick(props.obj)}></div>
        <Img src={props.obj.assetManagement ? props.obj.assetManagement.filename : null} class="img-fluid" />

      </div>
    </>
  )
}

import React, { forwardRef, useState, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { ImageUpload, Heading, InputTextPrompt, Img } from "../../../shared";
import { Button, Alert, Col, Form, Row } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ACTIVITIES_MUTATION = gql`
  mutation activity_create(
    $title: String!
    $body: String!
    $meta_keyword: String!
    $meta_description: String!
    $seo_title: String!
    $file: String
  ) {
    activity_create(
      obj: {
        title: $title
        body: $body
        meta_keyword: $meta_keyword
        meta_description: $meta_description
        seo_title: $seo_title
        file: $file
      }
    ) {
      status
    }
  }
`;

const ACTIVITIES_BY_UPDATE_MUTATION = gql`
  mutation activity_updateById(
    $title: String!
    $body: String!
    $meta_keyword: String!
    $meta_description: String!
    $seo_title: String!
    $file: String
    $_id: String!
  ) {
    activity_updateById(
      obj: {
        title: $title
        body: $body
        meta_keyword: $meta_keyword
        meta_description: $meta_description
        seo_title: $seo_title
        file: $file
        _id: $_id
      }
    ) {
      status
    }
  }
`;

const Add = forwardRef((props, ref) => {
  const [createActivity, { loading, error }] = useMutation(ACTIVITIES_MUTATION);
  const [UpdateActivity] = useMutation(ACTIVITIES_BY_UPDATE_MUTATION);

  const [isValid, setIsValid] = useState("");
  const upload_Ref = useRef();
  const [fields, setFields] = useState([
    { title: null, body: null, files: null },
  ]);
  const [inputForm, setForm] = useState({
    title: props.obj.title ? props.obj.title : null,
    body: props.obj.body ? props.obj.body : null,
    seo_title: props.obj.seo_title ? props.obj.seo_title : null,
    meta_description: props.obj.meta_description
      ? props.obj.meta_description
      : null,
    meta_keyword: props.obj.meta_keyword ? props.obj.meta_keyword : null,
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
  });

  useEffect(() => {
    setSrc(
      props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.assetManagement.filename
        : ""
    );
  }, [props]);
  const submitForm = () => {
    const file = cropper.getCroppedCanvas().toDataURL();
    const values = { ...inputForm };

    values["file"] = file;

    if (
      !values.title ||
      !values.body ||
      !values.seo_title ||
      !values.meta_keyword ||
      !values.meta_description
    ) {
      return setIsValid("Please Check All The Field");
    } else {
      setIsValid("");
    }

    if (props.editAble) {
      values._id = props.obj._id;

      UpdateActivity({ variables: { ...values } }).then((res) => {
        setIsValid("Updated Successfully");
        props.refetch();
      });
      timeOutDelay();
    } else {
      createActivity({ variables: { ...values } }).then((res) => {
        setIsValid("Created Successfully");
        props.refetch();
      });
      timeOutDelay();
    }
  };
  const timeOutDelay = () => {
    setTimeout(() => {
      props.handleClose();
    }, 2000);
  };
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();

  return (
    <div id="activity_add">
      <div className="row">
        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.title}
              placeholder="Enter Title"
              name="title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Content <span className="mandatory">*</span>
            </Form.Label>
            <textarea
              type="text"
              value={inputForm.body}
              placeholder="Enter Name"
              name="body"
              rows="8"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              SEO Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.seo_title}
              placeholder="SEO Title"
              name="seo_title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            Meta Keyword<span className="mandatory">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={inputForm.meta_keyword}
            placeholder="Meta Keyword"
            name="meta_keyword"
            onChange={(e) => formChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            Meta Description<span className="mandatory">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={inputForm.meta_description}
            placeholder="Meta Description"
            name="meta_description"
            onChange={(e) => formChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="file">
          <input type="file" onChange={onFileChange} />
          <Cropper
            style={{ height: 282, width: 450 }}
            aspectRatio={3.2 / 4}
            guides={false}
            src={srcCrop}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            viewMode={1}
            dragMode="move"
            cropBoxResizable={false}
            cropBoxMovable={false}
          />
        </Form.Group>
      </div>
      <div>
        <div className="col-lg-12">
          <Button varient="primary" onClick={(e) => submitForm()}>
            Save
          </Button>
          {isValid ? (
            <Alert className="mt-3" variant="success">
              {isValid}
            </Alert>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});

export default Add;


import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";


import './sb-admin-2.scss';
import './style.scss';
import AdminNavbar from './AdminNavbar'
import Topmenu from './topMenu'
const AdminLayout = () => (
  <div id="page-top" className=''>
    <div id="wrapper">
      <AdminNavbar />
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Topmenu />
          <div class="container-fluid">
            <Outlet />
          </div>
        </div>

        <footer class="sticky-footer bg-white">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright &copy; Your Website 2020</span>
            </div>
          </div>
        </footer>


      </div>


    </div>


  </div>


)


export default AdminLayout;
import React from 'react'
import eventimg from '../assets/img/chil.PNG'
export default function Service_banner() {
    return (
        <section className='service-banner'>
        <div className="container">

          <div className="row">
          
            <div className="col-3 col-sm-3 col-md-3 borders" >   <span className='service edu'> </span> <p> Brave Hearts</p></div>
            <div className="col-3 col-sm-3 col-md-3 borders" >  <span className='service adv'> </span> <p> Charity and Service</p></div>
            <div className="col-3 col-sm-3 col-md-3 borders" > <span className='service blood'> </span>
              <p> Blood Donation </p>
            </div>
            <div className="col-3 col-sm-3 col-md-3" >    <span className='service camp'> </span> <p> Welfare, Arts And Sports</p></div>
          </div>
        </div>
      </section>
    

    )
}

import React from 'react'
import './heading.scss';
function Heading(props) {
    return (
        <div id="heading">
            <h2 className="title float-left ">{props.title}</h2>
        </div>
    )
}

export default Heading
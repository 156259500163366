import React, {
  forwardRef,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
  useImperativeHandle,
} from "react";

import { useQuery, gql, useMutation } from "@apollo/client";
import { Button, DropdownButton, Dropdown, Alert, Form } from "react-bootstrap";
import { ImageUpload } from "../../../shared";
import AdminPanel from "../../../shared/adminpanel";
import "./style.scss";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
const GALLERY_MUTATION = gql`
  mutation gallery_create(
    $title: String!
    $type: String!
    $url: String!
    $file: String
    $date: String
    $folderId: String
  ) {
    gallery_create(
      obj: {
        title: $title
        type: $type
        url: $url
        file: $file
        imageDate: $date
        folderId: $folderId
      }
    ) {
      status
    }
  }
`;
const GALLERY_FOLDER_LIST = gql`
  {
    getFolderList {
      title
      folderYear
      id
    }
  }
`;
const Add = forwardRef((props, ref) => {
  const upload_Ref = useRef();
  const title_Ref = useRef();
  const url_Ref = useRef();
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState("");
  const { data, refetch } = useQuery(GALLERY_FOLDER_LIST);
  const [createGallery, { loading, error }] = useMutation(GALLERY_MUTATION);
  const [inputForm, setForm] = useState({
    title: null,
    url: null,
    type: null,
    file: null,
    date: null,
    folderId: null,
  });
  const submitVideo = () => {
    const values = { ...inputForm };
    values["file"] = null;
    createGallery({ variables: { ...values } }).then((res) => {
      setIsValid("Created successfully");
      props.refetch();
      setTimeout(() => {
        props.handleClose();
      }, 2000);
    });
  };

  const submitForm = () => {
    const values = { ...inputForm };
    const file = cropper.getCroppedCanvas().toDataURL();
    values["file"] = file;
    values.url = "null";

    createGallery({ variables: { ...values } }).then((res) => {
      setIsValid("Created successfully");
      props.refetch();
      setTimeout(() => {
        props.handleClose();
      }, 2000);
    });
    setTimeout(() => {
      setIsValid(null);
    }, 2000);
  };
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();
  return (
    <div class="col-12">
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Gallery Type<span className="mandatory">*</span>
        </Form.Label>
        <Form.Select
          value={inputForm.type}
          name="type"
          onChange={(e) => formChange(e)}
        >
          <option value="none" selected>
            Select Image or Video
          </option>
          <option value="Image">Image</option>
          <option value="Video">Video</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label style={{ width: "100%", position: "relative" }}>
          Select folder<span className="mandatory">*</span>
        </Form.Label>
        <Form.Select
          value={inputForm.folderId}
          name="folderId"
          onChange={(e) => formChange(e)}
        >
          <option value="none" selected>
            Select folder
          </option>
          {data
            ? data.getFolderList.map((items, key) => {
                return (
                  <option key={key} value={items.id}>
                    {items.title}
                  </option>
                );
              })
            : []}
        </Form.Select>
      </Form.Group>
      {/* *******************Video********************* */}
      {inputForm.type == "Video" ? (
        <>
          <Form.Group className="mt-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="title"
              value={inputForm.name}
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="formBasicEmail">
            <Form.Label>
              Video URL<span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="url"
              placeholder="Enter Video Url"
              name="url"
              value={inputForm.url}
              onChange={(e) => formChange(e)}
            />
          </Form.Group>{" "}
          <Button
            variant="primary"
            className="mt-3"
            onClick={(e) => submitVideo()}
          >
            Save
          </Button>
        </>
      ) : null}
      {/* ********************Image******************** */}
      {inputForm.type == "Image" ? (
        <>
          <Form.Group className="mt-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="title"
              value={inputForm.name}
              onChange={(e) => formChange(e)}
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="file">
            <input type="file" onChange={onFileChange} />
            <Cropper
              style={{ height: 282, width: 450 }}
              aspectRatio={2 / 2}
              guides={false}
              src={srcCrop}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              viewMode={1}
              dragMode="move"
              cropBoxResizable={false}
              cropBoxMovable={false}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="mt-3"
            onClick={(e) => submitForm()}
          >
            Save
          </Button>
        </>
      ) : null}

      {isValid ? (
        <Alert className="mt-3" variant="success">
          {isValid}
        </Alert>
      ) : (
        ""
      )}
    </div>
  );
});

export default Add;

import React, { forwardRef, useRef, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import "./style.scss";
import { Heading, ImageUpload, Img } from "../../../shared";
import { isConstValueNode } from "graphql";

const SIGNUP_MUTATION = gql`
  mutation siginUpByadmin(
    $email: String!
    $hash: String!
    $file: Upload
    $name: String!
    $lastname: String!
    $date_of_birth: String!
    $blood_group: String!
    $blood_donation: Boolean
    $ex_ServiceMan: String!
    $contact: String!
    $whatsapp: String!
    $address: String!
    $post: String!
    $pin: String!
    $district: String!
    $docfile: Upload
    $family: JSON
    $verificationtype: String!
    #subscribed: Boolean
    #in_Service : $in_Service,
    $area: String!
    $date_of_marriage: String!
    $occupation: String!
  ) {
    siginUpByadmin(
      obj: {
        email: $email
        hash: $hash
        file: $file
        name: $name
        lastname: $lastname
        date_of_birth: $date_of_birth
        blood_group: $blood_group
        blood_donation: $blood_donation
        district: $district
        ex_ServiceMan: $ex_ServiceMan
        contact: $contact
        whatsapp: $whatsapp
        address: $address
        post: $post
        pin: $pin
        docfile: $docfile
        family: $family
        verificationtype: $verificationtype
        #subscribed: $subscribed,
        #in_Service : $in_Service,
        area: $area
        occupation: $occupation
        date_of_marriage: $date_of_marriage
      }
    ) {
      status
    }
  }
`;
//no file upload in profile_create
//email value is null
//no docfile upload in profile-create

const STATUS_EDIT_MUTATION = gql`
  mutation updateStatusForUser($action: String, $id: String) {
    updateStatusForUser(obj: { id: $id, action: $action }) {
      status
    }
  }
`;

const PROFILE_UPDATE_MUTATION = gql`
  mutation profile_update_admin(
    $name: String!
    $lastname: String!
    $date_of_birth: String!
    $blood_group: String!
    $blood_donation: Boolean
    $ex_ServiceMan: Boolean!
    $contact: String!
    $whatsapp: String!
    $address: String!
    $post: String!
    $pin: String!
    $district: String!
    #$in_Service: String!
    #$docfile: Upload
    #$file: Upload
    $verificationtype: String!
    $subscribed: Boolean
    $area: String!
    $date_of_marriage: String!
    $occupation: String!
    $_id: String!
  ) {
    profile_update_admin(
      obj: {
        name: $name
        lastname: $lastname
        date_of_birth: $date_of_birth
        blood_group: $blood_group
        blood_donation: $blood_donation
        district: $district
        ex_ServiceMan: $ex_ServiceMan
        contact: $contact
        whatsapp: $whatsapp
        address: $address
        post: $post
        pin: $pin
        #in_Service : $in_Service,
        #docfile:$docfile,
        #file: $file,
        verificationtype: $verificationtype
        subscribed: $subscribed
        area: $area
        occupation: $occupation
        date_of_marriage: $date_of_marriage
        _id: $_id
      }
    ) {
      status
    }
  }
`;

const View = forwardRef((props, ref) => {
  const [updateStatus] = useMutation(STATUS_EDIT_MUTATION);
  const [createProfile] = useMutation(SIGNUP_MUTATION);
  const [updateProfile] = useMutation(PROFILE_UPDATE_MUTATION);
  const [fields, setFields] = useState([{ name: null, value: null }]);
  const [edit, setForm] = useState(props.edit);
  const [isValid, setIsValid] = useState("");
  const upload_Ref = useRef();
  const doc_upload_Ref = useRef();
  // const [validation,setValidation] = useState([{ field: null, error: null }])
  const [status, setStatus] = useState({
    action: null,
  });
  // const [validation,setValidation] = useState([{ field: null, error: null }])
  const [userstatus, setUserStatus] = useState(props.obj.account_refId.action);

  const [validation, setValidation] = useState({
    name: "",
    lastname: "",
    email: "",
    hash: "",
    date_of_birth: "",
    blood_group: "",
    blood_donation: "",
    ex_ServiceMan: "",
    in_Service: "", //new field
    contact: "",
    whatsapp: "",
    address: "",
    district: "",
    post: "",
    pin: "",
    date_of_marriage: "",
    area: "",
    occupation: "",
    files: "",
    verificationtype: "",
    docfile: "",
    action: "",
    subscribed: "",
  });

  const [inputForm, setInputForm] = useState({
    name: props.obj.name ? props.obj.name : null,
    ex_ServiceMan: props.obj.ex_ServiceMan,
    in_Service: props.obj.in_Service, // new filed
    date_of_birth: props.obj.date_of_birth ? props.obj.date_of_birth : null,
    blood_group: props.obj.blood_group ? props.obj.blood_group : null,
    contact: props.obj.contact ? props.obj.contact : null,
    whatsapp: props.obj.whatsapp ? props.obj.whatsapp : null,
    address: props.obj.address ? props.obj.address : null,
    post: props.obj.post ? props.obj.post : null,
    pin: props.obj.pin ? props.obj.pin : null,
    district: props.obj.district ? props.obj.district : null,
    blood_donation: props.obj.blood_donation ? props.obj.blood_donation : null,
    lastname: props.obj.lastname ? props.obj.lastname : null,
    email: props.obj.email ? props.obj.email : null,
    hash: props.obj.hash ? props.obj.hash : null,
    subscribed: props.obj.subscribed,
    area: props.obj.area,
    date_of_marriage: props.obj.date_of_marriage,
    occupation: props.obj.occupation,
    verificationtype: props.obj.verificationtype
      ? props.obj.verificationtype
      : null,
    docFile: props.obj.docFile ? props.obj.docFile.filename : null,
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
  });
  let UserStatus = "";
  if (edit) {
    UserStatus = props ? props.obj.account_refId.action : "";
  }

  const submitForm = () => {
    var textformat = /^[a-zA-Z ]+$/;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const values = { ...inputForm };
    values["family"] = fields;
    const file = upload_Ref.current.getSelected();
    const docfile = doc_upload_Ref.current.getSelected();
    values["docfile"] = docfile;
    values["file"] = file;

    const obj = { ...status };
    obj.id = props.obj._id;

    // **********************Validation************************
    if (!values.name || !values.name.match(textformat)) {
      return setValidation({ name: "Please Check Your Name" });
    } else if (!values.lastname || !values.lastname.match(textformat)) {
      return setValidation({ lastname: "Please Check Last name" });
    } else if (!values.contact || values.contact.length !== 10) {
      return setValidation({ contact: "Please Check Your PhoneNumber" });
    } else if (!values.whatsapp || values.whatsapp.length !== 10) {
      return setValidation({ whatsapp: "Please Check Whatsapp Number" });
    } else if (!values.email || !values.email.match(mailformat)) {
      return setValidation({ email: "Please Check Your Email" });
    } else if (!values.hash || values.hash.length < 9) {
      return setValidation({ hash: "Please Check Password" });
    } else if (!values.date_of_birth) {
      return setValidation({ date_of_birth: "Please Check Date of Birth" });
    } else if (!values.date_of_marriage) {
      return setValidation({
        date_of_marriage: "Please Check Date of Marriage",
      });
    } else if (!values.blood_group || values.blood_group == "none") {
      return setValidation({ blood_group: "Please Select bloodGroup" });
    } else if (!values.ex_ServiceMan || values.ex_ServiceMan == "none") {
      return setValidation({ ex_ServiceMan: "Please Select ex-serviceman?" });
    } else if (values.ex_ServiceMan == "yes" && !values.occupation) {
      return setValidation({ occupation: "Please Give your occupation" });
    } else if (!values.in_Service || values.in_Service == "none") {
      return setValidation({
        in_Service: "Please Select Currently on service?",
      });
    } else if (!values.blood_donation || values.blood_donation == "none") {
      return setValidation({ blood_donation: "Willing for blood donation ?" });
    } else if (!values.address || !values.address.match(textformat)) {
      return setValidation({ address: "Please Check House Name" });
    } else if (!values.area) {
      return setValidation({ area: "Please Check Area Name" });
    } else if (!values.district || values.district == "none") {
      return setValidation({ district: "Please Select District" });
    } else if (!values.post || !values.post.match(textformat)) {
      return setValidation({ post: "Please Check Post" });
    } else if (!values.pin || values.pin.length !== 6) {
      return setValidation({ pin: "Please Check Pin Number" });
    } else if (
      upload_Ref.current.getSelected() == null ||
      upload_Ref.current.getSelected() == undefined
    ) {
      return setValidation({ files: "Please Insert Profile Picture" });
    } else if (!values.verificationtype || values.verificationtype == "none") {
      return setValidation({
        verificationtype: "Please Select verification Document",
      });
    } else if (
      doc_upload_Ref.current.getSelected() == null ||
      doc_upload_Ref.current.getSelected() == undefined
    ) {
      return setValidation({
        docfile: "Please insert a Verification Document",
      });
    }
    // else if (!values.subscribed || values.subscribed =="none"){
    // return setValidation({subscribed:"please select Subscribed or not"})
    // }
    // else if (!status.action || status.action=="none" ){
    // return setValidation({action:"please Select User Status"})
    // }

    // 	if(props.edit) {
    // 	  return updateStatus({ variables: { ...obj } }).then(res=>{
    // 	   updateProfile({ variables: { ...values } })
    // 		setIsValid('Status Updated Successfully')
    // 	})
    //   }
    // if (!props.edit) {
    values.blood_donation = Boolean(values.blood_donation);
    values.subscribed = Boolean(values.subscribed);
    values.ex_ServiceMan = Boolean(values.ex_ServiceMan);
    createProfile({ variables: { ...values } }).then((res) => {
      setIsValid("Created Successfully");
      props.refetch();
    });
    // }
    timeOutDelay();

    setTimeout(() => {
      setIsValid("");
    }, 1500);
  };

  const updateSubmit = () => {
    const obj = { ...status };
    obj.id = props.obj.account_refId._id;
    obj.action = userstatus;
    const values = { ...inputForm };
    values._id = props.obj._id;
    // if (!values.subscribed || values.subscribed =="none"){
    // 		return setValidation({subscribed:"please select Subscribed or not"})
    // 	}
    // else if (!status.action || status.action=="none" ){
    // 	return setValidation({action:"please Select User Status"})
    // }
    values.subscribed = Boolean(values.subscribed)
      // updateStatus({ variables: { ...obj } }).then(res=>{
      // updateProfile({ variables: { ...values } })
      // })

      .then((res) => {
        setIsValid("updated Successfully");
        props.refetch();
      });
    timeOutDelay();
    setTimeout(() => {
      setIsValid("");
    }, 1500);
  };

  const timeOutDelay = () => {
    setTimeout(() => {
      props.handleClose();
    }, 2000);
  };
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setInputForm(values);
  };
  let StatusChange = (event) => {
    const obj = { ...status };
    obj[event.target.name] = event.target.value;
    setUserStatus(event.target.value);
    setStatus(obj);
  };

  function handleAdd() {
    const values = [...fields];
    values.push({ name: null, value: null });
    setFields(values);
  }
  function handleChange(i, event) {
    const values = [...fields];
    values[i].name = event.target.value;
    setFields(values);
  }

  function handleChangeSelect(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }
  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  return (
    <div id="profile-view">
      {inputForm.files ? (
        <div className="col-lg-12 profile">
          <div class="user-avatar">
            <Img src={inputForm.files} />
          </div>
        </div>
      ) : null}
      <Form.Group className="mt-3" controlId="formBasicEmail">
        <Form.Label>
          Name<span className="mandatory">*</span> (Text and Space are allowed)
        </Form.Label>
        <Form.Control
          disabled={edit}
          type="text"
          placeholder="Enter Name"
          name="name"
          value={inputForm.name}
          onChange={(e) => formChange(e)}
        />
        {validation.name ? (
          <span className="mandatory">{validation.name}</span>
        ) : null}
      </Form.Group>
      <Form.Group className="mt-3" controlId="formBasicEmail">
        <Form.Label>
          Last Name<span className="mandatory">*</span> (Text and Space are
          allowed)
        </Form.Label>
        <Form.Control
          disabled={edit}
          type="text"
          placeholder="Enter Name"
          name="lastname"
          value={inputForm.lastname}
          onChange={(e) => formChange(e)}
        />
        {validation.lastname ? (
          <span className="mandatory">{validation.lastname}</span>
        ) : null}
      </Form.Group>
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Contact Number<span className="mandatory">*</span> (10 Digit Number)
        </Form.Label>
        <Form.Control
          disabled={edit}
          type="number"
          maxLength="10"
          placeholder="Contact Number"
          name="contact"
          value={inputForm.contact}
          onChange={(e) => formChange(e)}
        />
        {validation.contact ? (
          <span className="mandatory">{validation.contact}</span>
        ) : null}
      </Form.Group>
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Whatsapp Number<span className="mandatory">*</span> (10 Digit Number)
        </Form.Label>
        <Form.Control
          disabled={edit}
          type="number"
          maxLength="10"
          placeholder="Whatsapp Number"
          name="whatsapp"
          value={inputForm.whatsapp}
          onChange={(e) => formChange(e)}
        />
        {validation.whatsapp ? (
          <span className="mandatory">{validation.whatsapp}</span>
        ) : null}
      </Form.Group>
      {/* <Form.Group className="mt-3" controlId="formBasicPassword">
            <Form.Label>Email Id<span className='mandatory'>*</span> </Form.Label>
            <Form.Control disabled={edit}  type="email" placeholder="Enter email" name="email" value={inputForm.email} onChange={(e) => formChange(e)} />
			{validation.email?<span className='mandatory'>{validation.email}</span>:null}
          </Form.Group> */}
      {!props.edit ? (
        <Form.Group className="mt-3" controlId="formBasicEmail">
          <Form.Label>
            Password <span className="mandatory">*</span>(Must be more than 8
            digit)
          </Form.Label>
          <Form.Control
            type="password"
            minlength="8"
            placeholder="Enter password"
            name="hash"
            onChange={(e) => formChange(e)}
          />
          {validation.hash ? (
            <span className="mandatory">{validation.hash}</span>
          ) : null}
        </Form.Group>
      ) : null}
      <Row className="mt-3">
        <Form.Group
          disabled={edit}
          as={Col}
          className=""
          controlId="formBasicPassword"
        >
          <Form.Label>
            Date of Birth<span className="mandatory">*</span>{" "}
          </Form.Label>
          <Form.Control
            disabled={edit}
            type="date"
            placeholder="Date of Birth"
            name="date_of_birth"
            value={inputForm.date_of_birth}
            onChange={(e) => formChange(e)}
          />
        </Form.Group>

        {/* ******************Date of Marriage****************** */}

        <Form.Group
          disabled={edit}
          as={Col}
          className=""
          controlId="formBasicPassword"
        >
          <Form.Label>
            Date of Marriage<span className="mandatory">*</span>{" "}
          </Form.Label>
          <Form.Control
            disabled={edit}
            type="date"
            placeholder="Date of Marriage"
            name="date_of_marriage"
            value={inputForm.date_of_marriage}
            onChange={(e) => formChange(e)}
          />
        </Form.Group>
        {validation.date_of_birth ? (
          <span className="mandatory">{validation.date_of_birth}</span>
        ) : null}
        {validation.date_of_marriage ? (
          <span className="mandatory">{validation.date_of_marriage}</span>
        ) : null}
      </Row>
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Blood Group<span className="mandatory">*</span>
        </Form.Label>
        <Form.Select
          disabled={edit}
          value={inputForm.blood_group}
          name="blood_group"
          onChange={(e) => formChange(e)}
        >
          <option value="none" selected>
            Select Blood Group
          </option>
          <option value="A +ve">A +ve</option>
          <option value="A -ve">A -ve</option>
          <option value="B +ve">B +ve</option>
          <option value="B -ve">B -ve</option>
          <option value="AB +ve">AB +ve</option>
          <option value="AB -ve">AB -ve</option>
          <option value="O +ve">O +ve</option>
          <option value="O -ve">O -ve</option>
        </Form.Select>
        {validation.blood_group ? (
          <span className="mandatory">{validation.blood_group}</span>
        ) : null}
      </Form.Group>
      <Row className="mt-3">
        <Form.Group as={Col} className="" controlId="formBasicPassword">
          <Form.Label>
            Are You an Ex-ServiceMan ?<span className="mandatory">*</span>{" "}
          </Form.Label>
          <Form.Select
            disabled={edit}
            value={inputForm.ex_ServiceMan}
            name="ex_ServiceMan"
            onChange={(e) => formChange(e)}
          >
            <option selected value="none">
              Select
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="" controlId="formBasicPassword">
          <Form.Label>
            Blood Donation?<span className="mandatory">*</span>{" "}
          </Form.Label>
          <Form.Select
            disabled={edit}
            value={inputForm.blood_donation}
            name="blood_donation"
            onChange={(e) => formChange(e)}
          >
            <option selected value="none">
              Select
            </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Select>
        </Form.Group>
        {validation.ex_ServiceMan ? (
          <span className="mandatory">{validation.ex_ServiceMan}</span>
        ) : null}
        {validation.blood_donation ? (
          <span className="mandatory">{validation.blood_donation}</span>
        ) : null}
      </Row>
      {/* *********************Occupation*********************/}
      {inputForm.ex_ServiceMan == "yes" ? (
        <Form.Group as={Col} className="mt-3" controlId="formBasicPassword">
          <Form.Label>
            Occupation <span className="mandatory">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            disabled={edit}
            placeholder="Current Occupation"
            value={inputForm.occupation}
            name="occupation"
            onChange={(e) => formChange(e)}
          />
          {validation.occupation ? (
            <span className="mandatory">{validation.occupation}</span>
          ) : null}
        </Form.Group>
      ) : null}
      {/* *********************in service?*********************/}
      <Form.Group as={Col} className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Are You In Service ?<span className="mandatory">*</span>{" "}
        </Form.Label>
        <Form.Select
          disabled={edit}
          value={inputForm.in_Service}
          name="in_Service"
          onChange={(e) => formChange(e)}
        >
          <option selected value="none">
            Select
          </option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </Form.Select>
        {validation.in_Service ? (
          <span className="mandatory">{validation.in_Service}</span>
        ) : null}
      </Form.Group>
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Address<span className="mandatory">*</span> (Text and Space are
          allowed)
        </Form.Label>
        <Form.Control
          disabled={edit}
          type="text"
          placeholder="House Name"
          name="address"
          value={inputForm.address}
          onChange={(e) => formChange(e)}
        />
        {validation.address ? (
          <span className="mandatory">{validation.address}</span>
        ) : null}
      </Form.Group>
      {/* ***************area*************** */}
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Area<span className="mandatory">*</span> (Text and Space are allowed)
        </Form.Label>
        <Form.Control
          disabled={edit}
          type="text"
          placeholder="Area"
          name="area"
          value={inputForm.area}
          onChange={(e) => formChange(e)}
        />
        {validation.area ? (
          <span className="mandatory">{validation.area}</span>
        ) : null}
      </Form.Group>
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          District<span className="mandatory">*</span>{" "}
        </Form.Label>
        <Form.Select
          disabled={edit}
          value={inputForm.district}
          name="district"
          onChange={(e) => formChange(e)}
        >
          <option selected value="none">
            Select
          </option>
          <option value="calicut">Calicut</option>
        </Form.Select>
        {validation.district ? (
          <span className="mandatory">{validation.district}</span>
        ) : null}
      </Form.Group>
      <Row className="mt-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>
            Post<span className="mandatory">*</span> (Only Text and Space)
          </Form.Label>
          <Form.Control
            disabled={edit}
            placeholder="Post"
            name="post"
            value={inputForm.post}
            onChange={(e) => formChange(e)}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>
            Pin<span className="mandatory">*</span>(Six digit){" "}
          </Form.Label>
          <Form.Control
            disabled={edit}
            type="number"
            placeholder="Pin"
            name="pin"
            value={inputForm.pin}
            onChange={(e) => formChange(e)}
          />
        </Form.Group>
        {validation.post ? (
          <span className="mandatory">{validation.post}</span>
        ) : null}
        {validation.pin ? (
          <span className="mandatory">{validation.pin}</span>
        ) : null}
      </Row>

      {/*****************Profile Picture Selection********************/}
      {!props.edit ? (
        <Form.Group className="mt-3" controlId="file">
          <Form.Label>
            Profile Picture<span className="mandatory">*</span>{" "}
          </Form.Label>
          <ImageUpload disabled={edit} ref={upload_Ref} />
          {validation.files ? (
            <span className="mandatory">{validation.files}</span>
          ) : null}
        </Form.Group>
      ) : null}

      {/********************Document verification*********************/}
      <Form.Group className="mt-3" controlId="formGridCity">
        <Form.Label>
          Verification Document Details<span className="mandatory">*</span>
        </Form.Label>
        <Form.Select
          disabled={edit}
          value={inputForm.verificationtype}
          defaultValue="Choose..."
          className=""
          name="verificationtype"
          onChange={(e) => formChange(e)}
        >
          <option value="none" selected>
            Select Document
          </option>
          <option value="Passport">Passport </option>
          <option value="Aadhaar">Aadhaar</option>
          <option value="Driving Licence">Driving Licence</option>
          <option value="Bank Passbook">Bank Passbook</option>
        </Form.Select>
        {validation.verificationtype ? (
          <span className="mandatory">{validation.verificationtype}</span>
        ) : null}
      </Form.Group>
      {inputForm.docFile ? (
        <Form.Group className="" controlId="file">
          <div className="image-prv">
            <Img src={inputForm.docFile} />
          </div>
        </Form.Group>
      ) : null}
      {!props.edit ? (
        <Form.Group className="" controlId="file">
          <ImageUpload ref={doc_upload_Ref} />
          {validation.docfile ? (
            <span className="mandatory">{validation.docfile}</span>
          ) : null}
        </Form.Group>
      ) : null}
      {/********************Subscription*********************/}
      <Form.Group className="mt-3" controlId="formBasicPassword">
        <Form.Label>
          Are you Subscribed?<span className="mandatory">*</span>{" "}
        </Form.Label>
        <Form.Select
          value={inputForm.subscribed}
          name="subscribed"
          onChange={(e) => formChange(e)}
        >
          <option value="none" selected>
            Select
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Form.Select>
        {validation.subscribed ? (
          <span className="mandatory">{validation.subscribed}</span>
        ) : null}
      </Form.Group>

      {/********************Add Family Members*********************/}
      {!props.edit ? (
        <div className="mt-3">
          <Form.Label>Add Family Members</Form.Label>
          <i onClick={() => handleAdd()} class="fas fa-plus-circle"></i>
          {fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Row className="col">
                  <Form.Group
                    as={Col}
                    onChange={(e) => handleChange(idx, e)}
                    controlId="formGridCity"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Relation</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      onChange={(e) => handleChangeSelect(idx, e)}
                    >
                      <option value="none" selected>
                        Select Relation
                      </option>
                      <option value="Wife">Wife</option>
                      <option value="Husband">Husband</option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Son">Son</option>
                      <option value="Brother">Daughter</option>
                      <option value="Sister">Daughter</option>
                      <option value="Daughter">Daughter</option>
                      <option value="Grand Mother">Grand Mother</option>
                      <option value="Grand Father">Grand Father</option>
                    </Form.Select>
                    <i
                      onClick={() => handleRemove(idx)}
                      class="fas fa-trash-alt"
                    ></i>
                  </Form.Group>
                </Row>
              </div>
            );
          })}
        </div>
      ) : null}
      {/********************User Status Change*********************/}
      <h6 class="mt-3 text-success">Account Status</h6>
      <Form.Group className="mt-3" controlId="formGridCity">
        <Form.Label>
          Status<span className="mandatory">*</span>
        </Form.Label>
        <Form.Select
          className="mt-3 mb-3"
          name="action"
          value={userstatus}
          onChange={(e) => StatusChange(e)}
        >
          <option value="none" selected>
            Select Status
          </option>
          <option value="Active">Active</option>
          <option value="Rejected">Rejected</option>
          <option value="Blocked">Blocked</option>
          {/* <option value="Waiting_for_admin" >Waiting For Admin</option> */}
          <option value="email_verification">Email Verification</option>
        </Form.Select>
        {validation.action ? (
          <span className="mandatory">{validation.action}</span>
        ) : null}
      </Form.Group>
      {!props.edit ? (
        <Button
          type="button"
          id="submit"
          name="submit"
          class="btn btn-primary "
          onClick={(e) => submitForm()}
        >
          Save
        </Button>
      ) : null}
      {props.edit ? (
        <Button
          type="button"
          id="submit"
          name="submit"
          class="btn btn-primary "
          onClick={(e) => updateSubmit()}
        >
          Update
        </Button>
      ) : null}
      {isValid ? (
        <Alert className="mt-3" variant="success">
          {isValid}
        </Alert>
      ) : (
        ""
      )}
    </div>
  );
});

export default View;

import React, { forwardRef, useState, Fragment, useEffect, Suspense, lazy, useRef, useImperativeHandle } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import fakeAuth from '../../fakeAuth';
import { useSelector, useDispatch } from 'react-redux'
import { USER_ACTIVE, USER_ROLE } from '../../redux/action'
const CHANGE_PASS_MUTATION = gql`
  mutation changePassword(
    $token: String
    $hash: String
    ) {
      changePassword(obj:{
        token:$token,
        hash:$hash

      }) {
        status
    }
  }
`;

function ChangePassword() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { id } = useParams();
  const [querys, { loading, error }] = useMutation(CHANGE_PASS_MUTATION);
  const [isValid, setIsValid] = useState(false);
  const [inputForm, setForm] = useState({
    password1: null,
    password2: null,
  })
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values)

  }
  const submitForm = () => {
    const values = { ...inputForm };

    if (!values.password1 || !values.password2) {
      return (setIsValid(true));
    }
    else {
      setIsValid(false);
    }


    if (values.password1 !== values.password2) {
      return (setIsValid(true));
    }
    else {
      setIsValid(false);
    }


    querys({ variables: { hash: values.password1, token: localStorage.getItem('AUTH_TOKEN') } }).then(res => {
      alert('password is  Updated')
      fakeAuth.signout(false);
      dispatch({ type: USER_ACTIVE, payload: false })
      dispatch({ type: USER_ROLE, payload: null })
      localStorage.clear();
      history('/home');
    })

  }
  return (
    <div id="forget">
      {isValid ? <Alert variant="danger">Please Check Password</Alert> : ""}

      <div className="change-password">
        <h4> change password</h4>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>New Passoword</Form.Label>
          <Form.Control type="password" placeholder="Enter New Passoword" name="password1" onChange={(e) => formChange(e)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>New Passoword</Form.Label>
          <Form.Control type="password" placeholder="Enter New Password" name="password2" onChange={(e) => formChange(e)} />
        </Form.Group>
        <div class="ctdc-btn">
          <Button className="submit-btn" type="submit" onClick={(e) => submitForm()}>
            Submit
          </Button>
        </div>
      </div>
    </div>

  )
}

export default ChangePassword
import React, { forwardRef, useRef, useState } from 'react';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import './style.scss'

const ROLE_MUTATION = gql`
mutation assiginRole(
    $userid: String!
    $roleid: String!

    ){
      assiginRole(obj:{
        userid: $userid
        roleid:$roleid
        }){
            status
        }
    }  
`;
// change query



const ROLE_DELETE_MUTATION = gql`
mutation assiginRoleDelete(
    $userid: String!
    $roleid: String!

    ){
      assiginRoleDelete(obj:{
        userid: $userid
        roleid:$roleid
        }){
            status
        }
    }  
`;
const ROLE_QUERY = gql`
  {
    rolelist{
      name
      _id
    }
  }
`;
const SetRole = ((props) => {

  const [userList, setUser] = useState([]);
  const [role, setRole] = useState([]);

//   const { data, loading, error, refetch } = useQuery(FILMS_QUERY, {
//     onCompleted: (data) => {
// if(data){
//       setUser(data.profile_list)
//     }
//     }
//   });
  const { data: userrole, refetch: refetching } = useQuery(ROLE_QUERY, {
    onCompleted: (data) => {
      if(data){
      setRole(data.rolelist)
      }
    }
  });
  
  const [isValid, setIsValid] = useState('');
  const [inputForm, setInputForm] = useState({
    //name
    //role
  })
  const [createRole] = useMutation(ROLE_MUTATION);
  const [deleteRole] = useMutation(ROLE_DELETE_MUTATION);
  
  const submitForm = () => {
    
    const values = { ...inputForm };

values['userid']=props.obj.account_refId._id;
    createRole({ variables: { ...values } }).then(res => {
      setIsValid('Created Successfully');
      props.refetch()
    })
    timeOutDelay()

    setTimeout(() => {
      setIsValid('')
    }, 1500)

  }
  const timeOutDelay = () => {
    setTimeout(() => {
      props.handleClose()
    }, 2000);
  }
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setInputForm(values)

  }
  function handleRemove(obj){

    if(obj._id!='6221a523ae45dbee791a93f3'){
      let values={}
      values['userid']=props.obj.account_refId._id;
      values ['roleid']=obj._id
  deleteRole({ variables: { ...values } }).then(res => {
    setIsValid('Role is removed Successfully');
  })
    }
    else{
      alert('admin we cant delete Gust user Role')
    }


  }
  return (
    <>
    <div>
      {/* <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Users List<span className='mandatory'>*</span></Form.Label>
        <Form.Select name="userid" onChange={(e) => formChange(e)} >
          <option value="none" selected>Select User</option>
          {

            userList.map((item) => {
              return (<option value={item.account_refId._id}>{item.userId}-{item.name}</option>)

            })

          }
        </Form.Select>
      </Form.Group> */}
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Role<span className='mandatory'>*</span></Form.Label>
        <Form.Select name="roleid" onChange={(e) => formChange(e)} >
          <option value="none"  selected>Select Role</option>
          {role.map((item) => { return (<option value={item._id}>{item.name}</option>) })}
        </Form.Select>
      </Form.Group>

      <Button type="button" id="submit" name="submit" class="btn btn-primary" onClick={(e) => submitForm()}>Save</Button>

      {isValid ? <Alert className="mt-3" variant="success">{isValid}</Alert> : ""}
    </div>
    <h6> Active Role</h6>
    <ul className='active_roles'> 
      {
       props.obj? props.obj.account_refId.role.map((key,value)=>{
 return (<li>{key.name} <i onClick={() => handleRemove(key)} class="fas fa-trash-alt"></i></li>)
        }):''
      }
      
    </ul>
    </>
  )
}
)

export default SetRole
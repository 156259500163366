import React from 'react'; 
import ReactDOM from 'react-dom'; 
import banner from '../assets/img/banner.png'

const Advt=()=>
{
  return ( <div class="adbanner">
   sdfsdf  <img src={banner} alt="CALICUT DEFENCE" />sdfds
 
   </div>)
}
  
export default Advt;
import React from "react";
import { Link } from "react-router-dom";

import { DateSpan, Img } from "../../shared";

function Activity_lists({ title, _id, body, assetManagement, createdAt }) {
  return (
    <div className="col-12 col-sm-12 col-md-4">
      <div className="activity-row-items-box">
        <Link className="nav-link" to={"activity_details/" + _id}>
          <Img
            src={assetManagement ? assetManagement.filename : ""}
            width={580}
            height={800}
          />
          {/* <h5>{title} </h5> */}
        </Link>
        {/* <p>
                    {body}
                </p>
                <div className='footer-area'>
                    <div className='footer-area'>
                        <span className='created-by'> <DateSpan dat={createdAt} type='MMMM Do YYYY' /> </span>
                        <span className='more'>  <Link className="nav-link" to={"news_details/" + _id}> More </Link></span>
                    </div>
                </div> */}
      </div>
    </div>
  );
}
export default Activity_lists;

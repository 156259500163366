
import React from 'react'
import { Link } from 'react-router-dom';
import { Advt, News_list, DateSpan, Img } from '../../shared';
import './style.scss';
import { useQuery, gql, useMutation } from "@apollo/client";
import moment from 'moment'

const EVENT_QUERY = gql`
  {
    event_list(obj:{page:0,skip:0}){
        title
        body
        start_date
        end_date
        _id
        assetManagement{
          filename
        }
    }
  }
`;
const ADS_QUERY = gql`
  {
    ads_list_byuser(obj:{page:10  skip:0,place:"event_left"}){
      title
      _id
      assetManagement{
        filename
      }
    
    }
  }
`;
// const NEWS_QUERY = gql`
//   {
//     news_list(obj:{page:0 skip:1}){
//       title
//       body
//       createdAt
//       _id
//       assetManagement{
//         filename
//       }
//     }
//   }
// `;
export default function Event() {
    const { data, loading, error, refetch } = useQuery(EVENT_QUERY);


    let list = data ? data.event_list.map((res, key) => <Event_list {...res} key={key} />) : 'null'
    const { data: ads } = useQuery(ADS_QUERY);
    let advt = [];
    if (ads) {
    
        if (ads.ads_list_byuser) {
            advt = ads.ads_list_byuser.map((item, key) => {
                return (
                    <Img src={item.assetManagement?item.assetManagement.filename:''} width={300} height={300} alt="CALICUT DEFENCE" key={key} />
                )
            })

        }
    }


    return (
        <div id='events'>
            <div className="container">
                <h1 >Events</h1>
                <div className="row">
                    <div className='col-12 col-sm-12 col-md-9 event-list'>
                        <div className="row">
                            {list}
                        </div>

                    </div>
                    <div className='col-0 col-sm-0 col-md-3 left-col'>
                      
                        {advt}

                    </div>




                </div>

            </div>

        </div >
    )
}



function Event_list({ title, _id, body, assetManagement, start_date }) {

    return (
        <div className="col-12 col-sm-12 col-md-12" >
            <div className='events-row-items-box'>
                < div className='row'>
                    <div className="col-3 col-sm-3 col-md-3" >
                        <Img src={assetManagement ? assetManagement.filename : ''} width={247} height={180} />

                    </div>
                    <div className="col-9 col-sm-9 col-md-9" >

                        <h5>{title} </h5>
                        {/* <p>
                  {body}
              </p> */}
                        <div className='footer-area'>
                            <div className='footer-area'>
                                <span className='created-by'> {moment(start_date).format(" DD-MM-YYYY")}  </span>

                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>

    )
}

import React, { forwardRef, useState, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { ImageUpload, Heading, InputTextPrompt, Img } from "../../../shared";
import { Button, Alert, Col, Form } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const GROUP_MUTATION = gql`
  mutation administration_create(
    $title: String!
    $role: String!
    $designation: String
    $file: String
  ) {
    administration_create(
      obj: {
        title: $title
        role: $role
        designation: $designation
        file: $file
      }
    ) {
      status
    }
  }
`;

const GROUP_UPDATE_MUTATION = gql`
  mutation administration_updateById(
    $title: String!
    $role: String!
    $designation: String
    $id: String!
    $file: String
  ) {
    administration_updateById(
      obj: {
        title: $title
        role: $role
        designation: $designation
        id: $id
        file: $file
      }
    ) {
      status
    }
  }
`;

const Add = forwardRef((props, ref) => {
  const [createGroup, { loading, error, refesh }] = useMutation(GROUP_MUTATION);
  const [updateGroup] = useMutation(GROUP_UPDATE_MUTATION);
  const [isValid, setIsValid] = useState(false);
  const [validation, setValidation] = useState({
    title: "",
    role: "",
    designation: "",
    files: "",
  });
  const upload_Ref = useRef();
  const [inputForm, setForm] = useState({
    title: props.obj.title ? props.obj.title : null,
    role: props.obj.role ? props.obj.role : null,
    designation: props.obj.designation ? props.obj.designation : null,
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
  });

  useEffect(() => {
    setSrc(
      props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.assetManagement.filename
        : ""
    );
  }, [props]);

  const submitForm = () => {
    // let error = validation;
    const file = "";

    const values = { ...inputForm };
    values["file"] = file;
    var textformat = /^[a-zA-Z ]+$/;
    if (!values.title || !values.title.match(textformat)) {
      return setValidation({ name: "Check Your Name" });
    } else if (!values.role || values.role == "none") {
      return setValidation({ role: "Select Your Group" });
    } else if (
      inputForm.role == "AdministrativeMembers" &&
      (inputForm.designation == "none" || !values.designation)
    ) {
      return setValidation({ designation: "Select Your Designation" });
    }

    if (props.editAble) {
      values["file"] = cropper.getCroppedCanvas().toDataURL();

      values.id = props.obj._id;
      updateGroup({ variables: { ...values } }).then((res) => {
        setIsValid("Updated Successfully");
        props.refetch();
        props.handleClose();
      });
    } else {
      if (cropper.getCroppedCanvas().toDataURL()) {
        values["file"] = cropper.getCroppedCanvas().toDataURL();
        createGroup({ variables: { ...values } }).then((res) => {
          setIsValid("Created Successfully");
          setForm({
            title: "",
            role: "none",
            designation: "none",
            files: null,
          });
          props.refetch();
          props.handleClose();
        });
      } else {
        return setIsValid("Select Your Photo");
      }
      //   props.refetch();
      //   setValidation({
      //     title: "",
      //     role: "",
      //     designation: "",
      //     files: "",
      //   });
      //   timeOutDelay();
      // }
      // setTimeout(() => {
      //   setIsValid(null);
      // }, 1500);
    }
  };

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  const [imageChange, setimageChange] = useState(0);
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
      setimageChange(1);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();

  return (
    <div id="donation_add">
      <div className="row">
        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Name <span className="mandatory">*</span>(Text and Space are
              allowed)
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Title"
              name="title"
              value={inputForm.title}
              onChange={(e) => formChange(e)}
            />
            {validation.name ? (
              <span className="mandatory">{validation.name}</span>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridState">
            <Form.Label>
              Group Type<span className="mandatory">*</span>
            </Form.Label>
            <Form.Select
              name="role"
              value={inputForm.role}
              onChange={(e) => formChange(e)}
            >
              <option value="none" selected>
                Select Group
              </option>
              <option value="AdministrativeMembers">
                Administrative Member
              </option>
              <option value="Councilmembers">Council Members</option>
              <option value="Trusteeboardmembers">Trustee Board Members</option>
            </Form.Select>
          </Form.Group>
          {validation.role ? (
            <span className="mandatory">{validation.role}</span>
          ) : null}
          {inputForm.role == "AdministrativeMembers" ? (
            <Form.Group className="mb-3" controlId="formGridState">
              <Form.Label>
                Designation<span className="mandatory">*</span>
              </Form.Label>
              <Form.Select
                name="designation"
                value={inputForm.designation}
                onChange={(e) => formChange(e)}
              >
                <option value="none" selected>
                  Select Designation
                </option>
                <option value="President">President</option>
                <option value="VicePresident">Vice President</option>
                <option value="Secretary">Secretary</option>
                <option value="JointSecretary">Joint Secretary</option>\
                <option value="Treasurer">Treasurer</option>
              </Form.Select>
            </Form.Group>
          ) : (
            (inputForm.designation = null)
          )}
          {validation.designation ? (
            <span className="mandatory mt-2">{validation.designation}</span>
          ) : null}

          <Form.Group className="mt-3 mb-1" controlId="file">
            <Form.Label>
              Profile Picture<span className="mandatory">*</span>
            </Form.Label>

            {/* {inputForm.files ? (
              <div className="image-prv">
                <Img src={inputForm.files} />
              </div>
            ) : null} */}
            <input type="file" onChange={onFileChange} />
            <Cropper
              style={{ height: 282, width: 450 }}
              aspectRatio={2 / 2}
              guides={false}
              src={srcCrop}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              viewMode={1}
              dragMode="move"
              cropBoxMovable={false}
            />
            {validation.files ? (
              <span className="mandatory">{validation.files}</span>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-lg-12">
          <Button varient="primary" onClick={(e) => submitForm()}>
            Save
          </Button>
          {isValid ? (
            <Alert className="mt-3" variant="success">
              {isValid}
            </Alert>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});

export default Add;

import React, { forwardRef, useState, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, Alert, Form } from "react-bootstrap";
import moment from "moment";
import { ImageUpload, Heading, InputTextPrompt, Img } from "../../../shared";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
const EVENT_MUTATION = gql`
  mutation event_create(
    $title: String!
    $body: String!
    $start_date: String!
    $end_date: String!
    $file: Upload
  ) {
    event_create(
      obj: {
        title: $title
        body: $body
        start_date: $start_date
        end_date: $end_date
        file: $file
      }
    ) {
      status
    }
  }
`;

const EVENT_BY_UPDATE_MUTATION = gql`
  mutation event_updateById(
    $id: String!
    $title: String!
    $body: String!
    $start_date: String!
    $end_date: String!
    $file: Upload
  ) {
    event_updateById(
      obj: {
        id: $id
        title: $title
        body: $body
        start_date: $start_date
        end_date: $end_date
        file: $file
      }
    ) {
      status
    }
  }
`;

const Add = forwardRef((props, ref) => {
  const [createEvent, { loading, error }] = useMutation(EVENT_MUTATION);
  const [UpdateEvent] = useMutation(EVENT_BY_UPDATE_MUTATION);
  const [isValid, setIsValid] = useState("");
  const upload_Ref = useRef();
  const [inputForm, setForm] = useState({
    title: props.obj.title ? props.obj.title : null,
    body: props.obj.body ? props.obj.body : null,
    start_date: moment(props.obj.start_date).format("YYYY-MM-DD"),
    end_date: moment(props.obj.end_date).format("YYYY-MM-DD"),
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
  });

  useEffect(() => {
    setSrc(
      props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.assetManagement.filename
        : ""
    );
  }, [props]);
  const submitForm = () => {
    const file = cropper.getCroppedCanvas().toDataURL();
    const values = { ...inputForm };
    values["file"] = file;
    values.body = "null";

    if (
      !values.title
      // !values.body ||
      // !values.start_date ||
      // !values.end_date
    ) {
      return setIsValid("Please Check All The Field");
    } else {
      setIsValid("");
    }

    if (props.editAble) {
      values.id = props.obj._id;
      UpdateEvent({ variables: { ...values } }).then((res) => {
        setIsValid("Updated Successfully");
        props.refetch();
      });
      timeOutDelay();
    } else {
      createEvent({ variables: { ...values } }).then((res) => {
        setIsValid("Created Successfully");
        props.refetch();
      });
      timeOutDelay();
    }

    setTimeout(() => {
      setIsValid(null);
    }, 2000);
  };
  const timeOutDelay = () => {
    setTimeout(() => {
      props.handleClose();
    }, 2000);
  };
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();

  return (
    <div id="event_add">
      <div className="row">
        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Title"
              value={inputForm.title}
              name="title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            {}
            <Form.Label>
              Start Date<span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              placeholder="Date"
              value={inputForm.start_date}
              name="start_date"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              End Date<span className="mandatory">*</span> {inputForm.end_date}
            </Form.Label>
            <Form.Control
              type="date"
              placeholder="Date"
              value={inputForm.end_date}
              name="end_date"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="file">
            <input type="file" onChange={onFileChange} />
            <Cropper
              style={{ height: 282, width: 450 }}
              aspectRatio={3.2 / 4}
              guides={false}
              src={srcCrop}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              viewMode={1}
              dragMode="move"
              cropBoxMovable={false}
            />
          </Form.Group>
        </div>
        <div className="col-lg-12">
          <Button varient="primary" onClick={(e) => submitForm()}>
            Save
          </Button>

          {isValid ? (
            <Alert className="mt-3" variant="success">
              {isValid}
            </Alert>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});

export default Add;

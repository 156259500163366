import React from 'react'

function FirstPage() {
  return (
    <div>
        <h5>There are numerous social and welfare communities functioning around us by means of politics, cast, religion, sports, arts etc to survive, share even for drugs, scam also. All these communities implies good and bad impacts in the society. In this scenario, introducing a community that is entirely different from others with most effective outcomes. It is a companionship of serving and retired soldiers of defence as well as Para Military forces of Kozhikode district who have pledged to give their lives to protect the freedom and integrity of India. The main objective of this community is to uphold the moral of defence personnel and provide support and shade to the socially and economically weaker public of Kozhikode district. It is called 'Calicut Defence Trust and Care'."Nation Service is our Duty...People Service is our Happiness" is our vision.

A soldier is always a soldier. He is the one who can deliver service to common public beyond cast, creed,colour, religion and politics with utter dedication and sacrifice. Since, last three years we are on ground to fullfill our vision through our missions.The trust is leaded by Mr.Anil TK as President, Mr. Gireesh the Vice President, Mr. Sareesh the Secretary, Mr. Shidhin as Jt Secretary and Mr. Sarin as treasurer. Moreover, it also includes approximately 1500 vibrant members to accomplish the task in a disciplined manner. It is a great honour to inform that the source of income for all the task is from the salary and pension of its members.</h5>
    </div>
  )
}

export default FirstPage
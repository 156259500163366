import React from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import Activity_lists from'./activity_list';

const ACTIVITIES_QUERY = gql`
  {
    activity_list(obj:{page:3  skip:0}){
      title
      body
      createdAt
      _id
      assetManagement{
        filename
      }
    }
  }
`;

export default function Activity() {
    const { data, loading, error,refetch } = useQuery(ACTIVITIES_QUERY);

  
    let list=data?data.activity_list.map((res,key)=><Activity_lists {...res} key={key} />):'null'
    
    return (
        <section className='event'>
            <div className="container">
                <h6> Our Recent Achievements</h6>
                <p> Let us also look at the missions undertaken by the calicut defense</p>.
{/* <p> More than 800 financially distressed families were provided with grocery kits and medicines for more than 150 patients during the lockdown.</p>
 <p>During Covid's early days, Kovid's health workers who were involved in the defense were reassured at Beach Hospital and their immunization kits were handed out.
 3 The next step was to provide prevention kits to the District Collector for the health sector in Kozhikode district, police personnel and other Kovid defense forces.
 4 The blood shortage at that time was resolved by organizing the Blood Donation Camp in honor of Sister LINI, who left us in Nipha, and is still donating blood to those in need.
 5 Donations were made to more than eighty terminally ill people, to the weddings of sisters from needy families, to the construction of houses, and to the families of those who had to commit suicide.
 6 On the second anniversary, the children in the orphanages were fed with joy and encouragement .
 7 Rs 2 lakhs collected from the lottery held in connection with Onam was handed over to help about 35 families
 8 We have been able to find the suffering soldiers in our country and provide them with all the assistance they need.
 9 On the day of Diwali / Children's Day, the orphanages in Kozhikode were again given food and food items and celebrated with them. He also set an example by providing learning materials to the children of the Kuttyadi Adivasi Colony
 10 A bike rally was organized to raise awareness about the spread of covid virus and shigella virus as people move away from covid immunization.
 11 In addition to this, Padam Namukku Padam Senior & Junior concert on Calicut Defense's social media platforms Defense Fans Club (DFC) and YouTube channel was successfully conducted by renowned music director Justin Kalidas as a member of the jury.

We are also proud to be able to continue with more organized programs and charity activities by the end of 2020 and 2021 ... Let's go into them with your consent ....
Even before the word jeevan was given to such a military community ... Retired from the army without being known to anyone ... Former ex-servicemen aged 26 and over on Republic Day and 'Independence Day' on the occasion of 'Snehasparsham' to pay their respects and receive their blessings on the ex-servicemen who have led a decent life and paved the way for us. , war widows, Gallantery Awards to honor Jeddah, people with disabilities, and Veer Norris and to donate as much aid as needed to the needy.
 Another feature of this community is that it has spent over Rs. 12 lakhs on charity work alone last year for our work.
All financial resources are contributions from the salaries and pensions of loving / trusted members of the military / paramilitary / veterans.Calicut defense is a commendable service to society that seeks to protect the borders of its own country but also to love and help its fellow man while at the same time thinking of the security of the people who live there .
</p> */}
                <div className="row event-row">
       
           {list}
                 
              

                </div>
            </div>
        </section>
    )
}

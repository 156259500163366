import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Img,
  Heading,
  AdminPanel,
  Widget,
  Search,
  Export,
} from "../../../shared";
import {
  Button,
  Tab,
  Tabs,
  Alert,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";

import Dropdown from "react-bootstrap/Dropdown";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import people from "../../../assets/img/people.png";
import DataTable from "react-data-table-component";
import "./style.scss";
import { useParams } from "react-router-dom";
import { assign } from "apollo-utilities";
import AsyncSelect from "react-select/async";
import { MultiSelect } from "react-multi-select-component";
import AclPermission from "../../../lib/acl";
import ManulPayment from '../../../shared/manulPayment'
import paisa from 'paisa.js';


const GROUP_QUERY = gql`
  query group_viewById($id: ID!) {
    group_viewById(id: $id) {
      _id
      title
      adminrefId {
        name
        userId
        _id
      }
      memberfId {
        name
        userId
        _id
      }
    }
  }
`;
const DELETE_MEMBER_MUTATION = gql`
  mutation group_deleteByUser($userId: String, $_id: String) {
    group_deleteByUser(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;

const DELETE_ADMIN_MUTATION = gql`
  mutation group_deleteByAdmin($userId: String, $_id: String) {
    group_deleteByAdmin(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;

const USERl_LIST_QUERY = gql`
  {
    profile_list {
      name
      lastname
      ex_ServiceMan
      in_Service
      date_of_birth
      blood_group
      blood_donation
      contact
      whatsapp
      address
      post
      group_refId {
        title
      }
      family {
        name
        relation
        blood_group
      }
      pin
      district
      email
      subscribed
      verificationtype
      _id
      account_refId {
        action
        _id
        role {
          _id
          name
        }
      }
      area
      date_of_marriage
      occupation
      subscribed
      userId

      docFile {
        filename
      }
      assetManagement {
        filename
      }
    }
  }
`;

const GROUP_ADMIN_MUTATION = gql`
  mutation groupAdmin_updateById($userId: [String], $_id: String) {
    groupAdmin_updateById(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;

const GROUP_MEMBER_MUTATION = gql`
  mutation groupMember_updateById($userId: [String], $_id: String) {
    groupMember_updateById(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;
export default function Groupnfo() {
  const { id } = useParams();
  const [groupadmin, setGroupadmin] = useState(false);
  const [groupMember, setgroupMember] = useState(false);
  const [inputAdminForm, setAdminForm] = useState({});
  const [options, setOptions] = useState([]);
  const [inputMemberForm, setMemberForm] = useState({});
  const [selected, setSelected] = useState([]);
  const [selecteds, setSeelected] = useState([]);
  const [loadEntries, { data: groups }] = useLazyQuery(GROUP_QUERY, {
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
  });
  const [deletememberQ] = useMutation(DELETE_MEMBER_MUTATION);
  const [deleteAdmin] = useMutation(DELETE_ADMIN_MUTATION);
  const [loadUserlist] = useLazyQuery(USERl_LIST_QUERY, {
    //fetchPolicy: "network-only", // Used for first execution
    // nextFetchPolicy: "cache-first", // Used for subsequent executions
    onCompleted: (data) => {
      let opctionList = [];
      if (data.profile_list.length > 0) {
        data.profile_list.map((items, key) => {
          if (items.group_refId && items.group_refId.length > 0) {
            let roles = "";
            items.group_refId.map((gro, key) => {
              let re = roles.concat(gro.title);
              roles = re;
            });
            opctionList.push({
              value: items._id,
              label: `${items.userId}-${items.name}-${roles}`,
            });
            //setOptions(opctionList)
            roles = "";
          } else {
            opctionList.push({
              value: items._id,
              label: `${items.userId}-${items.name}`,
            });
            //setOptions(opctionList)
          }
        });
      }
      setOptions(opctionList);
    },
  });
  const [assinAdmin] = useMutation(GROUP_ADMIN_MUTATION);
  const [assinMember] = useMutation(GROUP_MEMBER_MUTATION);
  const [manulPaymnet, setManulPaymnet] = useState(false);
  const [profilerefId, setProfilerefId] = useState(false);



  useEffect(() => {
    loadEntries({ variables: { id: id } });
    loadUserlist();
  }, []);

  function deleteMember(id, userId) {
    //
    deletememberQ({ variables: { userId: userId, _id: id } }).then((res) => {
      if (res.data.group_deleteByUser.status) {
        loadEntries({ variables: { id: id } });
      }
    });
  }

  function handledeleteAdmin(id, userId) {
    deleteAdmin({ variables: { userId: userId, _id: id } }).then((res) => {
      if (res.data.group_deleteByAdmin.status) {
        loadEntries({ variables: { id: id } });
      }
    });
  }
  function assignAdmin(id) {
    loadUserlist();
    setAdminForm({ _id: id });
    setGroupadmin(true);
  }

  const close = () => {
    setGroupadmin(false);
    setgroupMember(false);
  };
  function submitassignAdmin(event) {
    event.preventDefault();
    let ids = [];
    selected.forEach((element, key) => {
      ids.push(element.value);
    });

    assinAdmin({ variables: { userId: ids, _id: id } }).then((res) => {
      loadEntries({ variables: { id: id } });
      setgroupMember(false);
      setGroupadmin(false);
    });
  }

  function assignMember(id) {
    loadUserlist();
    setMemberForm({ _id: id });
    setgroupMember(true);
  }

  function submitassignMember(event) {
    event.preventDefault();
    let ids = [];
    selecteds.forEach((element, key) => {
      ids.push(element.value);
    });
    assinMember({ variables: { userId: ids, _id: id } }).then((res) => {
      loadEntries({ variables: { id: id } });
      setgroupMember(false);
      setGroupadmin(false);
    });
  }

  function cashPayment(row){
    setManulPaymnet(true);
     setProfilerefId(row._id);
  }



  let { title, adminrefId, memberfId } = groups ? groups.group_viewById : {};
  const columns = [
    {
      name: "userId",
      cell: (row) => <span>{row.userId}</span>,
      sortable: true,
    },
    {
      name: "name",
      cell: (row) => <span>{row.name}</span>,
      sortable: true,
    },

    {
      name: "payment",
      cell: (row) => <span><button type="button" class="add-btn btn btn-primary" onClick={() => cashPayment(row)}>
        Cash Paymnet
      </button></span>,
      sortable: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => deleteMember(id, row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const columnsAdmin = [
    {
      name: "userId",
      cell: (row) => <span>{row.userId}</span>,
      sortable: true,
    },
    {
      name: "name",
      cell: (row) => <span>{row.name}</span>,
      sortable: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handledeleteAdmin(id, row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const [inputForm, setForm] = useState({
    amount: 0.00,
    validity:0,
    type:null


  })
  let subscription = (month) => {

    let sub =100;
  
    setForm({
      amount: parseFloat((sub*month)*100) ,
      type:'monthly',
      validity:month
    })
  


  }
  let membership = () => {
    let sub =500;
    setForm({
      amount: parseFloat((sub)*100)  ,
      type:'membership',
      validity:0
    })
  }
  
  let Websitefee = () => {

    let sub =200;
    setForm({
      amount: parseFloat((sub)*100)  ,
      type:'website',
      validity:0
    })


  }
  
  

  return (
    <div className="groupinfo">
      <AclPermission
        isActive={["admin", "President", "VP, Sec, jt Sec, treasurer"]} showMessage={false}
      >
        <header>
          <h1>{title}</h1>
        </header>
        <div className="groupadmin">
          <button
            onClick={() => {
              assignAdmin(id);
            }}
            className=" btn btn-primary add_btn"
          >
            Add New Admin
          </button>
          <DataTable
            title={title + " Admins"}
            pagination
            highlightOnHover
            columns={columnsAdmin}
            data={adminrefId ? adminrefId : []}
          />
        </div>
        <br />

        <div className="member">
          <button
            className=" btn btn-primary add_btn"
            onClick={() => {
              assignMember(id);
            }}
          >
            Add Members
          </button>
          <DataTable
            title={title + " Members List"}
            pagination
            highlightOnHover
            columns={columns}
            data={memberfId ? memberfId : []}
          />
        </div>
        <Modal show={groupadmin} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title> {"Add " + title + " Admin"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
              closeOnChangedValue={false}
            />
            {/* <AsyncSelect cacheOptions loadOptions={options} isMulti={true} /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => submitassignAdmin(e)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={groupMember} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title>{"Add " + title + " Members List"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MultiSelect
              options={options}
              value={selecteds}
              onChange={setSeelected}
              labelledBy="Select"
              closeOnChangedValue={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => submitassignMember(e)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={manulPaymnet} onHide={() => setManulPaymnet(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Cash Paymnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="payment-tab">
              <Tabs
                defaultActiveKey="monthly"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="monthly" title="monthly subscription " >
                  <h2>Monthly Subscription    </h2>
                  <div className='Subscribe_list'>
                    <ul>
                      <li>
                        <span className='pakgName'>Basic  </span>
                        <span className='amount'> <i class="fas fa-rupee-sign"></i>100 <p>+Gst</p> </span>
                        <span className='validity'> 1 month</span>
                        <Form.Check type="radio" id="custom-switch1" value={1} name="amount" onChange={(e) => subscription(1)} />
                      </li>
                      <li>
                        <span className='pakgName'>Starter </span>
                        <span className='amount'> <i class="fas fa-rupee-sign"></i>300 <p>+Gst</p> </span>
                        <span className='validity'> 3 month</span>
                        <Form.Check type="radio" id="custom-switch1" value={3} name="amount" onChange={(e) => subscription(3)} />
                      </li>
                      <li>
                        <span className='pakgName'>Standard </span>
                        <span className='amount'> <i class="fas fa-rupee-sign"></i>600<p>+Gst</p> </span>
                        <span className='validity'> 6 month</span>
                        <Form.Check type="radio" id="custom-switch1" value={6} name="amount" onChange={(e) => subscription(6)} />
                      </li>

                      <li>
                        <span className='pakgName'>Premium </span>
                        <span className='amount'> <i class="fas fa-rupee-sign"></i>1200<p>+Gst</p> </span>
                        <span className='validity'> 12 month</span>
                        <Form.Check type="radio" id="custom-switch1" value={12} name="amount" onChange={(e) => subscription(12)} />
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="Membership" title="Member Ship">
                  <h2>Membership    </h2>
                  <div className='Subscribe_list'>
                    <ul>
                      <li>
                        <span className='pakgName'>Basic  </span>
                        <span className='amount'> <i class="fas fa-rupee-sign"></i>500 <p>+Gst</p> </span>
                        <span className='validity'> 1 year</span>
                        <Form.Check type="radio" id="custom-switch1" value={1} name="amount" onChange={(e) => membership()} />
                      </li>

                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="WebsiteFee" title="Website Fee" >
                  <h2>Website Fee    </h2>
                  <div className='Subscribe_list'>
                    <ul>
                      <li>
                        <span className='pakgName'>Basic  </span>
                        <span className='amount'> <i class="fas fa-rupee-sign"></i>200 <p>+Gst</p> </span>
                        <span className='validity'> 1 year</span>
                        <Form.Check type="radio" id="custom-switch1" value={1} name="amount" onChange={(e) => Websitefee()} />
                      </li>

                    </ul>
                  </div>
                </Tab>

                <Tab eventKey="WebsiteFee" title="Other" disabled >
                  Tab content for Contact
                </Tab>
              </Tabs>

              <div className="total">
                <span> Amount :  {paisa.formatWithSymbol(inputForm.amount, 2)}</span>
                <span> Gst : 0 %</span>
                <span> Total : {paisa.formatWithSymbol(inputForm.amount, 2)}</span>
              </div>
            </div>

            <ManulPayment amountt={inputForm.amount} profilerefId={profilerefId} group_id={id} validity={inputForm.validity} paymentMode={'cash'} type={inputForm.type} />
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={()=>setManulPaymnet(false)}>
              Close
            </Button>
         
          </Modal.Footer> */}
        </Modal>


      </AclPermission>
    </div>
  );
}

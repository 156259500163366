import React, {
  useNavigate,
  forwardRef,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
  useImperativeHandle,
} from "react";

import {
  Button,
  Tab,
  Tabs,
  Alert,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
const PAYMENT_MUTATION = gql`
  mutation createPayment($amount: Int) {
    createPayment(obj: { amount: $amount }) {
      amount
      amount_due
      amount_paid
      attempts

      currency
      entity
      id
      offer_id
      receipt
    }
  }
`;

const PAYMENT_SUCESS_MUTATION = gql`
  mutation manulpayment(
    $amount: Int
    $type: String
    $validity: String
    $profile_refId :String    
    $sessionId:String
    $paymentMode:String
    $groupID:String
  ) {
    manulpayment(
      obj: {
        amount: $amount
        validity: $validity
        type: $type
        profile_refId: $profile_refId
        sessionId:$sessionId
        paymentMode:$paymentMode
        groupID:$groupID
      }
    ) {
      status
    }
  }
`;

const ManulPayment = ({ type, amountt, validity,profilerefId,paymentMode,group_id }) => {
  const [reqQuery, { loading, error }] = useMutation(PAYMENT_MUTATION);
  const [reqsecuess] = useMutation(PAYMENT_SUCESS_MUTATION);

  // function loadScript(src) {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // }

let displayRazorpay=()=>{
  reqsecuess({
    variables: {
      amount:amountt,
      type: type ,
      validity:validity.toString(),
      paymentMode:paymentMode,
      profile_refId:profilerefId,
      sessionId:localStorage.getItem('userId'),
      groupID:group_id
    },
  }).then((res) => {
  alert('successfully')
  });


}


  return (
    <Button
      className="Razorpay-btn"
      variant="primary"
      onClick={displayRazorpay}
    >
     pay Amount 
    </Button>
  );
};

export default ManulPayment;

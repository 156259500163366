
import React from 'react'

export default function Groups() {
    return (
        <div>
            Groups
        </div>
    )
}

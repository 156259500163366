import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import DataTable from "react-data-table-component";
import {
  Img,
  Heading,
  AdminPanel,
  Widget,
  Search,
  Export,
} from "../../../shared";

import "./style.scss";
import moment from "moment";
import {
  Form,
  Row,
  Col,
  Spinner,
  Modal,
  Button,
  Alert,
  Dropdown,
  DropdownButton,
  Tabs,
  Tab,
} from "react-bootstrap";
import AclPermission from "../../../lib/acl";
import SetRole from "./SetRole";
import { data } from "jquery";
import FilterComponent from "./filter";

const FILMS_QUERY = gql`
  {
    profile_list {
      name
      lastname
      ex_ServiceMan
      in_Service
      date_of_birth
      blood_group
      blood_donation
      contact
      whatsapp
      address
      post
      family {
        name
        relation
        blood_group
      }
      pin
      district
      email
      subscribed
      verificationtype
      _id
      account_refId {
        action
        _id
        role {
          _id
          name
        }
      }
      area
      date_of_marriage
      occupation
      subscribed
      userId

      docFile {
        filename
      }
      assetManagement {
        filename
      }
    }
  }
`;

const ROLE_QUERY = gql`
  {
    rolelist {
      name
      _id
    }
  }
`;
const Acl = () => {
  const { data: userrole, refetch: refetching } = useQuery(ROLE_QUERY);
  const [editAble, setEditAble] = useState(false);
  const [shownew, setShowNew] = useState(false);
  const [show, setShow] = useState(false);
  const [showrole, setShowRole] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({});
  const [rolename, setRoleName] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [filater, setfilter] = useState([]);

  const { data, loading, error, refetch } = useQuery(FILMS_QUERY, {
    onCompleted: (data) => {
      if (data.profile_list.length >= 1) {
        setfilter(data ? data.profile_list : []);
      }
    },
  });
  let lists = data ? data.profile_list : [];

  let roleList = userrole ? userrole.rolelist : null;

  const rolefilter = (name) => {
    if (name == "all") {
      setfilter(data.profile_list);
      return;
    }
    lists = [];

    data.profile_list.forEach((element) => {
      element.account_refId.role.forEach((i) => {
        if (i._id == name) {
          console.log(element);
          lists.push(element);
        }
      });
    });
    setfilter(lists);
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );

  const filteredItems = filater.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText("");
    //   }
    // };

    return (
      <div className="table-toolbar">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          //onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: " ",
      cell: (row) => <i class="fa fa-ellipsis-v" aria-hidden="true"></i>,
      //sortable: true,
    },

    {
      name: "",
      width: 200,
      cell: (row) => (
        <Link
          className="userPf"
          to={"/admin/userProfile/" + row.account_refId._id}
        >
          <Img
            src={
              row.assetManagement != null ? row.assetManagement.filename : ""
            }
            alt="CALICUT DEFENCE"
          />
        </Link>
      ),
      //sortable: true,
    },
    {
      name: "Date",
      cell: (row) => <> {moment(row.createdAt).format("DD-MM-YYYY")}</>,
      sortable: true,
    },
    {
      name: "ID",
      //cell: (row) => row.userId,
      selector: "userId",
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      //sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <div className={"adminstatus " + row.account_refId.action}>
          {" "}
          {row.account_refId.action}
        </div>
      ),

      //sortable: true,
    },

    {
      name: "Role",
      selector: (row) => (
        <Button className={"add-btn me-2"} onClick={() => roleShow(row)}>
          Set Role
        </Button>
      ),
    },
  ];

  const handleEdit = (obj) => {
    setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };

  const handleShow = () => {
    setShowNew(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };
  const handleCreateClose = () => {
    setShowNew(false);
  };

  {
    /* ***********Role*************** */
  }

  let list = userrole
    ? userrole.rolelist.map((res, key) => (
        <Button
          style={{ marginRight: "10px" }}
          onClick={(e) => {
            rolefilter(res._id);
          }}
        >
          {res.name}
        </Button>
      ))
    : null;
  const roleShow = (obj) => {
    setobj({ ...obj });
    setRoleName({
      name: null,
    });
    setShowRole(true);
    setEditAble(false);
  };
  const roleClose = () => {
    setShowRole(false);
    setEditAble(false);
  };
  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return `Error! ${error.message}`;
  return (
    <div id="users-list">
      <AclPermission isActive={["admin", "President"]} showMessage={false}>
        <div className="tbl">
          {/* *****************Role AdminPanel********************* */}
          <AdminPanel
            show={showrole}
            handleClose={(e) => {
              roleClose();
            }}
            title="User Details"
          >
            <SetRole
              obj={obj}
              rol={roleList}
              refetch={(e) => {
                refetch();
              }}
              handleClose={(e) => {
                roleClose();
              }}
            />
          </AdminPanel>
          {/* ************************** */}

          <div className="permission">
            <Tabs defaultActiveKey="Users">
              <Tab eventKey="Users" title="Users">
                <div className="users-list-table">
                  <Heading title="Users List" />
                  <div className="user-menus">
                    <Button
                      onClick={(e) => {
                        rolefilter("all");
                      }}
                      style={{ marginRight: "10px" }}
                    >
                      All
                    </Button>{" "}
                    {list}
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortField="name"
                    striped
                    pagination
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </AclPermission>
    </div>
  );
};

export default Acl;

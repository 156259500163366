import React, { forwardRef, useState, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { ImageUpload, Heading, InputTextPrompt, Img } from "../../../shared";
import { Button, Alert, Col, Form } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ADS_MUTATION = gql`
  mutation ads_create($title: String!, $place: String!, $file: String) {
    ads_create(obj: { title: $title, file: $file, place: $place }) {
      status
    }
  }
`;

const ADS_BY_UPDATE_MUTATION = gql`
  mutation ads_updateById(
    $title: String
    $file: String
    $place: String
    $_id: String
  ) {
    ads_updateById(
      obj: { title: $title, place: $place, file: $file, _id: $_id }
    ) {
      status
    }
  }
`;

const Add = forwardRef((props, ref) => {
  const [createads, { loading, error }] = useMutation(ADS_MUTATION);
  const [Updateads] = useMutation(ADS_BY_UPDATE_MUTATION);

  const [isValid, setIsValid] = useState("");
  const upload_Ref = useRef();

  const [inputForm, setForm] = useState({});

  const submitForm = () => {
    // const file = upload_Ref.current.getSelected();
    const values = { ...inputForm };

    values["file"] = cropper.getCroppedCanvas().toDataURL();

    if (values.file && values.place && values.title) {
      createads({ variables: { ...values } }).then((res) => {
        setIsValid("Created Successfully");
        props.refetch();
      });
    } else {
      alert("please fill all the field");
    }
  };

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();

  return (
    <div id="donation_add">
      <div className="row">
        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.title}
              placeholder="Enter Title"
              name="title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formGridState">
            <Form.Label>
              ad place <span className="mandatory">*</span>
            </Form.Label>
            <Form.Select name="place" onChange={(e) => formChange(e)}>
              <option value="none" selected>
                Select Ad place
              </option>
              <option value="home_top">Home page top</option>
              <option value="event_left">Event left</option>
              <option value="news_left">News left</option>
              <option value="news_detals">News Detals</option>
              <option value="activity_left">Activity left</option>
              <option value="activity_detals">Activity Detals</option>
            </Form.Select>
          </Form.Group>
        </div>
        <div class="col-sm-12">
          <Form.Group className="mb-3" controlId="file">
            {inputForm.files ? (
              <div className="image-prv">
                <Img src={inputForm.files} />
              </div>
            ) : null}
            <input type="file" onChange={onFileChange} />
            <Cropper
              style={{ height: 282, width: 450 }}
              aspectRatio={8 / 2}
              guides={false}
              src={srcCrop}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              viewMode={1}
              dragMode="move"
              cropBoxMovable={false}
            />
          </Form.Group>
        </div>

        <div className="col-lg-12">
          <Button varient="primary" onClick={(e) => submitForm()}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
});

export default Add;

import React from 'react'
import {Tab,Nav,Row,Col} from 'react-bootstrap';
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import "./style.scss";


function Settings() {

  return (

    <div className="container" id="settings">
    <Tab.Container defaultActiveKey="first">
  <Row>
    <Col sm={2}>
      <Nav variant="pills" className="flex-column tab-button">
        <Nav.Item>
          <Nav.Link eventKey="first" className="Tabs">Tab 1</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second" className="Tabs">Tab 2</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={10}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <FirstPage/>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <SecondPage/>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
  </div>
  );
}
export default Settings;
import React, {
  forwardRef,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
  useImperativeHandle,
} from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.scss";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

const CHANGE_PASS_MUTATION = gql`
  mutation changePassword($token: String, $hash: String) {
    changePassword(obj: { token: $token, hash: $hash }) {
      status
    }
  }
`;

function New() {
  const { id } = useParams();
  const [querys, { loading, error }] = useMutation(CHANGE_PASS_MUTATION);
  const [isValid, setIsValid] = useState(false);
  const [inputForm, setForm] = useState({
    password1: null,
    password2: null,
  });
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  const submitForm = () => {
    const values = { ...inputForm };

    if (!values.password1 || !values.password2) {
      return setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (values.password1 !== values.password2) {
      return setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (
      !values.password1.match(
        /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
      )
    ) {
      alert("Please enter secure and strong password eg-Abcd123&");
      return "";
    } else {
      querys({ variables: { hash: values.password1, token: id } }).then(
        (res) => {
          alert("password is changed");
        }
      );
    }
  };
  return (
    <div id="forget">
      {isValid ? <Alert variant="danger">Please Check Password</Alert> : ""}
      <div className="d-flex align-items-center auth px-0 mt-5">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <span>
              Password *(Must be more than 8 digit -15 ) eg-"Abcd123&"
            </span>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>New Passoword</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter New Passoword"
                name="password1"
                onChange={(e) => formChange(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>New Passoword</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter New Password"
                name="password2"
                onChange={(e) => formChange(e)}
              />
            </Form.Group>
            <div class="ctdc-btn">
              <Button
                className="submit-btn"
                type="submit"
                onClick={(e) => submitForm()}
              >
                Submit
              </Button>
            </div>

            <Link to="login">Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default New;

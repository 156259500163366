import React, {
  useNavigate,
  forwardRef,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
  useImperativeHandle,
} from "react";

import {
  Button,
  Tab,
  Tabs,
  Alert,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
const PAYMENT_MUTATION = gql`
  mutation createPayment($amount: Int) {
    createPayment(obj: { amount: $amount }) {
      amount
      amount_due
      amount_paid
      attempts

      currency
      entity
      id
      offer_id
      receipt
    }
  }
`;

const PAYMENT_SUCESS_MUTATION = gql`
  mutation paymentScuessUserSubscrib(
    $amount: Int
    $type: String
    $validity: String
    $orderCreationId: String
    $razorpayPaymentId: String
    $razorpayOrderId: String
    $razorpaySignature: String
  ) {
    paymentScuessUserSubscrib(
      obj: {
        amount: $amount
        validity: $validity
        type: $type
        orderCreationId: $orderCreationId
        razorpayPaymentId: $razorpayPaymentId
        razorpayOrderId: $razorpayOrderId
        razorpaySignature: $razorpaySignature
      }
    ) {
      status
    }
  }
`;

const RrazopayUserSubscripction = ({ type, amountt, validity }) => {
  const [reqQuery, { loading, error }] = useMutation(PAYMENT_MUTATION);
  const [reqsecuess] = useMutation(PAYMENT_SUCESS_MUTATION);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    reqQuery({
      variables: {
        amount: amountt,
        currency: "INR",
        receipt: "MemberShip",
        payment_capture: true,
      },
    }).then((res) => {
      let { id, amount } = res.data.createPayment;

      const options = {
        key: "rzp_test_KMpvHhgNDnZv5F", // Enter the Key ID generated from the Dashboard "rzp_test_OqV6i62mvoPxrD" ,//
        amount: amount,
        currency: "INR",
        name: "CALICUT DEFENCE",
        description: "",

        handler: async function (response) {
          const data = {
            orderCreationId: id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          reqsecuess({
            variables: {
              amount: amountt,
              type: type,
              validity: validity,
              orderCreationId: id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            },
          }).then((res) => {
            setTimeout(() => {
              window.location.href =
                "https://www.calicutdefence.com/paymentSuccess";
            }, 3000);
          });
        },
        // prefill: {
        //     name: "Soumya Dey",
        //     email: "SoumyaDey@example.com",
        //     contact: "9999999999",
        // },
        // notes: {
        //     address: "Soumya Dey Corporate Office",
        // },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  }

  return (
    <Button
      className="Razorpay-btn"
      variant="primary"
      onClick={displayRazorpay}
    >
      Subscripction
    </Button>
  );
};

export default RrazopayUserSubscripction;


import React from 'react'

import './style.scss';
import Slide from './slide'
import Activity from './activity'
import NewsItems from './news'
import UpcommingEvent from './upcomingevents'
import DonationList from './donation-list'
import { Service_banner } from '../../shared'
import bannar4 from '../../assets/img/pic1.png';
export default function Home() {
  return (
    <div id="home">
      <Slide />
      <Service_banner />

      <Activity />
      <section className='donation'>

        <div className="row justify-content-end">
          <div className="col-12 col-sm-12 col-md-7" >
            <div className='donation-img'></div>
          </div>
          <div className="col-12 col-sm-12 col-md-5" >

            <div className='donation-container'>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p> */}
              <h4>To Be a Rainbow</h4><h4> in someone's Cloud</h4>
              <p>We are thrilled to announce that this community, that is dreaming of purely charitable causes, has spent over Rs.12 lakhs on charity work alone last year. .</p>
              <p>We have a vision for our destiny, we are doing charitable activities.
              </p>
              <ul className='donation-card'>
                <li className='donation-n'></li>
                <li className='project'> </li>
                <li className='bea'> </li>
              </ul>
            </div>
          </div>

        </div>


      </section>


      <NewsItems />

      <UpcommingEvent />
      <DonationList />




      {/* <section className='instagram'>
        <div className="container">
          <h1>In Pictures</h1>
          <p>Follow us on Instagram</p>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-3" >
              <div className='event-box'>
                <div className='title'>
                  <div className='date'>  <span> 01</span>
                    <span> 556</span>
                  </div>
                  <h5>Lorem ipsum dolor sit amet, consectetur </h5>
                </div>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur
                  sunt excepturi dicta ex, placeat ab esse, iure harum eaque fuga asperiores
                  distinctio amet temporibus enim illum molestiae
                </p>

              </div>
            </div>





          </div>
          <button> More</button>
        </div>
      </section>


      <section id="testimonials">
        <h4>What They Are Talking About Us</h4>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={bannar} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>First slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={bannar} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>Second slide label</h5>
                <p>Some representative placeholder content for the second slide.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={bannar} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>Third slide label</h5>
                <p>Some representative placeholder content for the third slide.</p>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

      </section> */}
    </div>
  )
}

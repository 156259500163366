import React from 'react';

import Donate from '../profile/donate'
function GuestDonation() {
  return <div>
     <Donate />
  </div>;
}

export default GuestDonation;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import fakeAuth from "../fakeAuth";
import logo from "../assets/img/profile.png";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { USER_ACTIVE, USER_ROLE } from "../redux/action";
import AclPermission from "../lib/acl";

import { Img } from "../shared";

const USER_QUERY = gql`
query {
  profile_view {
    name
    lastname
    ex_ServiceMan
    in_Service
    date_of_birth
    blood_group
    blood_donation
    contact
    whatsapp
    address
    post
    family {
      name
      relation
      blood_group
      date_of_birth
    }
    pin
    district
    email
    subscribed
    verificationtype
    _id
    account_refId {
      email
      action
      _id
      role {
        _id
        name
      }
    }
    area
    date_of_marriage
    occupation
    subscribed
    userId

    docFile {
      filename
      _id
    }
    assetManagement {
      filename
      _id
    }
    group_refId{
      title

    }


    membership_start_date
    membership_end_date
    membership_validity
  

  }
}
`;
const User = () => {
  //const { data } = useQuery(USER_QUERY);
  const [loadEntries] = useLazyQuery(USER_QUERY)
  // let { name,
  //     lastname,
  //     date_of_birth,
  //     blood_group,
  //     ex_ServiceMan,
  //     contact,
  //     whatsapp,
  //     address,
  //     district,
  //     post,
  //     pin,
  //     blood_donation,assetManagement}=data?data.profile_view:{}
  const dispatch = useDispatch();
  const isLogedin = useSelector((state) => state.user.isLogedin);
  const isrole = useSelector((state) => state.role.isAdmin);
  const [isAdmin, setAdmin] = useState(false);

  const profile = useSelector((state) => state.profile);
  const [groupName, setGroupName] = useState(null);
  const [obj, setData] = useState([]);

  useEffect( () => {
    loadEntries().then(res=>{
       let obj = res.data ? res.data.profile_view : {};
       logic(obj)
 
     });
   
   }, []);


   let logic=(obj)=>{
    if(obj){
      
       localStorage.setItem('userId',obj._id)
       if(obj.account_refId){
        localStorage.setItem('Role',JSON.stringify(obj.account_refId.role))
       }

       if(obj?.group_refId){
        if(obj?.group_refId.length>0){
         localStorage.setItem('group',obj.group_refId[0].title)
         setGroupName(obj.group_refId[0].title)
        }
       }
   
       setData(obj)
       
     }
   }

  // if(isrole.length>0){

  //     isrole.forEach(element => {
  //         if(element.name=="admin"){
  //             setAdmin(true)
  //         }

  //     });

  // }
  const history = useNavigate();
  let login = () => {
    history("/login");
  };
  let myaccount = () => {
    history("/profile");
  };

  let admin = () => {
    history("/admin/user-list");
  };

  let logout = () => {
    fakeAuth.signout(false);
    dispatch({ type: USER_ACTIVE, payload: false });
    dispatch({ type: USER_ROLE, payload: null });
    localStorage.clear();
    history("/home");
  };

  return (
    <>
      {isLogedin ? (
        <div className="userMenu">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <img alt="CALICUT DEFENCE" src={logo} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div>
                {profile.name}
                {profile.lastname}
              </div>
              <Dropdown.Item onClick={myaccount}>My Account</Dropdown.Item>
              <AclPermission
                isActive={[
                  "admin",
                  "President",
                  "VP, Sec, jt Sec, treasurer",
                  "Trusty Borad",
                  "Counsilor",
                  "Media Cell",
                ]}
              >
                <Dropdown.Item onClick={admin}>Admin Dashboard</Dropdown.Item>
              </AclPermission>

              <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <button className="sign-btn" type="button" onClick={login}>
          Sign in
        </button>
      )}
    </>
  );
};

export default User;

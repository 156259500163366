


import React from 'react'

export default function Spinner(props) {
    return (

<div className='overlay'> 
        <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>  
      </div>
    )
}

import React, { Component, Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import { gql, useQuery, useLazyQuery } from "@apollo/client";

import "./App.scss";

import routes from "./routes";
import { Footer, Navbar } from "./template";

import fakeAuth from "./fakeAuth";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import { USER_PROFILE, USER_ACTIVE, USER_ROLE } from "./redux/action";

import Home from "./page/home";
import About from "./page/about";
import Contact from "./page/contact";
import Gallery from "./page/gallery";
import GalleryDetails from "./page/gallery/galleryDetails";

import Groups from "./page/groups";
import News from "./page/news";
import Policy from "./page/policy";
import Terms from "./page/terms";
import Event from "./page/event";
import Activity from "./page/activity";
import Login from "./page/login";
import Registration from "./page/registration";
import AdditionalDetails from "./page/registration/add-details";
import Profile from "./page/profile";
import Invoice from "./page/invoice";
import ForgotPassword from "./page/password";
import NewPassword from "./page/password/new";
import News_details from "./page/news/news_details";
import Activity_details from "./page/activity/activity_details";
import Success from "./page/scuess";
import Administration from "./page/administration";
import AccountVerification from "./page/accountVerification";
import GuestDonation from "./page/guestDonation";
import PaymentSuccess from "./page/paymentMessage";

import Cancellation from "./page/cancellation";
import Shipping from "./page/shipping";

import AdminLayout from "./page/admin";

import AdminActivities from "./page/admin/activities";
import ActivitiesAdd from "./page/admin/activities/Add";
import Activitylist from "./page/admin/activitilist";
import ActivitylistAdd from "./page/admin/activitilist/Add";
import Dashboard from "./page/admin/dashboard";
import AdminNews from "./page/admin/news";
import NewsAdd from "./page/admin/news/Add";
import AdminEventMangement from "./page/admin/eventmanagment";
import EventMangementAdd from "./page/admin/eventmanagment/Add";
import AdminDonation from "./page/admin/donation";
import DonationAdd from "./page/admin/donation/Add";
import UsersList from "./page/admin/users";
import UsersView from "./page/admin/users/View";
import PaymentManagement from "./page/admin/payment-management";
import DonationManagement from "./page/admin/donation-management";
import AdministrationAdmin from "./page/admin/administration";
import Testimonial from "./page/admin/testimonials";
import TestimonialAdd from "./page/admin/testimonials/Add";
import AdminGallery from "./page/admin/gallery";
import GalleryAdd from "./page/admin/gallery/Add";
import Folders from "./page/admin/gallery/folder";
import GalleryList from "./page/admin/gallery/GalleryList";
import Assets from "./page/admin/assets";
import AssetsList from "./page/admin/assets/AssetList";
import Settings from "./page/admin/settings";
import AdminProfile from "./page/admin/adminprofile";
import Acl from "./page/admin/acl";
import Group from "./page/admin/group";
import Groupnfo from "./page/admin/group/groupsnfo";
import Ads from "./page/admin/ads";

const SETTINGS = gql`
  {
    validateToken {
      status
      message
      role {
        name
      }
    }
  }
`;

function App() {
  const dispatch = useDispatch();

  const [settings, { called, loading, data }] = useLazyQuery(SETTINGS);
  const token = localStorage.getItem("AUTH_TOKEN");

  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN")) {
      settings().then((res) => {
        if (res.data) {
          if (res.data.validateToken.status == "true") {
            //dispatch({ type: USER_ROLE,payload:res.data.validateToken.role})
            dispatch({ type: USER_ACTIVE, payload: true });
            dispatch({ type: USER_ROLE, payload: res.data.validateToken.role });
            fakeAuth.authenticate(true);
          } else {
            dispatch({ type: USER_ACTIVE, payload: false });
            fakeAuth.authenticate(false);
            localStorage.removeItem("AUTH_TOKEN");
          }
        }
      });
    }

    //     if (token!='null') {
    //
    //       loadUser().then(res => {
    //         if (res.data) {
    //
    //           fakeAuth.authenticate(true)
    //           dispatch({ type: USER_ACTIVE, payload: true })
    //           // res.data.profile_view.role.role.forEach(element => {
    //           //   if (element.name == "admin") {
    //           //     dispatch({ type: USER_ROLE, payload: "admin" })
    //           //   }

    //           // });
    //           dispatch({ type: USER_PROFILE, payload: res.data.profile_view })
    //         }
    //       })

    //     }
  }, []);

  return (
    <div className="container-scroller">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="/accountVerification/:id"
              element={<AccountVerification />}
            />
            <Route path="/paymentSuccess" element={<PaymentSuccess />} />
            <Route path="/administration" element={<Administration />} />
            <Route path="/success" element={<Success />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/additional-details" element={<AdditionalDetails />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password/:id" element={<NewPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/invoice/:id" element={<Invoice />} />
            <Route path="/guest-donation" element={<GuestDonation />} />
            <Route path="/event" element={<Event />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallerylist/:id" element={<GalleryDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/news" element={<News />} />
            <Route path="/news_details/:id" element={<News_details />} />
            <Route path="/activity" element={<Activity />} />
            <Route
              path="/activity_details/:id"
              element={<Activity_details />}
            />
            <Route path="/cancellation" element={<Cancellation />} />
            <Route path="/shipping" element={<Shipping />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/admin-news" element={<AdminNews />} />
            <Route path="/admin/news-add" element={<NewsAdd />} />
            <Route
              path="/admin/admin-activities"
              element={<AdminActivities />}
            />
            <Route path="/admin/activitiList" element={<ActivitiesAdd />} />
            <Route path="/admin/activitylist/:id" element={<Activitylist />} />
            <Route
              path="/admin/activitylist-add"
              element={<ActivitylistAdd />}
            />
            <Route
              path="/admin/admin-event"
              element={<AdminEventMangement />}
            />
            <Route path="/admin/event-add" element={<EventMangementAdd />} />
            <Route path="/admin/admin-donation" element={<AdminDonation />} />
            <Route path="/admin/donation-add" element={<DonationAdd />} />
            <Route path="/admin/testimonial" element={<Testimonial />} />
            <Route path="/admin/testimonial-add" element={<TestimonialAdd />} />
            <Route path="/admin/admin-gallery" element={<AdminGallery />} />
            <Route path="/admin/gallery-add" element={<GalleryAdd />} />
            <Route path="/admin/gallery-folder" element={<Folders />} />

            <Route path="/admin/gallery-list" element={<GalleryList />} />
            <Route path="/admin/assets" element={<Assets />} />
            <Route path="/admin/assets-list" element={<AssetsList />} />
            <Route path="/admin/settings" element={<Settings />} />
            <Route path="/admin/user-list" element={<UsersList />} />
            <Route path="/admin/user-view" element={<UsersView />} />
            <Route
              path="/admin/payment-manage"
              element={<PaymentManagement />}
            />
            <Route
              path="/admin/donation-manage"
              element={<DonationManagement />}
            />
            <Route
              path="/admin/adadministration"
              element={<AdministrationAdmin />}
            />
            <Route path="/admin/userProfile/:id" element={<AdminProfile />} />
            <Route path="/admin/acl" element={<Acl />} />
            <Route path="/admin/group" element={<Group />} />
            <Route path="/admin/groupsinfo/:id" element={<Groupnfo />} />
            <Route path="/admin/ads" element={<Ads />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
function Layout() {
  return (
    <div id="public">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

import React, { Fragment } from "react";
import { Heading } from "../../../shared";
import { useQuery, gql } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import AclPermission from "../../../lib/acl";
const DONATION_MANAGEMENT_QUERY = gql`
  {
    donationManage_list {
      name
      startDate
      endDate
      amount
      _id
    }
  }
`;

const DonationManagement = () => {
  const { data, loading, error } = useQuery(DONATION_MANAGEMENT_QUERY);
  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return `Error! ${error.message}`;

  const actionsMemo = (e) => {
    console.log(e);
  };
  console.log(data);
  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <Link to={"/admin/donation_history/" + row._id} className="table_link">
          {row.name}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Invoice",
      cell: (row) => (
        <button className="button_transparent" onClick={(_id) => row._id}>
          <i className="mdi mdi-file-check btn-icon-append"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div id="donation-management">
      <div className="card">
        <div className="card-body">
          <div className="clearfix mb-4">
            <Heading title="Donation Management" />
            <DataTable
              pagination
              highlightOnHover
              columns={columns}
              data={data ? data.donationManage_list : []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationManagement;

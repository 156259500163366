// import React, {forwardRef,useState,useRef} from 'react'
// import { gql, useMutation } from "@apollo/client";
// import {ImageUpload,Heading,InputTextPrompt } from '../../../shared';
// import { Button, Alert,Col,Form } from 'react-bootstrap';

// const ACTIVITIES_MUTATION = gql`
//   mutation activity_create(
//   $title: String!
// $body: String!
//   $meta_keyword: String!
//   $meta_description: String!
//   $seo_title: String!
//   $file: Upload
//   ) {
// 	activity_create(obj:{
//       title: $title,
// 	body: $body,
//       meta_keyword: $meta_keyword,
//       meta_description: $meta_description,
//       seo_title: $seo_title,
//       file: $file
//   }) {
//       status
//   }
// }
// `;

// const Add = forwardRef((props, ref) =>{
//   const [createDonation,{loading, error}] = useMutation(ACTIVITIES_MUTATION);
//   const [isValid, setIsValid] = useState(false)
//   const upload_Ref = useRef();
//   const [inputForm, setForm] = useState({

//       title: null,
//       body: null,
//       seo_title: null,
//       meta_description: null,
//       meta_keyword: null
//     })

// const submitForm=()=>
// {
//
//   const file = upload_Ref.current.getSelected()
//   const values = { ...inputForm };

//   values["file"] = file;

//   var textformat = /^[a-zA-Z @]+$/;
//
//   if
//   (
//       !values.title ||
//       !values.title.match(textformat) ||
//       !values.body ||
//       !values.body.match(textformat) ||
//       !values.seo_title ||
//       !values.meta_keyword ||
//       !values.meta_description
//   )

//   {
//        return(
//            setIsValid(true)
//        );
//       }
//       else{
//           setIsValid(false);
//       }

//   createDonation({variables:{...values } })
//   }
// let formChange = (event) => {
//   const values = { ...inputForm };
//   values[event.target.name] = event.target.value;
//   setForm(values)
// }
//   return (
//       <div id="donation_add">
//           {isValid?<Alert variant="danger">Please fill all the field</Alert>:""}

//               <div className="row">

//                   <div className="col-sm-12">
//                   <Form.Group className="mb-3" controlId="formBasicEmail" >
//           <Form.Label>Title <span className="mandatory">*</span></Form.Label>
//           <Form.Control type="text" placeholder="Enter Title" name="title" onChange={(e) => formChange(e)} />
//         </Form.Group>
//         <Form.Group className="mb-3" controlId="formBasicEmail" >
//           <Form.Label>Content <span className="mandatory">*</span></Form.Label>
//           <textarea type="text" placeholder="Enter Name" name="body" rows="8" onChange={(e) => formChange(e)} />
//         </Form.Group>
//         <Form.Group className="mb-3" controlId="formBasicEmail" >
//           <Form.Label>SEO Title <span className="mandatory">*</span></Form.Label>
//           <Form.Control type="text" placeholder="SEO Title" name="seo_title" onChange={(e) => formChange(e)} />
//         </Form.Group>
//                   </div>
//                   <div class="col-sm-12">
//                   <Form.Group className="mb-3" controlId="formBasicEmail" >
//           <Form.Label>Meta Keyword<span className="mandatory">*</span></Form.Label>
//           <Form.Control type="text" placeholder="Meta Keyword" name="meta_keyword" onChange={(e) => formChange(e)} />
//         </Form.Group>
//         <Form.Group className="mb-3" controlId="formBasicEmail" >
//           <Form.Label>Meta Description<span className="mandatory">*</span></Form.Label>
//           <Form.Control type="text" placeholder="Meta Description" name="meta_description" onChange={(e) => formChange(e)} />
//         </Form.Group>
//         <Form.Group className="mb-3" controlId="file">
//                   <ImageUpload ref={upload_Ref} />
//                   </Form.Group>
//                       </div>

//               <div className="col-lg-12">
//                   <Button varient="primary" onClick={(e)=>submitForm()}>Save</Button>
//                   </div></div>
//       </div>

//   )
// })

// export default Add

import React, { forwardRef, useState, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { ImageUpload, Heading, InputTextPrompt, Img } from "../../../shared";
import { Button, Alert, Col, Form } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NEWS_MUTATION = gql`
  mutation news_create(
    $title: String!
    $body: String!
    $meta_keyword: String!
    $meta_description: String!
    $seo_title: String!
    $file: String
  ) {
    news_create(
      obj: {
        title: $title
        body: $body
        meta_keyword: $meta_keyword
        meta_description: $meta_description
        seo_title: $seo_title
        file: $file
      }
    ) {
      status
    }
  }
`;

const NEWS_BY_UPDATE_MUTATION = gql`
  mutation news_updateById(
    $title: String!
    $body: String!
    $meta_keyword: String!
    $meta_description: String!
    $seo_title: String!
    $file: String
    $_id: String
  ) {
    news_updateById(
      obj: {
        title: $title
        body: $body
        meta_keyword: $meta_keyword
        meta_description: $meta_description
        seo_title: $seo_title
        file: $file
        _id: $_id
      }
    ) {
      status
    }
  }
`;

const Add = forwardRef((props, ref) => {
  const [createNews, { loading, error }] = useMutation(NEWS_MUTATION);
  const [UpdateNews] = useMutation(NEWS_BY_UPDATE_MUTATION);

  const [isValid, setIsValid] = useState("");
  const upload_Ref = useRef();

  const [inputForm, setForm] = useState({
    title: props.obj.title ? props.obj.title : null,
    body: props.obj.body ? props.obj.body : null,
    seo_title: props.obj.seo_title ? props.obj.seo_title : null,
    meta_description: props.obj.meta_description
      ? props.obj.meta_description
      : null,
    meta_keyword: props.obj.meta_keyword ? props.obj.meta_keyword : null,
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
  });

  useEffect(() => {
    setSrc(
      props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.assetManagement.filename
        : ""
    );
  }, [props]);
  const submitForm = () => {
    const file = cropper.getCroppedCanvas().toDataURL();
    const values = { ...inputForm };

    values["file"] = file;

    // if
    //   (
    //   !values.title ||
    //   !values.body ||
    //   !values.seo_title ||
    //   !values.meta_keyword ||
    //   !values.meta_description
    // ) {
    //   return (
    //     setIsValid('Please Check All The Field')
    //   );
    // }
    // else {
    //   setIsValid('');
    // }

    if (props.editAble) {
      values._id = props.obj._id;

      UpdateNews({ variables: { ...values } }).then((res) => {
        setIsValid("Updated Successfully");
        props.refetch();
      });
      timeOutDelay();
    } else {
      createNews({ variables: { ...values } }).then((res) => {
        setIsValid("Created Successfully");
        props.refetch();
      });
      timeOutDelay();
    }

    setTimeout(() => {
      setIsValid(null);
    }, 2000);
  };

  const timeOutDelay = () => {
    setTimeout(() => {
      props.handleClose();
    }, 2000);
  };

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();
  return (
    <div id="donation_add">
      <div className="row">
        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.title}
              placeholder="Enter Title"
              name="title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Content <span className="mandatory">*</span>
            </Form.Label>
            <textarea
              type="text"
              value={inputForm.body}
              placeholder="Enter Name"
              name="body"
              rows="8"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              SEO Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.seo_title}
              placeholder="SEO Title"
              name="seo_title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
        </div>
        <div class="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Meta Keyword<span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.meta_keyword}
              placeholder="Meta Keyword"
              name="meta_keyword"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Meta Description<span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.meta_description}
              placeholder="Meta Description"
              name="meta_description"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="file">
            <input type="file" onChange={onFileChange} />
            <Cropper
              style={{ height: 282, width: 450 }}
              aspectRatio={2 / 2}
              guides={false}
              src={srcCrop}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              viewMode={1}
              dragMode="move"
              cropBoxResizable={false}
              cropBoxMovable={false}
            />
          </Form.Group>
        </div>

        <div className="col-lg-12">
          <Button varient="primary" onClick={(e) => submitForm()}>
            Save
          </Button>
          {isValid ? (
            <Alert className="mt-3" variant="success">
              {isValid}
            </Alert>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});

export default Add;

import React, { forwardRef, useState, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { ImageUpload, Heading, InputTextPrompt, Img } from "../../../shared";
import { Button, Alert, Col, Form } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
const DONATION_MUTATION = gql`
  mutation donations_create(
    $title: String!
    $body: String!
    $amount: Int!
    $file: String
  ) {
    donations_create(
      obj: { title: $title, body: $body, amount: $amount, file: $file }
    ) {
      status
    }
  }
`;

const DONATION_BY_UPDATE_MUTATION = gql`
  mutation donations_updateById(
    $id: String
    $title: String!
    $body: String!
    $amount: Int!
    $file: String
  ) {
    donations_updateById(
      obj: { id: $id, title: $title, body: $body, amount: $amount, file: $file }
    ) {
      status
    }
  }
`;

const Add = forwardRef((props, ref) => {
  const [createDonation, { loading, error }] = useMutation(DONATION_MUTATION);
  const [UpdateDonation] = useMutation(DONATION_BY_UPDATE_MUTATION);

  const [isValid, setIsValid] = useState("");
  const upload_Ref = useRef();
  const [inputForm, setForm] = useState({
    title: props.obj.title,
    body: props.obj.body,
    amount: props.obj.amount,
    files: props.obj.assetManagement
      ? props.obj.assetManagement.filename
      : null,
  });
  useEffect(() => {
    setSrc(
      props.obj.assetManagement
        ? process.env.REACT_APP_STATIC + props.obj.assetManagement.filename
        : ""
    );
  }, [props]);
  const submitForm = () => {
    const file = cropper.getCroppedCanvas().toDataURL();
    const values = { ...inputForm };
    values["file"] = file;

    values["amount"] = parseInt(values.amount);

    if (props.editAble) {
      if (values.file) {
        values.id = props.obj._id;

        UpdateDonation({ variables: { ...values } }).then((res) => {
          setIsValid("Updated Successfully");
          props.refetch();
        });
        timeOutDelay();
      } else {
        values["file"] = null;
        UpdateDonation({ variables: { ...values } }).then((res) => {
          setIsValid("Updated Successfully");
          props.refetch();
        });
        timeOutDelay();
      }
    } else {
      createDonation({ variables: { ...values } }).then((res) => {
        setIsValid("Created Successfully");
        props.refetch();
      });
      timeOutDelay();
    }

    setTimeout(() => {
      setIsValid(null);
    }, 2000);
  };

  const timeOutDelay = () => {
    setTimeout(() => {
      props.handleClose();
    }, 2000);
  };

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();
  return (
    <div id="donation_add">
      <div className="row">
        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Title <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={inputForm.title}
              placeholder="Enter Title"
              name="title"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Content <span className="mandatory">*</span>
            </Form.Label>
            <textarea
              type="text"
              value={inputForm.body}
              placeholder="Content"
              name="body"
              rows="8"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Amount <span className="mandatory">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              value={inputForm.amount}
              placeholder="Amount"
              name="amount"
              onChange={(e) => formChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="file">
            {/* {inputForm.files ? (
              <div className="image-prv">
                <Img src={inputForm.files} />
              </div>
            ) : null} */}
            <input type="file" onChange={onFileChange} />
            <Cropper
              style={{ height: 282, width: 450 }}
              aspectRatio={2 / 2}
              guides={false}
              src={srcCrop}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              viewMode={1}
              dragMode="move"
              cropBoxResizable={false}
              cropBoxMovable={false}
            />
          </Form.Group>
        </div>

        <div className="col-lg-12">
          <Button varient="primary" onClick={(e) => submitForm()}>
            Save
          </Button>
          {isValid ? (
            <Alert className="mt-3" variant="success">
              {isValid}
            </Alert>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});

export default Add;

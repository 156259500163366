import React from 'react'
import { Event_list, Advt } from '../../shared';
import { useQuery, gql, useMutation } from "@apollo/client";
import { Link } from 'react-router-dom';
// const ACTIVITIES_QUERY = gql`
//   {
//     event_list(obj:{page:3,skip:0}){
//       title
//       body
//       start_date
//     }
//   }
// `;
const NEWS_QUERY = gql`
  {
    news_list(obj:{page:3,  skip:0}){
      title
      body
      createdAt
      _id
      assetManagement{
        filename
      }
    }
  }
`;
export default function UpcommingEvent() {
    const { data, loading, error, refetch } = useQuery(NEWS_QUERY);


    let list = data ? data.news_list.map((res, key) => <Event_list {...res} key={key} />) : 'null'

    return (

        <section className='upcomingevents'>
            <div className="container">
                <h6>Latest News</h6>
                
                <div className='upcoming-events'>
                    <div className="row">
                        {list}
                    </div>

                </div> <button className='more-btn'><Link to="news">More</Link></button></div>
        </section>


    )
}

import React from 'react'

export default function Share_btn(props) {
    return (
<>
<a target="_blank" href = "https://www.facebook.com/groups/273514820725673/?ref=share"><i className="fab fa-facebook-square"></i></a>
              <a target="_blank" href = "https://chat.whatsapp.com/IWL8JnZRDNnFdMhRiYH1Fg"><i className="fab fa-whatsapp-square"></i></a>
              <a target="_blank" href = "https://t.me/+rx92d-OdF8g1NTM1"><i className="fab fa-twitter-square"></i></a>
              <a target="_blank" href = "https://youtube.com/channel/UCk9Z6Mbq61xqLZ-ajezV6Rw"><i className="fab fa-youtube"></i></a>
</>
    )
}

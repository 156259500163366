import {USER_PROFILE } from '../action'
const initialState = {
    isLoading: false,
    name:null,
    email: null,
    lastname: null,
    date_of_birth: null,
    Blood: null,
    ex_service:null,
    contact: null,
    whatsapp: null,
    address: null,
    post:null,
    pin: null,
    donation: null,
    assetManagement:{
       filename:null
    }
 };
 const profile = (state = initialState, action) => {
    switch (action.type) {
       case USER_PROFILE:
           let {name,
            email,
            lastname,
            date_of_birth,
            Blood,
            ex_service,
            contact,
            whatsapp,
            address,
            post,
            pin,
            donation}=action.payload
         return { ...state, name:name }
       default:
          return state;
    }
 }
 export default profile;

import React, { forwardRef, useState, Fragment, useEffect, Suspense, lazy, useRef, useImperativeHandle } from 'react';
import { Form, Button, Row, Col, Alert, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import './style.scss'
import { Img, ImageUpload, Spinner } from '../../../shared';
import { useQuery, gql, useMutation } from "@apollo/client";
import { event } from 'jquery';
import DatePicker from "react-datepicker";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";



const SIGNUP_MUTATION = gql`
  mutation siginUpByadmin(
	$email: String
	$hash: String
  $file: String
  $name: String!
  $lastname: String!
  $date_of_birth: String!
  $blood_group: String!
  $blood_donation: String
  $ex_ServiceMan: String!
  $contact: String!
  $whatsapp: String!
  $address: String!
  $post: String!
  $pin: String!
  $district: String!
  $docfile: String
  $family: JSON
  $verificationtype: String!
  $subscribed: String
  $in_Service : String,
  $area: String!
  $date_of_marriage: String!
  $occupation: String!
    ) {
		siginUpByadmin(obj:{
        email: $email,
        hash: $hash,
        file: $file,
        name: $name,
        lastname: $lastname,
        date_of_birth: $date_of_birth,
        blood_group: $blood_group,
        blood_donation: $blood_donation,
        district:$district,
        ex_ServiceMan: $ex_ServiceMan,
        contact: $contact,
        whatsapp: $whatsapp,
        address: $address,
        post: $post,
        pin: $pin,
        docfile:$docfile,
        family:$family,
        verificationtype:$verificationtype,
		subscribed: $subscribed,
		in_Service : $in_Service,
		area:$area,
		occupation: $occupation,
		date_of_marriage: $date_of_marriage
    }) {
        status
    }
  }
`;
const GROUP_QUERY = gql`
  {
    group_list {
      _id
      title
    }
  }
`;

const Create = forwardRef((props, ref) => {
	let history = useNavigate();
	const [isSpinner, setisSpinner] = useState(false);
	const [fields, setFields] = useState([{
		name: null,
		relation: null,
		bloodGroup: null,
		date_of_birth: null,
	}])
	const upload_Ref = useRef();
	const doc_upload_Ref = useRef();
	const [inputForm, setForm] = useState({})
	const [isValid, setIsValid] = useState('');
	const [errors, setErrors] = useState({})
	const [createProfile] = useMutation(SIGNUP_MUTATION);
	const [File, setFile] = useState({})

	const [cropper, setCropper] = useState();
	const [showCrop, setCropShow] = useState(false);
	const [srcCrop, setSrc] = useState();
	const [profileImage, setProfile] = useState();


	const [cropperDoc, setDocCropper] = useState();
	const [docShow, setCropDocShow] = useState(false);
	const [srcDocCrop, setDocSrc] = useState();
	const [docImage, setDoc] = useState();

	const { data, loading, error } = useQuery(GROUP_QUERY);

	let formChange = (event) => {
		const values = { ...inputForm };
		values[event.target.name] = event.target.value;
		if (event.target.value.toLowerCase() === "true") {
			values[event.target.name] = true;
		}
		if (event.target.value.toLowerCase() === "false") {
			values[event.target.name] = false;
		}
		setForm(values)

	}
	let registration = (event) => {
		const values = { ...inputForm };

		values['family'] = fields;
		event.preventDefault();
		if (validateForm()) {
			values["file"] = profileImage;
			values["docfile"] = docImage;
			setisSpinner(true)

			createProfile({ variables: { ...values } })
				.then(res => {
					if (res.data.siginUpByadmin.status) {
						setIsValid('Created Successfully');
						props.refetch();
						props.handleClose()
					}
					else {
						setIsValid('user is all ready created ');
					}


				})
			setTimeout(() => {
				setIsValid('')
			}, 1500)



		}
	}

	function dateofbirthDateChange(date) {
		const values = { ...inputForm };
		values['date_of_birth'] = date
		setForm(values)
	}
	function dateofmarriageDateChange(date) {
		const values = { ...inputForm };
		values['date_of_marriage'] = date
		setForm(values)
	}


	function handleAdd() {
		const values = [...fields];
		values.push({ name: null, value: null });
		setFields(values);
	}
	function handleChange(i, event) {
		const values = [...fields];
		values[i][event.target.name] = event.target.value;

		setFields(values);


	}

	function handleChangeSelect(i, event) {
		const values = [...fields];
		values[i][event.target.name] = event.target.value;
		setFields(values);

	}


	function familyDateChange(i, event, date) {

		const values = [...fields];
		values[i]['date_of_birth'] = date
		setFields(values);

	}



	function handleRemove(i) {
		const values = [...fields];
		values.splice(i, 1);
		setFields(values);
	}


	function validateForm() {

		let errors = {};
		let formIsValid = true;
		if (!inputForm["name"]) {
			formIsValid = false;
			errors["name"] = "*Please enter your Name.";
		}

		if (!inputForm["lastname"]) {
			formIsValid = false;
			errors["lastname"] = "*Please enter your Last Name.";
		}

		if (!inputForm["contact"]) {
			formIsValid = false;
			errors["contact"] = "*Please enter your mobile no.";
		}

		if (typeof inputForm["contact"] !== "undefined") {
			if (!inputForm["contact"].match(/^[0-9]{10}$/)) {
				formIsValid = false;
				errors["contact"] = "*Please enter valid mobile no.";
			}
		}

		if (!inputForm["whatsapp"]) {
			formIsValid = false;
			errors["whatsapp"] = "*Please enter your whatsapp mobile no.";
		}

		if (typeof inputForm["whatsapp"] !== "undefined") {
			if (!inputForm["whatsapp"].match(/^[0-9]{10}$/)) {
				formIsValid = false;
				errors["whatsapp"] = "*Please enter valid whatsapp mobile no.";
			}
		}

		if (!inputForm["date_of_birth"]) {
			formIsValid = false;
			errors["date_of_birth"] = "*Please enter date of birth.";
		}

		// if (!inputForm["date_of_marriage"]) {
		//   formIsValid = false;
		//   errors["date_of_marriage"] = "*Please enter date of marriage.";
		// }
		if (!inputForm["blood_group"]) {
			formIsValid = false;
			errors["blood_group"] = "*Please select blood group";
		}
		if (inputForm["blood_donation"] == null) {
			formIsValid = false;
			errors["blood_donation"] = "*Please select blood donation.";
		}



		if (inputForm["ex_ServiceMan"] == null) {
			formIsValid = false;
			errors["ex_ServiceMan"] = "*Please select ex ServiceMan";
		}
		// if (inputForm["in_Service"] == null) {
		//   formIsValid = false;
		//   errors["in_Service"] = "*Please select Service";
		// }

		if (!inputForm["occupation"]) {
			formIsValid = false;
			errors["occupation"] = "*Please select occupation";
		}



		if (!inputForm["address"]) {
			formIsValid = false;
			errors["address"] = "*Please Enter  your address";
		}

		if (!inputForm["area"]) {
			formIsValid = false;
			errors["area"] = "*Please Enter  your area";
		}

		if (!inputForm["district"]) {
			formIsValid = false;
			errors["district"] = "*Please Enter  district";
		}

		if (!inputForm["post"]) {
			formIsValid = false;
			errors["post"] = "*Please Enter  post";
		}

		if (!inputForm["pin"]) {
			formIsValid = false;
			errors["pin"] = "*Please Enter  pin";
		}
		if (typeof inputForm["pin"] !== "undefined") {
			if (!inputForm["pin"].match(/^[0-9]{6}$/)) {
				formIsValid = false;
				errors["pin"] = "*Please enter valid pin no.";
			}
		}

		if (!inputForm["verificationtype"]) {
			formIsValid = false;
			errors["verificationtype"] = "*Please Enter  verification Type";
		}

		if (!inputForm["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your email-ID.";
		}

		if (typeof inputForm["email"] !== "undefined") {
			//regular expression for email validation
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(inputForm["email"])) {
				formIsValid = false;
				errors["email"] = "*Please enter valid email-ID.";
			}
		}

		if (!inputForm["hash"]) {
			formIsValid = false;
			errors["hash"] = "*Please enter your password.";
		}

		if (typeof inputForm["hash"] !== "undefined") {
			if (!inputForm["hash"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
				formIsValid = false;
				errors["hash"] = "*Please enter secure and strong password.";
			}
		}


		if (!profileImage) {
			formIsValid = false;
			errors["upload_Ref"] = "*Please select Profile Pic.";

		}
		// if (upload_Ref.current.getSelected()) {
		//   const files = Math.round((upload_Ref.current.getSelected().size / 1024));

		//   if (files >= 50048) {
		//     formIsValid = false;
		//     errors["upload_Ref"] = "* Profile File too Big, please select a file less than 30mb";
		//   }
		// }

		if (!docImage) {
			formIsValid = false;
			errors["doc_upload_Ref"] = "*Please select  document file.";
		}
		// if (doc_upload_Ref.current.getSelected()) {
		//   const filess = Math.round((doc_upload_Ref.current.getSelected().size / 1024));
		//   if (filess >= 50048) {
		//     formIsValid = false;
		//     errors["upload_Ref"] = "*Document File too Big, please select a file less than 30mb";
		//   }
		// }
		setErrors(errors)
		return formIsValid;


	}

	const onFileChange = (e) => {
		const reader = new FileReader();
		reader.onload = () => {
			setSrc(reader.result);
		};
		reader.readAsDataURL(e.target.files[0]);
	}
	const onDocFileChange = (e) => {
		const reader = new FileReader();
		reader.onload = () => {
			setDocSrc(reader.result);
		};
		reader.readAsDataURL(e.target.files[0]);
	}



	const handleCropClose = () => {
		if (cropper.cropped) {
			setProfile(cropper.getCroppedCanvas().toDataURL())

		} setCropShow(false)
	};
	const handleCropShow = () => setCropShow(true);


	const handleDocCropClose = () => {
		if (cropperDoc.cropped) {
			setDoc(cropperDoc.getCroppedCanvas().toDataURL())

		} setCropDocShow(false)
	};
	const handleCropDocShow = () => setCropDocShow(true);


	return (
		<div id="registration" >
			{isSpinner ? <Spinner /> : ''}
			<form method="post" onSubmit={registration} >
				<div className="row w-100 mx-0">
					<div className="col-lg-12 mx-auto">
						<Form.Group className="" controlId="formBasicEmail" >
							<Form.Label>Name <span className="mandatory">*</span>(Text and space are allowed)</Form.Label>
							<Form.Control type="text" placeholder="Enter Name" name="name" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.name}</span>
						</Form.Group>
						<Form.Group className="mt-3 p-0" controlId="formBasicEmail">
							<Form.Label>Last Name (Text and space are allowed)</Form.Label>
							<Form.Control type="text" placeholder="Enter Last Name" name="lastname" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.lastname}</span>
						</Form.Group>
						<Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
							<Form.Label>Contact Number <span className="mandatory">*</span>(10 digit number)</Form.Label>
							<Form.Control type="text" pattern="[0-9]+" title="please enter number only" minlength="10" maxlength="10" placeholder="Contact Number" name="contact" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.contact}</span>
						</Form.Group>
						<Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
							<Form.Label>Whatsapp Number <span className="mandatory">*</span>(10 digit number)</Form.Label>
							<Form.Control type="text" pattern="[0-9]+" title="please enter number only" minlength="10" maxlength="10" placeholder="Whatsapp Number" name="whatsapp" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.whatsapp}</span>
						</Form.Group>
						<Row className="mb-3">
							<Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
								<Form.Label>Date of Birth <span className="mandatory">*</span></Form.Label>
								{/* <Form.Control type="date" placeholder="Date of Birth" name="date_of_birth" onChange={(e) => formChange(e)} /> */}
								<DatePicker dateFormat="dd/MM/yyyy" placeholderText="Date of Birth" selected={inputForm.date_of_birth} onChange={(date, e) => dateofbirthDateChange(date)} />
								<span className='mandatory'>{errors.date_of_birth}</span>
							</Form.Group>
							<Form.Group as={Col} className="mt-3 p-0" controlId="formBasicPassword">
								<Form.Label>Date of Marriage</Form.Label>
								{/* <Form.Control type="date" placeholder="Date of Marriage" name="date_of_marriage" onChange={(e) => formChange(e)} /> */}
								<DatePicker dateFormat="dd/MM/yyyy" placeholderText="Date of Marriage" selected={inputForm.date_of_marriage} onChange={(date, e) => dateofmarriageDateChange(date)} />

							</Form.Group>

						</Row>

						<Form.Group className="mt-3 p-0" controlId="file">

							<Form.Label>Profile Picture<span className="mandatory">*</span></Form.Label>

							{/* <ImageUpload ref={upload_Ref} />  */}
							<div className="imageUpload" onClick={handleCropShow}>   {profileImage ? <img src={profileImage} /> : <i className="icon"></i>}  </div>
							<span className='mandatory'>{errors.upload_Ref}</span>



						</Form.Group>
					</div>
					<div className="col-lg-12 mx-auto">
						<Form.Group as={Col} controlId="formGridState">
							<Form.Label>Blood Group <span className="mandatory">*</span></Form.Label>
							<Form.Select name="blood_group" onChange={(e) => formChange(e)}>
								<option value="none" selected>Select Blood Group</option>
								<option value="A +ve">A +ve</option>
								<option value="A -ve">A -ve</option>
								<option value="B +ve">B +ve</option>
								<option value="B -ve">B -ve</option>
								<option value="AB +ve">AB +ve</option>
								<option value="AB -ve">AB -ve</option>
								<option value="O +ve">O +ve</option>
								<option value="O -ve">O -ve</option>
							</Form.Select>
							<span className='mandatory'>{errors.blood_group}</span>
						</Form.Group>
						<Row className="mt-3 p-0">
							<Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
								<Form.Label>Willing for blood donation? <span className="mandatory">*</span></Form.Label>
							</Form.Group>
							<Form.Group as={Col}>
								<div className='ex_service'>
									<Form.Check type="radio" label="No" value="no" id="custom-switch1" name="blood_donation" onChange={(e) => formChange(e)} />
									<Form.Check type="radio" label="Yes" value="yes" id="custom-switch2" name="blood_donation" onChange={(e) => formChange(e)} />
								</div>
								<span className='mandatory'>{errors.blood_donation}</span>
							</Form.Group>

						</Row>
						{/* <Row className="mt-3">
                <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Currently in Service?<span className="mandatory">*</span></Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
					<div className='ex_service'>
						<Form.Check type="radio" label="no" id="custom-switch1" value="no" name="in_Service" onChange={(e) => formChange(e)} />
						<Form.Check type="radio" label="yes" id="custom-switch2" value="yes" name="in_Service" onChange={(e) => formChange(e)} />
					</div>
					<span className='mandatory'>{errors.in_Service}</span>
				</Form.Group>

              </Row> */}
						<Row className="mt-3">
							<Form.Group as={Col} className="mb-3 p-0" controlId="formBasicPassword">
								<Form.Label>Ex-ServiceMan?<span className="mandatory">*</span></Form.Label>
							</Form.Group>
							<Form.Group as={Col}>
								<div className='ex_service'>
									<Form.Check type="radio" label="no" id="custom-switch1" value="no" name="ex_ServiceMan" onChange={(e) => formChange(e)} />
									<Form.Check type="radio" label="yes" id="custom-switch2" value="yes" name="ex_ServiceMan" onChange={(e) => formChange(e)} />
								</div>
								<span className='mandatory'>{errors.ex_ServiceMan}</span>
							</Form.Group>

						</Row>
						{/* *********************Occupation*********************/}

						<Form.Group as={Col} className="mb-3 p-0" controlId="formBasicPassword">
							<Form.Label>Occupation <span className="mandatory">*</span></Form.Label>
							<Form.Control type="text" placeholder="Current Occupation" name="occupation" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.occupation}</span>
						</Form.Group>

						{/* *********************in service?*********************/}

						<Form.Group className="mt-2 p-0" controlId="formBasicPassword">
							<Form.Label>Address <span className="mandatory">*</span>(Text and space are allowed)</Form.Label>
							<Form.Control type="text" placeholder="House Name" name="address" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.address}</span>
						</Form.Group>

						<Row className="mt-3 p-0">

							<Form.Group as={Col} controlId="formGridCity">
								<Form.Label>Area <span className="mandatory">*</span></Form.Label>
								<Form.Control placeholder="Area" name="area" onChange={(e) => formChange(e)} />
								<span className='mandatory'>{errors.area}</span>
							</Form.Group>
							<Form.Group as={Col} className="mb-3 p-0" controlId="formGridCity">
								<Form.Label>District <span className="mandatory">*</span></Form.Label>
								<Form.Select className="mb-3" name="district" onChange={(e) => formChange(e)} >
									<option value="none" selected>Select District</option>
									<option value="kozhikode">kozhikode</option>
								</Form.Select>
								<span className='mandatory'>{errors.district}</span>
							</Form.Group>

						</Row>

						<Row className="mt-3 p-0">
							<Form.Group as={Col} controlId="formGridCity">
								<Form.Label>Post <span className="mandatory">*</span></Form.Label>
								<Form.Control placeholder="Post" name="post" onChange={(e) => formChange(e)} />
								<span className='mandatory'>{errors.post}</span>
							</Form.Group>
							<Form.Group as={Col} controlId="formGridZip">
								<Form.Label >Pin <span className="mandatory">*</span></Form.Label>
								<Form.Control type="text" pattern="[0-9]+" title="please enter number only" minlength="6" maxlength="6" placeholder="Pin" name="pin" onChange={(e) => formChange(e)} />
								<span className='mandatory'>{errors.pin}</span>
							</Form.Group>


						</Row>

					</div>

					<div className="col-lg-12 mx-auto">
						<Form.Group className="mt-3 p-0" controlId="formGridCity">
							<Form.Label>Select Group</Form.Label>
							<Form.Select defaultValue="Choose..." className="mb-3" name="group_refId" onChange={(e) => formChange(e)} >
								<option value="none" selected>Select Group</option>
								{

									data ? data.group_list.map((items) => {
										return (<option value={items._id}>{items.title} </option>);
									}) : ''
								}

							</Form.Select>

						</Form.Group>


						<Form.Group className="mt-3 p-0" controlId="formGridCity">
							<Form.Label>Verification Document Details<span className="mandatory">*</span></Form.Label>
							<Form.Select defaultValue="Choose..." className="mb-3" name="verificationtype" onChange={(e) => formChange(e)} >
								<option value="none" selected>Select Document</option>
								<option value="Passport">Passport </option>
								<option value="Aadhaar">Aadhaar</option>
								<option value="Driving Licence">Driving Licence</option>
								<option value="Bank Passbook">Bank Passbook</option>
							</Form.Select>
							<span className='mandatory'>{errors.verificationtype}</span>
						</Form.Group>
						<Form.Group className="mb-3 p-0" controlId="file">
							<div className="imageUpload doc" onClick={handleCropDocShow}>   {docImage ? <img src={docImage} /> : <i className="icon"></i>}  </div>
							{/* <ImageUpload ref={doc_upload_Ref} /> */}
							<span className='mandatory'>{errors.doc_upload_Ref}</span>
						</Form.Group>
						{/**************Email ID and Password****************/}
						<Form.Group as={Col} className="p-0" controlId="formBasicPassword">
							<Form.Label>Email Id <span className="mandatory">*</span></Form.Label>
							<Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.email}</span>
						</Form.Group>

						<Form.Group className="mt-3 p-0" controlId="formBasicEmail">
							<Form.Label>Password <span className="mandatory">*</span>(Must be more than 8 digit -15 ) eg-"Abcd123&"</Form.Label>
							<Form.Control type="password" minlength="8" placeholder="Enter password" name="hash" onChange={(e) => formChange(e)} />
							<span className='mandatory'>{errors.hash}</span>
						</Form.Group>

					</div>
					<div className="col-lg-12">
						{/**************Family Details****************/}
						<Form.Label className="">Add Family Members  <i onClick={() => handleAdd()} class="fas fa-plus-circle mt-3"></i></Form.Label>

						{fields.map((field, idx) => {
							return (
								<div key={`${field}-${idx}`}>
									<Row className="col family ">
										<Form.Group className="col-lg-12" as={Col} controlId="formGridCity">
											<Form.Label>Name</Form.Label>
											<Form.Control name="name" placeholder="Name" onChange={e => handleChange(idx, e)} />
										</Form.Group>
										<Form.Group className="col-lg-12" as={Col} controlId="formGridState">
											<Form.Label>Relation</Form.Label>
											<Form.Select name="relation" defaultValue="Choose..." onChange={e => handleChangeSelect(idx, e)}>
												<option value="none" selected>Select Relation</option>
												<option value="Wife" selected>Wife</option>
												<option value="Husband"  >Husband</option>
												<option value="Father" >Father</option>
												<option value="Mother" >Mother</option>
												<option value="Brother"  >Brother</option>
												<option value="Sister"  >Sister</option>
												<option value="Son"  >Son</option>
												<option value="Daughter" >Daughter</option>
												<option value="Grand Mother" >Grand Mother</option>
												<option value="Grand Father" >Grand Father</option>
											</Form.Select>

										</Form.Group>


										<Form.Group className="col-lg-12" as={Col} controlId="formGridState">
											<Form.Label>Blood Group <span className="mandatory">*</span></Form.Label>
											<Form.Select name="blood_group" onChange={e => handleChangeSelect(idx, e)}>
												<option value="A+ve">A +ve</option>
												<option value="A-ve">A -ve</option>
												<option value="B+ve">B +ve</option>
												<option value="B-ve">B -ve</option>
												<option value="AB+ve">AB +ve</option>
												<option value="AB-ve">AB -ve</option>
												<option value="O+ve">O +ve</option>
												<option value="O-ve">O -ve</option>
											</Form.Select>

										</Form.Group>
										<Form.Group className="col-lg-12" as={Col} controlId="formGridState">
											<Form.Label>Date Of Birth <span className="mandatory">*</span></Form.Label>

											<DatePicker dateFormat="dd/MM/yyyy" placeholderText="Click to select a date" selected={fields[idx].date_of_birth} onChange={(date, e) => familyDateChange(idx, e, date)} />
										</Form.Group>



										<i onClick={() => handleRemove(idx)} class="fas fa-trash-alt"></i>
									</Row>
								</div>
							);
						})}

					</div>



				</div>

				<div className="col-lg-12">
					<input type="submit" className=" register mt-3 btn btn-primary" value="Register" />


				</div>


			</form>

			<Modal show={showCrop} onHide={handleCropClose}>
				<Modal.Header closeButton>
					<Modal.Title>Update Profile</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<input type="file" onChange={onFileChange} />
					<Cropper
						style={{ height: 282, width: 450 }}
						aspectRatio={4 / 6}

						guides={false}
						src={srcCrop}
						onInitialized={(instance) => {
							setCropper(instance);
						}}
						viewMode={1}
						dragMode="move"
						cropBoxMovable={false}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCropClose}>
						Apply
					</Button>

				</Modal.Footer>
			</Modal>



			<Modal show={docShow} onHide={handleCropClose}>
				<Modal.Header closeButton>
					<Modal.Title>Update Profile</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<input type="file" onChange={onDocFileChange} />
					<Cropper
						style={{ height: 282, width: 450 }}
						aspectRatio={4 / 6}

						guides={false}
						src={srcDocCrop}
						onInitialized={(instance) => {
							setDocCropper(instance);
						}}
						viewMode={1}
						dragMode="move"
						cropBoxMovable={false}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleDocCropClose}>
						Apply
					</Button>

				</Modal.Footer>
			</Modal>










		</div>
	)
})

export default Create





const fakeAuth = {
    isAuthenticated: false,
    authenticate(cb) {
        this.isAuthenticated = cb
    },
    signout(cb) {
        this.isAuthenticated = cb

    }
}
export default fakeAuth

import React from 'react'
import eventimg from '../assets/img/chil.PNG'
import DateSpan from './date'
import { Link } from 'react-router-dom'
import Img from '../shared/img'
export default function Event_list({ title, _id, body, assetManagement,  start_date,createdAt }) {
    return (
        
        <div className="col-12 col-sm-6 col-md-4" >
        <div className='event-box'>
            <div className='title'>
                <div className='date'>  <span className='label'><DateSpan dat={ createdAt} type='DD' /> </span>
                    <span className='date-month'> <DateSpan dat={ createdAt} type='MM | YYYY' /> </span>
                </div>

                 <Link className="nav-link" to={"news_details/"+_id}> <h6>{title} </h6>  </Link> 

                {/* <h6>{title}</h6> */}
            </div>
             <div className='row'>
            <div>
            <Img src={assetManagement?assetManagement.filename:''} width={547} height={500}  />  
            </div>

             <div>
           <p>{body}</p>
            </div>
</div>
            
           
        </div>
    </div>
    //     <div className="col-3 col-sm-3 col-md-3" >
    //     <div className='event-row-items-box'>
    //         <img src={eventimg} className="d-block w-100" alt="..." />
    //         <h5>Lorem ipsum dolor sit amet, consectetur </h5>
    //         <p>
    //             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur
    //             sunt excepturi dicta ex, placeat ab esse, iure harum eaque fuga asperiores
    //             distinctio amet temporibus enim illum molestiae
    //         </p>
    //         <div className='footer-area'>
    //             <span className='created-by'> November 12, 2021</span>
    //             <span className='more'> <a href='#'> More </a></span>
    //         </div>
    //     </div>
    // </div>
    )
}



    
import React, { Fragment, useState } from "react";
import { CardContainor, Heading } from "../../../shared";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Spinner,
  Modal,
  Button,
  Alert,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import Add from "./Add";
import AclPermission from "../../../lib/acl";
const TESTIMONIAL_QUERY = gql`
  {
    testimonial_list(obj: { page: 0, skip: 0 }) {
      title
      body
      _id
      #designation
      status
    }
  }
`;

const TESTIMONIAL_DELETE_QUERY = gql`
  mutation testimonial_deleteById($id: ID!) {
    testimonial_deleteById(id: $id) {
      status
    }
  }
`;

const Testimonials = () => {
  const [deleteTestimonial] = useMutation(TESTIMONIAL_DELETE_QUERY);
  const { data, loading, error, refetch } = useQuery(TESTIMONIAL_QUERY);

  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({
    title: null,
    body: null,
  });
  const [isValid, setIsValid] = useState("");
  console.log();

  const handleDeleteClick = (_id) => {
    deleteTestimonial({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        refetch();
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
      });
  };

  const handleEdit = (obj) => {
    setId(_id);

    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
  };

  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    // {
    //   name: 'Designation',
    //   cell: (row) => <span> {row.designation}</span>
    //   ,
    //   sortable: true,
    // },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(_id) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      title: null,
    });

    setEditAble(false);
    setId(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  return (
    <div id="activityView">
      <div className="container tbl">
        {isValid ? <Alert variant="success">{isValid}</Alert> : ""}

        <Button className={"add-btn"} onClick={handleShow}>
          create
        </Button>

        <AdminPanel
          show={show}
          handleClose={(e) => {
            handleClose();
          }}
          title="create new Activity"
        >
          <Add editAble={editAble} obj={obj} id={_id} />
        </AdminPanel>

        <Heading title="Testimonial" />

        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={data ? data.testimonial_list : []}
        />
      </div>
    </div>
  );
};

export default Testimonials;

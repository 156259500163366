import React, { useEffect, Suspense } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useNavigate
} from "react-router-dom";
import fakeAuth from './fakeAuth';

function RouteWithSubRoutes(route) {
    let history = useNavigate();
    if (route.private == true) {
        if (fakeAuth.isAuthenticated == false) {
            history("/404")
        }
    }
    return (
        <Suspense fallback={<div>Loading...</div>}>


            <Route
                path={route.path}
                render={props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes} />
                )}
            />
        </Suspense>
    );
}


export default RouteWithSubRoutes;
import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Img,
  Heading,
  AdminPanel,
  Widget,
  Search,
  Export,
} from "../../../shared";
import {
  Button,
  Tab,
  Tabs,
  Alert,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import people from "../../../assets/img/people.png";
import "./style.scss";
import { assign } from "apollo-utilities";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import AclPermission from "../../../lib/acl";
const GROUP_QUERY = gql`
  {
    group_list {
      _id
      title
      adminrefId {
        name
        userId
        _id
      }
      memberfId {
        name
        userId
        _id
      }
    }
  }
`;
const GOAL_MUTATION = gql`
  mutation group_Create($title: String) {
    group_Create(obj: { title: $title }) {
      status
    }
  }
`;

const USERl_LIST_QUERY = gql`
  {
    profile_list {
      name
      lastname
      ex_ServiceMan
      in_Service
      date_of_birth
      blood_group
      blood_donation
      contact
      whatsapp
      address
      post
      family {
        name
        relation
        blood_group
      }
      pin
      district
      email
      subscribed
      verificationtype
      _id
      account_refId {
        action
        _id
        role {
          _id
          name
        }
      }
      area
      date_of_marriage
      occupation
      subscribed
      userId

      docFile {
        filename
      }
      assetManagement {
        filename
      }
    }
  }
`;

const UPATE_MUTATION = gql`
  mutation groupAdmin_updateById($id: String, $adminId: String) {
    groupAdmin_updateById(obj: { adminId: $adminId, id: $id }) {
      status
    }
  }
`;

const GROUP_ADMIN_MUTATION = gql`
  mutation groupAdmin_updateById($userId: String, $_id: String) {
    groupAdmin_updateById(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;

const GROUP_MEMBER_MUTATION = gql`
  mutation groupMember_updateById($userId: String, $_id: String) {
    groupMember_updateById(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;
const DELETE_GROUP_MUTATION = gql`
  mutation group_deleteById($id: ID!) {
    group_deleteById(id: $id) {
      status
    }
  }
`;

const DELETE_MEMBER_MUTATION = gql`
  mutation group_deleteByUser($userId: String, $_id: String) {
    group_deleteByUser(obj: { userId: $userId, _id: $_id }) {
      status
    }
  }
`;

export default function Group() {
  const [inputForm, setForm] = useState({});
  const [inputAdminForm, setAdminForm] = useState({});
  const [inputMemberForm, setMemberForm] = useState({});

  const [createGoal] = useMutation(GOAL_MUTATION);
  const [assinAdmin] = useMutation(GROUP_ADMIN_MUTATION);
  const [assinMember] = useMutation(GROUP_MEMBER_MUTATION);
  const [deleteGroupQ] = useMutation(DELETE_GROUP_MUTATION);

  const [deletememberQ] = useMutation(DELETE_MEMBER_MUTATION);

  //const [loadEntries, { data: groups }] = useLazyQuery(GROUP_QUERY);
  const { data: groups, refetch: loadEntries } = useQuery(GROUP_QUERY);
  const { data: userlist } = useQuery(USERl_LIST_QUERY);
  const [updateGoal] = useMutation(UPATE_MUTATION);
  const [acl, setAcl] = useState(false);
  const [acledit, setAcledit] = useState(false);
  const [isValid, setIsValid] = useState("");
  const [groupadmin, setGroupadmin] = useState(false);
  const [groupMember, setgroupMember] = useState(false);

  useEffect(() => {
    loadEntries();
  }, []);

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values);
  };
  let adminformChange = (event) => {
    const values = { ...inputAdminForm };
    values[event.target.name] = event.target.value;

    setAdminForm(values);
  };

  function registration(event) {
    event.preventDefault();
    createGoal({ variables: { ...inputForm } }).then((res) => {
      if (res.data.group_Create.status) {
        loadEntries();
        setIsValid("Created Successfully");
        close();
      }
    });
  }
  const close = () => {
    setAcl(false);
    setAcledit(false);
    setGroupadmin(false);
    setgroupMember(false);
  };

  function assignAdmin(id) {
    setAdminForm({ _id: id });
    setGroupadmin(true);
  }

  function submitassignAdmin(event) {
    event.preventDefault();
    assinAdmin({ variables: { ...inputAdminForm } }).then((res) => {
      if (res.data.groupAdmin_updateById.status) {
        loadEntries();
        setIsValid("Created Successfully");
        close();
      }
    });
  }

  let memberformChange = (event) => {
    const values = { ...inputMemberForm };
    values[event.target.name] = event.target.value;

    setMemberForm(values);
  };

  function assignMember(id) {
    setMemberForm({ _id: id });
    setgroupMember(true);
  }

  function submitassignMember(event) {
    event.preventDefault();
    debugger;
    assinMember({ variables: { ...inputMemberForm } }).then((res) => {
      if (res.data.groupMember_updateById.status) {
        loadEntries();
        setIsValid("Created Successfully");
        close();
      }
    });
  }
  function deleteGroup(id) {
    deleteGroupQ({ variables: { id: id } }).then((res) => {
      if (res.data.group_deleteById.status) {
        loadEntries();
        setIsValid("Created Successfully");
        close();
      }
    });
  }
  function deleteMember(id, userId) {
    deletememberQ({ variables: { userId: userId, _id: id } }).then((res) => {
      if (res.data.group_deleteByUser.status) {
        loadEntries();
        setIsValid("Created Successfully");
        close();
      }
    });
  }
  function redirect(id) {
    window.location.href = "/admin/groupsinfo/" + id;
  }

  const columns = [
    {
      name: "name",
      cell: (row) => <div> {row.title}</div>,
      sortable: true,
    },
    {
      name: "more",
      cell: (row) => (
        <a
          style={{ cursor: "pointer", color: "#007bff" }}
          onClick={(e) => redirect(row._id)}
        >
          {" "}
          more
        </a>
      ),
      sortable: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div className="trash" onClick={() => deleteGroup(row._id)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="groups">
      <AclPermission
        isActive={["admin", "President", "VP, Sec, jt Sec, treasurer"]} showMessage={false}
      >
        <AdminPanel
          show={acl}
          handleClose={(e) => {
            close();
          }}
          title="User Details"
        >
          <form method="post" onSubmit={(e) => registration(e)}>
            <Form.Group className="" controlId="formBasicEmail">
              <Form.Label>
                Goal Name <span className="mandatory">*</span>(Text and space
                are allowed)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Goal Name"
                name="title"
                onChange={(e) => formChange(e)}
              />
            </Form.Group>
            <input
              type="submit"
              className="add-btn me-2 btn btn-primary"
              value="Register"
            />
            {isValid}
          </form>
        </AdminPanel>
        <AdminPanel
          show={acledit}
          handleClose={(e) => {
            close();
          }}
          title="User Details"
        >
          update adnd delete
        </AdminPanel>
        <div className=" tbl">
          <button
            onClick={() => {
              setAcl(true);
            }}
            className=" btn btn-primary"
          >
            {" "}
            Create New Group
          </button>
          <Heading title="Group" />
          <div className="group_list">
            <DataTable
              title="Group List"
              pagination
              highlightOnHover
              columns={columns}
              data={groups ? groups.group_list : []}
            />
            {/* <ul>
            <li className='headers'>
              <div className='group_img'> </div>
              <div className='group_name'> Group Name</div>
              <div className='group_admin'> Group Admin</div>
              <div className='group_members' > Group Members</div>
            </li>
            {

              groups ? groups.group_list.map((items, key) => {

                return (
                  <li className='list'>
                    <div className='group_img'><img src={people} /> <button onClick={() => deleteGroup(items._id)}> <i class="fa fa-trash" aria-hidden="true"></i></button> </div>
                    <div className='group_name'><Link className='userPf' to={"/admin/groupsinfo/" + items._id}> {items.title} ---------  </Link> </div> 
                    <div className='group_admin'>{items.adminrefId ? items.adminrefId.userId : ''}<button onClick={() =>{assignAdmin(items._id)}} className='add_btn'>add</button></div>
                    <div className='group_members' >

                      {
                        items.memberfId ? items.memberfId.map((itemss, key) => {

                          return (
                            <span> {itemss.userId} <button className='delete' onClick={() =>{deleteMember(items._id,itemss._id)}}>X</button> </span>  
                          )
                        }) : null
                      }


                      <button className='add_btn' onClick={() =>{assignMember(items._id)}}>add</button>
                      </div>
                      
                  </li>
                )
              }) : null
            }
          </ul>  */}
          </div>
        </div>

        <Modal show={groupadmin} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title>Group Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form method="post">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>
                  Blood Group <span className="mandatory">*</span>
                </Form.Label>
                <Form.Select name="userId" onChange={(e) => adminformChange(e)}>
                  {userlist
                    ? userlist.profile_list.map((items, key) => {
                        return items.name != "Admin" ? (
                          <option value={items._id}>{items.name}</option>
                        ) : null;
                      })
                    : null}
                </Form.Select>
              </Form.Group>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => submitassignAdmin(e)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={groupMember} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form method="post">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>
                  Blood Group <span className="mandatory">*</span>
                </Form.Label>
                <Form.Select
                  name="userId"
                  onChange={(e) => memberformChange(e)}
                >
                  gfdgdf
                </Form.Select>
              </Form.Group>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => submitassignMember(e)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AclPermission>
    </div>
  );
}

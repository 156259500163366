import React, { useState, useEffect } from "react";
import {
  Button,
  Tab,
  Tabs,
  Alert,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";

function AdminPanel(props) {
  let { className } = props;
  // const [show, setShow] = useState(false);

  // const [sucess, setSucess] = useState(false);

  const handleClose = () => {
    props.handleClose(false);

    // setShow(false);
    // setSucess(false);
  };

  // const handleShow = () => setShow(true);
  /*  useEffect(() => {
    props.status? setShow(true): setShow(false)
      }, [ props.status]);
      */

  return (
    <div className="let-popup admin-panel">
      <Modal
        className={className + " modal right fade adminpanel"}
        show={props.show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </div>
  );
}

export default AdminPanel;

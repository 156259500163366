import React from 'react'
import { Link } from 'react-router-dom';
import Img from './img'
import DateSpan from './date'

//props.className=null?"col-3 col-sm-3 col-md-3":props.className
export default function News_list({className,obj}) {
 
    return (
        <div className= {className}  >
          
        <div className='news-box me-2'>
              <Img src={obj.assetManagement?obj.assetManagement.filename:''} width={580} height={800} />
        </div>
    </div>

    )
}

import React from "react";

export default function Shipping() {
  return (
    <>
      <section>
        <div className="ship">
          <h1>Shipping and delivery</h1>
          <p>we are didt offer any Shipping and delivery</p>
        </div>
      </section>
    </>
  );
}

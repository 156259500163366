
import {USER_ACTIVE } from '../action'
const initialState = {
    isLoading: false,
    isLogedin:false
 };
 const user = (state = initialState, action) => {
    switch (action.type) {
       case USER_ACTIVE:
         return { ...state, isLogedin: action.payload}
       default:
          return state;
    }
 }
 export default user;
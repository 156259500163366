

import React from 'react'
import './style.scss';
import Profile from '../../assets/img/profile.png';
import { useQuery, gql } from "@apollo/client";
import './style.scss';
import {Img } from '../../shared'
const GROUP_QUERY = gql`
  {
    administration_list{
      title
      role
      designation
      assetManagement{
        filename
      }
    }
  }
`;
export default function Administration() {

  const { data, loading, error, refetch } = useQuery(GROUP_QUERY);
  
  let list=[]
  if(data){
    list=data.administration_list
  }

  let administrative_members = list.filter(function (items) {
    return items.role === "AdministrativeMembers";
}).map(function (items) {
    return (
      <div className="col-6 col-sm-6 col-md-2" >
      <div className='banner-col'>
      <Img class='imgg' src={items.assetManagement?items.assetManagement.filename:null} /> 
      <p>{items.title}</p>
      <p>{items.designation}</p>
    </div> </div>
    )
})



let trustee_Board_Members = list.filter(function (items) {
  return items.role === "Trusteeboardmembers";
}).map(function (items) {
  return (
    <div className="col-6 col-sm-6 col-md-2" >
       <div className='banner-col'>
 <Img class='imgg' src={items.assetManagement?items.assetManagement.filename:null} /> 
    <p>{items.title}</p></div>
  </div>
  )
});


let council_members = list.filter(function (items) {
  return items.role === "Councilmembers";
}).map(function (items) {
  return (
    <div className="col-6 col-sm-6 col-md-2" >
      <div className=' banner-col'>

 
     <Img class='imgg' src={items.assetManagement?items.assetManagement.filename:null}  width={300} height={300} /> 
    <p>{items.title}</p>     </div>
  </div>
  )
});




    // const news=data?data.news_list.map((data,id)=>{
    //   return <News_list key={id} obj={data} className={"col-6 col-sm-6 col-md-6"}  />
    // }):'null'


    return (
       <div id="Administration">
       
           
               {/* <div className="adminis-banner">
      <h1>Administration</h1>
    </div> */}

{/* **********************Members container************************ */}
    <div className="container">
    <h1 > Calicut Defence Trust & care Administration Team </h1>
{/* *************Admini Members *********** */}
<div className=" heading ">
    <p>Administrative Members</p>
</div>
<div>
          <div className="row">
            {administrative_members}
           
          
           </div>
</div>
{/* *************End************* */}


   <div className="col-sm-3 heading mb-4">
      <p>Trustee Board Members</p>
    </div>

       <div className="row">
           {trustee_Board_Members}
       </div>

    <div className="col-sm-3 heading mb-4">
        <p>Council Members</p>
    </div>
       <div className="row">
            {council_members}
       </div>


</div>
</div>
    )
}
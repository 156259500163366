
import React, { forwardRef, useImperativeHandle, useState, Fragment, useEffect, Suspense, lazy, useRef } from 'react';
import { useQuery, gql } from "@apollo/client";
import { Spinner, Tabs, Tab, Modal, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import AdminPanel from '../../../shared/adminpanel';
import { Heading } from '../../../shared';

import './style.scss'
import AssetList from './AssetList';
//const GalleryCard = lazy(() => import('./gallery_Card'));

//const Add = lazy(() => import('./Add'))

const GALLERY_LIST_QUERY = gql`
{
    list_Images{
        title
  
        type
        _id
        assetManagement {
          filename
          path
        }
      }
    
  }
`;


function Assets() {


  const { data, loading, error, refetch } = useQuery(GALLERY_LIST_QUERY, {

  });


  const Asset = data ? data.list_Images.map((data, id) => {
    return <AssetList refetch={e => { refetch() }} key={id} obj={data} />
  }) : 'null'

  return (
    <div id="assets">
      <div className=' tbl'>
        <Heading title="Assets" />
        <div className="row">
          {Asset}
        </div>
        <div />
      </div>
    </div>
  )
}

export default Assets
import { React, useState } from 'react'
import { Form, FieldGroup, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './style.scss'
import { useQuery, gql, useMutation } from "@apollo/client";
import { useSelector, useDispatch } from 'react-redux'
import { USER_ACTIVE, USER_ROLE } from '../../redux/action'
import fakeAuth from '../../fakeAuth';
const LOGIN_QUERY = gql`
mutation siginIn($hash:String $email:String){
  siginIn(obj:{hash:$hash,email:$email}){
    status
    message,
    role{
      name
    }
  }

  
}

`;




function Login() {
  const dispatch = useDispatch()
  const [reqQuery, { loading, error }] = useMutation(LOGIN_QUERY);
  const [isValid, setIsValid] = useState(false);
  const [fields, setFields] = useState({
    email: null,
    hash: null
  })
  let history = useNavigate();
  let loginBtn = () => {

    reqQuery({ variables: { ...fields } }).then(res => {

      if (res.data.siginIn.status) {
        fakeAuth.authenticate(true)
        dispatch({ type: USER_ACTIVE, payload: true })
        localStorage.setItem('isActive', true)
        dispatch({ type: USER_ROLE, payload: res.data.siginIn.role })
        if (res.data.siginIn.role) {
          res.data.siginIn.role.forEach(element => {
            if (element.name != "gust") {
              // localStorage.setItem('isrole','admin')
            }

          });
        }
        localStorage.setItem('AUTH_TOKEN', res.data.siginIn.message);
        history('/profile')
        window.location.reload();
      }
      else {
        setIsValid(true);
      }
    })

  }
  let handleChange = (event) => {
    const values = { ...fields };
    values[event.target.name] = event.target.value;
    setFields(values)

  }
  return (
    <div id="login">

      {isValid ? <Alert variant="danger">Login Failed</Alert> : ""}
      <div className="d-flex align-items-center auth px-0 mt-5">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => handleChange(e)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" name="hash" onChange={(e) => handleChange(e)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              {/* <Form.Check type="checkbox" label="Check me out" /> */}
            </Form.Group>
            <div class="ctdc-btn">
              <Button className="login-btn" type="submit" onClick={loginBtn} >

                LOGIN
              </Button>
            </div>


            <div className='footer-area'>
              <Link className='forgot' to="/forgot-password">forgot password </Link>
              <Link className='registration' to="/registration">Registration</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

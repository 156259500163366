import React, { useState } from 'react';
import './profile.scss';
import { Button, Tab, Tabs, Alert, Modal,Form,  Row, Col, } from 'react-bootstrap';
import {Img,Rrazopay,RrazopayUserSubscripction}from '../../shared'
import paisa from 'paisa.js';
function Subscribed() {
   
      
    return (
        <div className='Subscribed'>
            
          <h3> Complete your MemberShip Payment</h3>

                <RrazopayUserSubscripction validity="1"  type="MemberShip" amountt={65000} />
                  
        </div>
    );
}

export default Subscribed
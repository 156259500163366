import React, { useState, useRef } from "react";
import "./profile.scss";
import { Button, Tab, Tabs, Alert, Modal } from "react-bootstrap";
import Profileimg from "../../assets/img/profile.jpg";
import {
  Img,
  Rrazopay,
  DateSpan,
  RrazopayUserSubscripction,
  AdminPanel,
} from "../../shared";
import { useQuery, gql, useMutation,useLazyQuery } from "@apollo/client";
import paymentimg from "../../assets/img/Payment.png";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Subscrib from "./subscrib";
import Donate from "./donate";
import { useSelector, useDispatch } from "react-redux";
import { USER_PROFILE } from "../../redux/action";
import moment from "moment";
import Subscribed from "./subscribed";
import paisa from "paisa.js";
import Invoice from "../invoice/index";
import AclPermission from "../../lib/acl";
import ChangePassword from "./changePassword";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // see installation section above for versions of NPM older than 3.0.0
import axios from "axios";
import UpdateProfile from "./updateProfile";
import { useEffect } from "react";
const USER_QUERY = gql`
  query {
    profile_view {
      name
      lastname
      ex_ServiceMan
      in_Service
      date_of_birth
      blood_group
      blood_donation
      contact
      whatsapp
      address
      post
      family {
        name
        relation
        blood_group
        date_of_birth
      }
      pin
      district
      email
      subscribed
      verificationtype
      _id
      account_refId {
        email
        action
        _id
        role {
          _id
          name
        }
      }
      area
      date_of_marriage
      occupation
      subscribed
      userId

      docFile {
        filename
        _id
      }
      assetManagement {
        filename
        _id
      }
      group_refId{
        title
  
      }


      membership_start_date
      membership_end_date
      membership_validity
    

    }
  }
`;

const UPDATE_PROFILE_IMAGE_MUTATION = gql`
  mutation profile_update_profile_image($_id: String, $file: String) {
    profile_update_profile_image(obj: { _id: $_id, file: $file }) {
      status
    }
  }
`;
// const DONATION_HISTORY_QUERY = gql`
//   {
//     donationManage_list{
//       amount
//       createdAt
//       _id
//     }
//   }
// `;

// const MEMBERSHIP_HISTORY_QUERY = gql`
//   {
//     membership_list{
//       amount
//       createdAt
//     }
//   }
// `;
const PAYMENT_QUERY = gql`
  {
    getHistoryByUser {
      orderCreationId
      razorpayPaymentId
      amount
      type
      validity
      _id
      createdAt
      month
    }
  }
`;

function Profile() {
  const [groupName, setGroupName] = useState(null);
  const [obj, setData] = useState([]);
  const [loadEntries] = useLazyQuery(USER_QUERY)
  //const { history, loading, error, status, refetch } = useQuery(MEMBERSHIP_HISTORY_QUERY);
  const { data: payment } = useQuery(PAYMENT_QUERY)


  // let obj = data ? data.profile_view : {};



  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [updateprofile, setUpdateprofile] = useState(false);

  const columns = [
    {
      name: "createdAt",
      cell: (row) => moment(row.month).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Validity",
      selector: (row) => row.validity,
    },

    {
      name: "orderCreationId",
      cell: (row) => row.orderCreationId,
      sortable: true,
    },

    {
      name: "razorpayPaymentId",
      selector: (row) => row.razorpayPaymentId,
    },
    {
      name: "Type",
      selector: (row) => <div className={row.type}> {row.type}</div>,
    },
    {
      name: "Invoice",
      cell: (row) => (
        <Link to={"/invoice/" + row._id}>
          <i class="fas fa-file-invoice"></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect( () => {
    console.log("ddddd")
    setTimeout(() => {
      console.log("wwww")
   loadEntries().then(res=>{
      let nobj = res.data ? res.data.profile_view : {};
      logic(nobj)
      // setData(obj)
      // if(obj){
      //   localStorage.setItem('userId',obj._id),
      //   localStorage.setItem('Role',JSON.stringify(obj?.account_refId?.role))
    
      //   if(obj?.group_refId){
      //     localStorage.setItem('group',obj?.group_refId[0]?.title)
      //     setGroupName(obj?.group_refId[0]?.title)
      //   }
    
      // //  localStorage.setItem('group',obj?.group_refId[0]?.title)
    
        
      // }

    });

  }, 1000);
  
  }, []);


  let logic=(obj)=>{
   // setTimeout(() => {
      if(obj){
      
        localStorage.setItem('userId',obj._id)
        if(obj.account_refId){
         localStorage.setItem('Role',JSON.stringify(obj.account_refId.role))
        }
 
        if(obj?.group_refId){
         if(obj?.group_refId.length>0){
          localStorage.setItem('group',obj.group_refId[0].title)
          setGroupName(obj.group_refId[0].title)
         }
        }
    
        setData(obj)
        
      }
   // }, 1000);

   
   }



  const handleEdit = () => {
    setShow(true);
    setEditAble(true);
  };
  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  const handleCropShow = () => setCropShow(true);

  /****
   *
   * Corop
   */
  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE_MUTATION);
  const [cropper, setCropper] = useState();
  const [srcCrop, setSrc] = useState();
  const [showCrop, setCropShow] = useState(false);
  const [profileImage, setProfile] = useState();

  const handleCropClose = () => {
    // if (cropper.cropped) {
    //     setProfile(cropper.getCroppedCanvas().toDataURL())

    // }

    setCropShow(false);
  };

  const handleCropprevew = () => {
    if (cropper.cropped) {
      setProfile(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const getCropData = () => {
    if (cropper.cropped) {
      setProfile(cropper.getCroppedCanvas().toDataURL());

      let ob = {
        _id: obj._id,
        file: cropper.getCroppedCanvas().toDataURL(),
      };

      updateProfileImage({ variables: { ...ob } }).then((res) => {
        if (res.data.profile_update_profile_image.status) {
          alert("file upload successfully");
          setCropShow(false);
        }
      });
    }
  };

  const updateProfileData = () => {
    setUpdateprofile(true);
  };

  return (
    <AclPermission isActive={["all"]}>
      <div className="profile_info">
        {obj?.subscribed === "no" ? <Subscribed /> : ""}
        <div class="container">
          <button
            className="btn btn-primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit()}
          >
            {" "}
            changePassword
          </button>
          <button
            className="btn btn-primary"
            onClick={() => updateProfileData()}
          >
            {" "}
            update Profile
          </button>


          <div style={{float:"right",display:"flex" ,    paddingTop: "6px"}}> Roles: {
          obj?.account_refId?.role?.map(res=>{
            return(
              <span style={{
                display: "table",
                padding: "3px 11px",
                backgroundColor: "#427744",
                borderRadius:" 5px",
                textTansform: "capitalize",
                color: "#fff",
                margin:"0px 5px"
              }}> {res.name}</span>
            )
          })
          
          }</div> <br></br>
  
          {groupName?<div style={{float:"right",display:"flex" ,    paddingTop: "6px"}}> Group Admin:   <span style={{
                display: "table",
                padding: "3px 11px",
                backgroundColor: "#427744",
                borderRadius:" 5px",
                textTansform: "capitalize",
                color: "#fff",
                margin:"0px 5px"
              }}> {groupName}</span></div> :""}
          <div className="info">
            <AdminPanel
              show={show}
              handleClose={(e) => {
                handleClose();
              }}
              title="User Details"
            >
              <ChangePassword />
              {/* <Update obj={obj} edit={editAble} refetch={e => { refetch() }} handleClose={(e) => { handleClose() }} />
               */}
            </AdminPanel>

            <AdminPanel
              show={updateprofile}
              handleClose={(e) => {
                setUpdateprofile(false);
              }}
              title="updateprofile"
            >
              <UpdateProfile
                obj={obj}
                refetch={() => loadEntries()}
                handleClose={() => setUpdateprofile(false)}
              />
            </AdminPanel>
            <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                <div className="user-profile-ingo">
                   <h5>{obj?.userId} </h5> 
                  <h6>
                    Membership Renew Date <br />
                  </h6>{" "}
                  <span>
                    {moment(obj.membership_end_date).format("DD-MM-YYYY")}
                  </span>
                </div>

                <div class="user-profile">
                  <div className="edit" onClick={handleCropShow}>
                    {" "}
                  </div>
                  <div class="user-avatar">
                    {profileImage ? (
                      <img src={profileImage} />
                    ) : (
                      <Img
                        src={
                          obj.assetManagement
                            ? obj.assetManagement.filename
                            : null
                        }
                        alt="CALICUT DEFENCE"
                      />
                    )}
                  </div>
                </div>

                <div className="right-profile">
                  <div>
                    {" "}
                    {/* <Subscrib /> */}
                    <Donate />
                  </div>

                  <img src={paymentimg} alt="payment" />
                </div>
              </div>
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <ul className="profile-info">
                  <li>
                    <label> Name</label>
                    <span>
                      {obj.name} {obj.lastname}
                    </span>
                  </li>
                  <li>
                    <label> Contact</label>
                    <span> {obj.contact} </span>
                  </li>

                  <li>
                    <label> Whatsapp</label>
                    <span>{obj.whatsapp} </span>
                  </li>
                  <li>
                    <label> Date Of Birth</label>
                    <span>
                      {" "}
                      {moment(obj.date_of_birth).format("DD-MM-YYYY")}{" "}
                    </span>
                  </li>
                  <li>
                    <label> Date of Marriage</label>
                    <span>
                      {" "}
                      {moment(obj.date_of_marriage).format("DD-MM-YYYY")}{" "}
                    </span>
                  </li>
                  <li>
                    <label> Email</label>
                    <span>
                      {obj.account_refId ? obj.account_refId.email : ""}{" "}
                    </span>
                  </li>
                  <li>
                    <label> Blood</label>
                    <span> {obj.blood_group} </span>
                  </li>
                  <li>
                    <label> Blood Donation?</label>
                    <span> {obj.blood_donation}</span>
                  </li>
                  <li>
                    <label> In Service?</label>
                    <span> {obj.in_Service}</span>
                  </li>
                  <li>
                    <label> Ex-ServiceMan?</label>
                    <span> {obj.ex_ServiceMan} </span>
                  </li>
                  <li>
                    <label> Occupation</label>
                    <span> {obj.occupation} </span>
                  </li>

                  <li>
                    <label> Address</label>
                    <span> {obj.address}</span>
                  </li>
                  <li>
                    <label> Area</label>
                    <span>{obj.area}</span>
                  </li>
                  <li>
                    <label> Post</label>
                    <span>{obj.post} </span>
                  </li>
                  <li>
                    <label> District</label>
                    <span> {obj.district}</span>
                  </li>
                  <li>
                    <label> Pin</label>
                    <span> {obj.pin} </span>
                  </li>
                  {obj.family
                    ? obj.family.map((val, key) => {
                        return (
                          <li>
                            <label> {val.relation}</label>
                            <span> {val.name} </span>
                            <span>{val.blood_group} </span>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div className="history">
            <div className="">
              <DataTable
                pagination
                highlightOnHover
                columns={columns}
                data={payment ? payment.getHistoryByUser : []}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showCrop} onHide={handleCropClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={onFileChange} />
          <Cropper
            style={{ height: 282, width: 450 }}
            aspectRatio={4 / 6}
            guides={false}
            src={srcCrop}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            viewMode={1}
            dragMode="move"
            cropBoxMovable={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCropprevew}>
            preview
          </Button>
          <Button onClick={getCropData}>Save</Button>
        </Modal.Footer>
      </Modal>
    </AclPermission>
  );
}

export default Profile;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./style.scss";
import { Provider } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import store from "./redux/store/store";
import "./lib/translation/i18n";
import { createUploadLink } from "apollo-upload-client";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from 'react-ga';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//const uploadLink = createUploadLink({ uri: "http://139.59.7.246/graphql"});
//const uploadLink = createUploadLink({ uri: "https://www.calicutdefence.com/graphql"});
//const uploadLink = createUploadLink({ uri: "http://localhost:4000/graphql" });
const link = new HttpLink({
 //uri: "http://localhost:4000/graphql",
  uri: "https://www.calicutdefence.com/graphql",
  // Additional options
});

ReactGA.initialize('G-975V2EEMVJ');
ReactGA.pageview(window.location.pathname + window.location.search);
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("AUTH_TOKEN");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link), //ApolloLink.from([httpLink,authLink,uploadLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();


import React from 'react'
import moment from 'moment'


export default function DateSpan({dat,type}) {
    return (

        <span>{moment(dat).format( type)}</span>
   
    )
}

import React, { forwardRef, useState, Fragment, useEffect, Suspense, lazy, useRef, useImperativeHandle } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './registration.scss'
import { Img, ImageUpload } from '../../shared';
import { useQuery, gql, useMutation } from "@apollo/client";


const SIGIN_MUTATION = gql`
  mutation siginUp(
  $email: String!
	$hash: String!
  $file: Upload
  $name: String!
  $lastname: String!
  $date_of_birth: String!
  $blood_group: String!
  $blood_donation: Boolean
  $ex_ServiceMan: String!
  $contact: String!
  $whatsapp: String!
  $address: String!
  $post: String!
  $pin: String!
  $area: String!
  $date_of_marriage: String!
  $occupation: String!
  $district: String!
  $docfile: Upload
  $family: JSON
  $verificationtype: String!
    ) {
      siginUp(obj:{
        email: $email,
        hash: $hash,
        file: $file,
        name: $name,
        lastname: $lastname,
        date_of_birth: $date_of_birth,
        blood_group: $blood_group,
        blood_donation: $blood_donation,
        district:$district,
        ex_ServiceMan: $ex_ServiceMan,
        contact: $contact,
        whatsapp: $whatsapp,
        address: $address,
        post: $post,
        pin: $pin,
        area: $area,
        date_of_marriage: $date_of_marriage 
        occupation: $occupation
        docfile:$docfile
        family:$family
        verificationtype:$verificationtype
    }) {
        status
    }
  }
`;


function AddDetails() {
  const upload_Ref = useRef();
  const doc_upload_Ref = useRef();

  const [profile_create, { loading, error }] = useMutation(SIGIN_MUTATION);
  const [fields, setFields] = useState([{ name: null, value: null }])
  const [readonly, setEdit] = useState(true);
  const [isValid, setIsValid] = useState(false);

  const [inputForm, setForm] = useState({
    name: null,
    lastname: null,
    email: null,
    hash: null,
    date_of_birth: null,
    blood_group: null,
    blood_donation: null,
    ex_ServiceMan: null,
    contact: null,
    whatsapp: null,
    address: null,
    district: null,
    post: null,
    pin: null,
    date_of_marriage: null,
    area: null,
    occupation: null

  })

  let history = useNavigate();

  let registration = () => {
    const file = upload_Ref.current.getSelected()
    const docfile = doc_upload_Ref.current.getSelected()
    const values = { ...inputForm };
    values.blood_donation = Boolean(values.blood_donation)

    values['family'] = fields;
    values["file"] = file;
    values["docfile"] = docfile;



    profile_create({ variables: { ...values } }).then(res => {

      if (res.data.siginUp.status) {
        history('/success')
      }


    })




  }
  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values)

  }

  function handleAdd() {
    const values = [...fields];
    values.push({ name: null, value: null });
    setFields(values);
  }
  function handleChange(i, event) {
    const values = [...fields];
    values[i].name = event.target.value;
    setFields(values);
  }

  function handleChangeSelect(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }
  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }


  return (
    <div id="registration">
      {/* {isValid ? <Alert variant="danger">Please check given data</Alert> : "" } */}
      <div className="row w-100 mx-0">

        <div className="col-lg-4 mx-auto">

          <Form.Label >Add Family Members <span className="mandatory">*</span></Form.Label>
          <i onClick={() => handleAdd()} class="fas fa-plus-circle"></i>
          {fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Row className="col">
                  <Form.Group as={Col} onChange={e => handleChange(idx, e)} controlId="formGridCity">
                    <Form.Label>Name</Form.Label>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Relation</Form.Label>
                    <Form.Select defaultValue="Choose..." onChange={e => handleChangeSelect(idx, e)}>
                      <option value="none" selected>Select Relation</option>
                      <option value="Wife" selected>Wife</option>
                      <option value="Husband"  >Husband</option>
                      <option value="Father" >Father</option>
                      <option value="Mother" >Mother</option>
                      <option value="Son"  >Son</option>
                      <option value="Daughter" >Daughter</option>
                      <option value="Grand Mother" >Grand Mother</option>
                      <option value="Grand Father" >Grand Father</option>
                    </Form.Select>
                    <i onClick={() => handleRemove(idx)} class="fas fa-trash-alt"></i>
                  </Form.Group>

                </Row>
              </div>
            );
          })}

          <Button type="submit" className=" register mb-5" onClick={registration} >
            Add Details
          </Button>
        </div> </div>
    </div>
  )
}

export default AddDetails
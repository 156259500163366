import React, { forwardRef, useState, useRef, useEffect } from 'react'
import { gql, useMutation } from "@apollo/client";
import { ImageUpload, Heading, InputTextPrompt, Img } from '../../../shared';
import { Button, Alert, Col, Form } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TESTIMONIAL_MUTATION = gql`
  mutation testimonial_create(
    $title: String!
  	$body: String!
    #$designation: String!
    ) {
		testimonial_create(obj:{
        title: $title,
		body: $body,
        #designation: $designation,
    }) {
        status
    }
  }
`;

const TESTIMONIAL_BY_UPDATE_MUTATION = gql`
  mutation testimonial_updateById(
    $_id:String!
    $title: String!
  	$body: String!
    #$designation: String!
    ) {
        testimonial_updateById(obj:{
        title: $title,
		body: $body,
        #designation: $designation,
        _id: $_id,
    }) {
        status
    }
  }
`;


const Add = forwardRef((props, ref) => {

  const [createTestimonial, { loading, error }] = useMutation(TESTIMONIAL_MUTATION);
  const [UpdateTestimonial] = useMutation(TESTIMONIAL_BY_UPDATE_MUTATION);

  const [isValid, setIsValid] = useState('')
  const [inputForm, setForm] = useState({

    title: props.obj.title?props.obj.title:null,
    body: props.obj.body?props.obj.body:null,
    //designation: props.obj.designation?props.obj.designation:null,
  })


  const submitForm = () => {

    const values = { ...inputForm };
  
    if
      (
      !values.title ||
      !values.body 
      //!values.designation
    ) {
      return (
        setIsValid('Please Check All The Field')
      );
    }
    else {
      setIsValid('');
    }

    if (props.editAble) {

      values._id = props.obj.id
      UpdateTestimonial({ variables: { ...values } }).then(res => {

        setIsValid('Created Successfully');
      })
    }
    else {

        createTestimonial({ variables: { ...values } }).then(res => {

        setIsValid('Created Successfully');
      })
    }
setTimeout(()=>{
    setIsValid('')
},2000)

  }

  let formChange = (event) => {
    const values = { ...inputForm };
    values[event.target.name] = event.target.value;
    setForm(values)
  }

  return (

    <div id="donation_add">

      <div className="row">

        <div className="col-sm-12">
          <Form.Group className="mb-3" controlId="formBasicEmail" >
            <Form.Label>Title <span className="mandatory">*</span></Form.Label>
            <Form.Control type="text" value={inputForm.title} placeholder="Enter Title" name="title" onChange={(e) => formChange(e)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail" >
            <Form.Label>Content <span className="mandatory">*</span></Form.Label>
            <textarea type="text" value={inputForm.body} placeholder="Enter Name" name="body" rows="8" onChange={(e) => formChange(e)} />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicEmail" >
            <Form.Label>Designation <span className="mandatory">*</span></Form.Label>
            <Form.Control type="text" value={inputForm.designation} placeholder="Designation" name="designation" onChange={(e) => formChange(e)} />
          </Form.Group> */}
          <Button varient="primary" onClick={(e) => submitForm()}>Save</Button>
          {isValid ? <Alert className="mt-3" variant="success">{isValid}</Alert> : ""}
        </div></div>
    </div>

  )
})

export default Add



import React from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { Img, Rrazopay } from '../../shared';

const DONATION_QUERY = gql`
  {
    donations_list(obj:{page:3  skip:0}){
      title
      body
      amount
      assetManagement{
        filename
      }
    }
  }
`;
export default function DonationList() {
    const { data, loading, error, refetch } = useQuery(DONATION_QUERY);


    if (!data) {
        return null
    }
    if (!data.donations_list) {
        return null
    }
    //console.log(data.donations_list)
    let { title,body, amount,assetManagement}=  data.donations_list.length>0?data.donations_list[0]:{title:null,body:null, amount:null,assetManagement:{filename:null}}
    return (
        <section className='donation-list'>
             <div className="container">
                <div className="row ">
                    <div className="col-12 col-sm-12 col-md-6 donation-img" >
                        <Img src={assetManagement?assetManagement.filename:null}   width={636} height={636} /> 
                  
                    </div>
                    <div className="col-12 col-sm-12 col-md-6" >
                        <div className='donation-container'>
                            <h6>  {title}</h6>
                            <p>{body} </p>
                                <div className='bar'>
                                <span> <i className="fas fa-hand-holding-heart"></i> </span>
                                <div>
                                <span className='Rupee '></span>
                                    <span className='amount'> {amount}</span>
                                    <span className='labal'> please donate </span>
                                </div>
                                <span>
                                <Rrazopay type="Donation" amountt={amount*100} />
                                     
                                     </span>
                            </div>
                        </div>
                    </div>

                </div>

            </div> 
        </section>
    )
}

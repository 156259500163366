import React, { Fragment, useState } from "react";
import { CardContainor, Heading } from "../../../shared";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Spinner,
  Modal,
  Button,
  Alert,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import Add from "./Add";
import AclPermission from "../../../lib/acl";
import Delete from "../../../assets/img/trash.svg";
import "./style.scss";
const ACTIVITIES_QUERY = gql`
  {
    activity_list(obj: { page: 0, skip: 0 }) {
      title
      body
      meta_keyword
      meta_description
      seo_title
      _id
      createdAt
      status
      assetManagement {
        filename
      }
    }
  }
`;

const ACTIVITIES_DELETE_QUERY = gql`
  mutation activity_deleteById($id: ID!) {
    activity_deleteById(id: $id) {
      status
    }
  }
`;

const AdminActivities = () => {
  const [deleteActivities] = useMutation(ACTIVITIES_DELETE_QUERY);
  const { data, loading, error, refetch } = useQuery(ACTIVITIES_QUERY);

  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({
    title: null,
    body: null,
    seo_title: null,
    meta_description: null,
    meta_keyword: null,
    files: null,
  });
  const [isValid, setIsValid] = useState("");

  console.log(data);
  // const histrory = useNavigate ()
  // if (loading) return <Spinner animation="border" size="sm" />;
  // if (error) return `Error! ${error.message}`;

  const handleDeleteClick = (_id) => {
    deleteActivities({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        refetch();
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
      });
  };

  const handleEdit = (obj) => {
    setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };

  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    {
      name: "createdAt",
      cell: (row) => <span> {row.createdAt}</span>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "More",
      cell: (row) => (
        <button className="add-btn btn btn-primary">
          <Link to={"/admin/activitylist/" + row._id}>More</Link>
        </button>
      ),
      sortable: false,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(_id) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      seo_title: null,
      meta_description: null,
      meta_keyword: null,
      files: null,
    });

    setEditAble(false);
    setId(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  return (
    <AclPermission showMessage={false}
      isActive={["admin", "President", "Media Cell", "Trusty Borad"]}  
    >
      <div id="activityView">
        {isValid ? <Alert variant="success">{isValid}</Alert> : ""}

        <AdminPanel
          show={show}
          handleClose={(e) => {
            handleClose();
          }}
          title="create new Activity"
        >
          <Add
            editAble={editAble}
            obj={obj}
            id={_id}
            refetch={(e) => {
              refetch();
            }}
            handleClose={(e) => {
              handleClose();
            }}
          />
        </AdminPanel>

        <Heading title="Activities" />
        <Button className={"add-btn"} onClick={handleShow}>
          create
        </Button>
        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={data ? data.activity_list : []}
        />
      </div>
    </AclPermission>
  );
};

export default AdminActivities;

/*

<DropdownButton className={row.status ? 'status published' : 'status not-published'}
    onSelect={handleSelect}
        title={row.status ? 'published' : 'not-published'}
      >
        <Dropdown.Item eventKey="3" active>published</Dropdown.Item>
        <Dropdown.Item eventKey="1">not-published</Dropdown.Item>
        
  
      </DropdownButton>,\
      */

import React, { useState } from "react";
import "./profile.scss";
import { Button, Tab, Tabs, Alert } from "react-bootstrap";
//import Profileimg from '../../assets/img/profile.jpg';
import {
  Img,
  Rrazopay,
  DateSpan,
  RrazopayUserSubscripction,
} from "../../../shared";
import { useQuery, gql } from "@apollo/client";
import paymentimg from "../../../assets/img/Payment.png";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { USER_PROFILE } from "../../../redux/action";
import moment from "moment";
import paisa from "paisa.js";

const USER_QUERY = gql`
  query profile_view_id($id: ID!) {
    profile_view_id(id: $id) {
      name
      lastname
      ex_ServiceMan
      in_Service
      date_of_birth
      blood_group
      blood_donation
      contact
      whatsapp
      address
      post
      family {
        name
        relation
        blood_group
      }
      pin
      district
      email
      subscribed
      verificationtype
      _id
      account_refId {
        email
        action
        _id
        role {
          _id
          name
        }
      }
      area
      date_of_marriage
      occupation
      subscribed
      userId

      docFile {
        filename
      }
      assetManagement {
        filename
      }
      membership_start_date
      membership_end_date
      membership_validity
    }
  }
`;

// const DONATION_HISTORY_QUERY = gql`
//   {
//     donationManage_list{
//       amount
//       createdAt
//       _id
//     }
//   }
// `;

// const MEMBERSHIP_HISTORY_QUERY = gql`
//   {
//     membership_list{
//       amount
//       createdAt
//     }
//   }
// `;
const PAYMENT_QUERY = gql`
  query getHistoryByUserById($id: ID!) {
    getHistoryByUserById(id: $id) {
      orderCreationId
      razorpayPaymentId
      amount
      type
      validity
      _id
      createdAt
    }
  }
`;

function AdminProfile() {
  const { id } = useParams();
  const { data, loading, error, refetch } = useQuery(USER_QUERY, {
    variables: { id: id },
  });
  const { data: payment } = useQuery(PAYMENT_QUERY, { variables: { id: id } });

  console.log(data);

  let obj = data ? data.profile_view_id : {};

  const columns = [
    {
      name: "createdAt",
      cell: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Validity",
      selector: (row) => row.validity,
    },

    {
      name: "orderCreationId",
      cell: (row) => row.orderCreationId,
      sortable: true,
    },

    {
      name: "razorpayPaymentId",
      selector: (row) => row.razorpayPaymentId,
    },
    {
      name: "Type",
      selector: (row) => <div className={row.type}> {row.type}</div>,
    },
    {
      name: "Invoice",
      cell: (row) => (
        <Link to={"./invoice/" + row._id}>
          <i class="fas fa-file-invoice"></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="profile_info">
      <div className="info">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
            <div className="user-profile-ingo">
              <h5>{obj.userId} </h5>
              <h6>
                Membership Renew Date <br />
              </h6>{" "}
              <span>
                {moment(obj.membership_end_date).format("DD-MM-YYYY")}
              </span>
            </div>

            <div class="user-profile">
              <div class="user-avatar">
                <Img
                  src={
                    obj.assetManagement ? obj.assetManagement.filename : null
                  }
                  alt="CALICUT DEFENCE"
                />
              </div>
            </div>
            <div className="right-profile">
              <img src={paymentimg} alt="payment" />
            </div>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
            <ul className="profile-info">
              <li>
                <label> Name</label>
                <span>
                  {obj.name} {obj.lastname}
                </span>
              </li>
              <li>
                <label> Contact</label>
                <span> {obj.contact} </span>
              </li>

              <li>
                <label> Whatsapp</label>
                <span>{obj.whatsapp} </span>
              </li>
              <li>
                <label> Date Of Birth</label>
                <span> {obj.date_of_birth} </span>
              </li>
              <li>
                <label> Date of Marriage</label>
                <span>{obj.date_of_marriage} </span>
              </li>
              <li>
                <label> Email</label>
                <span>{obj.account_refId ? obj.account_refId.email : ""} </span>
              </li>
              <li>
                <label> Blood</label>
                <span> {obj.blood_group} </span>
              </li>
              <li>
                <label> Blood Donation?</label>
                <span> {obj.blood_donation}</span>
              </li>
              <li>
                <label> In Service?</label>
                <span> {obj.in_Service}</span>
              </li>
              <li>
                <label> Ex-ServiceMan?</label>
                <span> {obj.ex_ServiceMan} </span>
              </li>
              <li>
                <label> Occupation</label>
                <span> {obj.occupation} </span>
              </li>

              <li>
                <label> Address</label>
                <span> {obj.address}</span>
              </li>
              <li>
                <label> Area</label>
                <span>{obj.area}</span>
              </li>
              <li>
                <label> Post</label>
                <span>{obj.post} </span>
              </li>
              <li>
                <label> District</label>
                <span> {obj.district}</span>
              </li>
              <li>
                <label> Pin</label>
                <span> {obj.pin} </span>
              </li>
              {obj.family
                ? obj.family.map((val, key) => {
                    return (
                      <li>
                        <label> {val.relation}</label>
                        <span> {val.name} </span>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
        </div>
      </div>

      <div className="history">
        <div className="">
          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={payment ? payment.getHistoryByUserById : []}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;

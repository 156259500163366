import React from 'react'
import { Link } from 'react-router-dom';
import bannar1 from '../../assets/img/Banner_01.png';
import bannar2 from '../../assets/img/Banner_02.png';
import bannar3 from '../../assets/img/Banner_03.png';
import bannar4 from '../../assets/img/Banner_04.png';

export default function Slide() {
  return (

    <section id="slide">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div> */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={bannar1} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <div className="container">
                {/* <label>Some representative placeholder content for the first slide.</label> */}
                <h5>Service to  nation is our duty <br/>
                And Service to people is our <br/>   Happiness</h5>
                <p>The real soldier fights not because he hates what's in front of him, but because he loves what's behind him</p>
                <Link to="/about">Read more </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={bannar2} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <div className="container">
              <h5>Charity is Willingly<br/> Given From the Heart</h5>
                <p>We don’t need Superman, Spiderman, and Batman, we have our own super hero’s Indian army They gave their todays to your tomorrow’s</p>
                <Link to="/about">Read more </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={bannar3} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <div className="container">
              <h5>The mindgrows by<br/> Taking in, but the<br/> heart Grows<br/> by giving out</h5>
              <p>Real Hero’s don’t have a name on the back of their jersey, they wear their countries Flag</p>
                <Link to="/about">Read more </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={bannar4} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <div className="container">
              <h5>Be present in all Things<br/> and thankful<br/> For all Things</h5>
              <p>Our Flag does not fly because the wind moves it, it flies with the last breadth of each soldier who died to protecting it</p>
                <Link to="/about">Read more </Link>
              </div>
            </div>
          </div>
        </div>
        
        <div className='btns'>
        <div className="container">
          <button className="prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      </div>
 
    </section>
  )
}

import React, { Fragment, useState, useEffect } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Heading } from "../../../shared";
import AdminPanel from "../../../shared/adminpanel";
import { Spinner, Modal, Button, Alert } from "react-bootstrap";
import AclPermission from "../../../lib/acl";
import Add from "./Add";
import "./style.scss";
const NEWS_QUERY = gql`
  {
    news_list(obj: { page: 0, skip: 0 }) {
      title
      body
      meta_keyword
      meta_description
      seo_title
      _id
      createdAt
      status
      assetManagement {
        filename
      }
    }
  }
`;

const NEWS_DELETE_MUTATION = gql`
  mutation news_deleteById($id: ID!) {
    news_deleteById(id: $id) {
      status
    }
  }
`;

const AdminNews = (props, ref) => {
  //   const { data, loading, error, refetch } = useQuery(NEWS_QUERY);
  //   const [deleteNews] = useMutation(NEWS_DELETE_MUTATION);
  //   const histrory = useNavigate ()
  //   if (loading) return <Spinner animation="border" size="sm" />;
  //   if (error) return `Error! ${error.message}`;

  // console.log(data);

  // const handleDeleteClick = (_id) => {
  //   console.log(_id);
  //   deleteNews({variables:{id:_id}}).then(res=>{
  //     refetch()
  // })

  // }

  const [deleteNews] = useMutation(NEWS_DELETE_MUTATION);
  //const { data, loading, error, refetch } = useLazyQuery(NEWS_QUERY);
  //const [refetch, { loading, error, data }] = useLazyQuery(NEWS_QUERY);
  const { data, refetch } = useQuery(NEWS_QUERY);

  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({
    title: null,
    body: null,
    seo_title: null,
    meta_description: null,
    meta_keyword: null,
    files: null,
  });
  const [isValid, setIsValid] = useState("");

  // const histrory = useNavigate ()
  // if (loading) return <Spinner animation="border" size="sm" />;
  // if (error) return `Error! ${error.message}`;

  useEffect(() => {
    refetch();
  }, []);

  const handleDeleteClick = (_id) => {
    deleteNews({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        refetch();
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
      });
  };

  const handleEdit = (obj) => {
    //setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };

  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    {
      name: "createdAt",
      cell: (row) => <span> {row.createdAt}</span>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(_id) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      seo_title: null,
      meta_description: null,
      meta_keyword: null,
      files: null,
    });

    setEditAble(false);
    setId(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };
  return (
    <div id="news">
      <AclPermission isActive={["admin", "President", "Media Cell"]} showMessage={false}>
        {isValid ? <Alert variant="success">{isValid}</Alert> : null}

        <Button className={"add-btn"} onClick={handleShow}>
          create
        </Button>

        <AdminPanel
          show={show}
          handleClose={(e) => {
            handleClose();
          }}
          title="create News"
        >
          <Add
            editAble={editAble}
            obj={obj}
            refetch={(e) => {
              refetch();
            }}
            handleClose={(e) => {
              handleClose();
            }}
          />
        </AdminPanel>
        <Heading title="News Details" />
        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={data ? data.news_list : []}
        />
      </AclPermission>
    </div>
  );
};

export default AdminNews;

import React from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { Activity_list, Advt,Img } from '../../shared';
import './style.scss';

const ACTIVITIES_QUERY = gql`
  {
    activity_list(obj:{page:0  skip:0}){
      title
      body
      createdAt
      _id
      assetManagement{
        filename
      }
    }
  }
`;
const ADS_QUERY = gql`
  {
    ads_list_byuser(obj:{page:10  skip:0,place:"activity_left"}){
      title
      _id
      assetManagement{
        filename
      }
    
    }
  }
`;

export default function Activity() {
  const { data: ads } = useQuery(ADS_QUERY);
  let advt = [];
  if (ads) {
  
      if (ads.ads_list_byuser) {
          advt = ads.ads_list_byuser.map((item, key) => {
              return (
                  <Img src={item.assetManagement?item.assetManagement.filename:''} width={300} height={300}  alt="CALICUT DEFENCE" key={key} />
              )
          })

      }
  }

  const { data, loading, error, refetch } = useQuery(ACTIVITIES_QUERY);


  let list = data ? data.activity_list.map((res, key) => <Activity_list {...res} key={key} />) : 'Loading'

  return (
    <section id='activity'>
      <div className="container">
        <h1> activity
        </h1>
        <div className="row">
          <div className='col-12 col-sm-12 col-md-9'>
            <div className="row activity-row">
              {list}
            </div>
          </div>
          <div className='col-0 col-sm-0 col-md-3 left-col'>
          {advt}
          </div>
        </div>
      </div>

    </section>
  )
}

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { useQuery, gql } from "@apollo/client";
import { Img, Video } from "../../shared";
import "react-image-lightbox/style.css";
import Gallery_el from "./gallery_el";
import "./style.scss";
import FsLightbox from "fslightbox-react";
const GALLERY_LIST_QUERY = gql`
  {
    list_Images {
      type
      url
      imageDate
      assetManagement {
        filename
      }
    }
  }
`;

const GALLERY_FOLDER_LIST = gql`
  {
    getFolderList {
      title
      folderYear
      id
    }
  }
`;

export default function Gallery() {
  const { data, loading, error, refetch } = useQuery(GALLERY_FOLDER_LIST);
  const [selected, setselected] = useState(Number(new Date().getUTCFullYear()));

  const [galleryl, setgalleryl] = useState(new Map());
  useEffect(() => {
    if (data) {
      let folderMap = new Map();
      for (let index = 0; index < data.getFolderList.length; index++) {
        let element = data.getFolderList[index];
        let year = new Date(element.folderYear || "2022-01-01").getFullYear();

        let getdata = folderMap.get(year);
        if (getdata) {
          setgalleryl(new Map(galleryl.set(year, [...getdata, element])));
          folderMap.set(year, [...getdata, element]);
        } else {
          folderMap.set(year, [element]);
          setgalleryl(new Map(galleryl.set(year, [element])));
        }
      }
    }
  }, [data]);

  let folderList = Array.from([...galleryl.keys()]).sort((a, b) => b - a);

  let selecetDate = (event) => {
    setselected(Number(event.target.value));
  };

  return (
    <>
      <div id="folder">
        <div className="container">
          <h1 className="gallery-title">
            Gallery{" "}
            <div className="years">
              {/* <label for="cars">years</label> */}
              <select name="cars" onChange={(E) => selecetDate(E)}>
                {folderList
                  ? folderList.map((items) => {
                      return <option value={items}>{items}</option>;
                    })
                  : ""}
              </select>
            </div>
          </h1>
          <>
            <h5>{selected}</h5>
            <div className=" row row-cols-1 row-cols-md-4 g-4">
              <>
                {galleryl.get(selected)?.map((item, key) => {
                  return (
                    <div class="col">
                      <Link to={"../gallerylist/" + item.id}>
                        <div class="card">
                          <div className="folderIcon" />
                          <div class="card-body">
                            <h5 class="card-title">{item.title}</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </>

              {/* 
               {folderMap
                ? folderMap.get(selected).map((item, key) => {
                    return (
                      <div class="col">
                        <Link to={"../gallerylist/" + item.id}>
                          <div class="card">
                            <div className="folderIcon" />
                            <div class="card-body">
                              <h5 class="card-title">{item.title}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                : ""}  */}
            </div>
          </>
          {/* {folderList
            ? folderList.map((items) => {
                return (
                  <>
                    <h5>{items}</h5>
                    <div className=" row row-cols-1 row-cols-md-4 g-4">
                      {folderMap.get(items).map((item, key) => {
                        return (
                          <div class="col">
                            <Link to={"../gallerylist/" + item.id}>
                              <div class="card">
                                <div className="folderIcon" />
                                <div class="card-body">
                                  <h5 class="card-title">{item.title}</h5>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })
            : ""} */}
        </div>
      </div>
    </>
  );
}

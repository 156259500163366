import React, { Fragment, useState } from "react";
import { CardContainor, Heading } from "../../../shared";
import { useQuery, gql, useMutation } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Modal, Button, Alert } from "react-bootstrap";
import AdminPanel from "../../../shared/adminpanel";
import AclPermission from "../../../lib/acl";
import Add from "./Add";
import "./style.scss";
const LIST_QUERY = gql`
  {
    administration_list {
      title
      designation
      role
      status
      _id
      assetManagement {
        filename
      }
    }
  }
`;

const DELETE_QUERY = gql`
  mutation administration_deleteById($id: ID!) {
    administration_deleteById(id: $id) {
      status
    }
  }
`;

function AdministrationAdmin() {
  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [obj, setobj] = useState({
    title: null,
    designation: null,
    role: null,
    files: null,
    _id: null,
  });
  const [isValid, setIsValid] = useState("");
  const [deleteGroup] = useMutation(DELETE_QUERY);
  const { data, loading, error, refetch } = useQuery(LIST_QUERY);
  const histrory = useNavigate();
  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return `Error! ${error.message}`;

  console.log(data);
  const handleDeleteClick = (_id) => {
    deleteGroup({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted successfully");
      })
      .then((res) => {
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
        refetch();
      });
  };
  const handleEdit = (obj) => {
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
  };

  const handleShow = () => {
    setobj({
      title: null,
      role: null,
      files: null,
      designation: null,
    });

    setEditAble(false);
    setShow(true);
    console.log(obj);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    //change name to title
    {
      name: "Group",
      cell: (row) => <span>{row.role}</span>,
      sortable: true,
    },
    {
      name: "Designation",
      cell: (row) => <span>{row.designation}</span>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => <span> {row.status ? "published" : "not-published"}</span>,

      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="edit" onClick={(_id) => handleEdit(row)}></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div id="gropup">
      <AclPermission isActive={["admin", "President"]} showMessage={false}>
        <div className=" tbl">
          {isValid ? <Alert variant="success">{isValid}</Alert> : ""}
          <Button className={"add-btn"} onClick={handleShow}>
            create
          </Button>
          <AdminPanel
            show={show}
            handleClose={(e) => {
              handleClose();
            }}
            title="Add new Member"
          >
            <Add
              editAble={editAble}
              obj={obj}
              refetch={(e) => {
                refetch();
              }}
              handleClose={(e) => {
                handleClose();
              }}
            />
          </AdminPanel>
          <Heading title="Group Members" />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data ? data.administration_list : []}
          />
        </div>
      </AclPermission>
    </div>
  );
}

export default AdministrationAdmin;

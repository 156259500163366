import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  Fragment,
  useEffect,
  Suspense,
  lazy,
  useRef,
} from "react";
import Lightbox from "react-image-lightbox";
import { useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { useQuery, gql } from "@apollo/client";
import { Img, Video } from "../../shared";
import "react-image-lightbox/style.css";
import Gallery_el from "./gallery_el";
import "./style.scss";
import FsLightbox from "fslightbox-react";
const GALLERY_LIST_QUERY = gql`
  query listbyId($listImagesId: ID) {
    listbyId(id: $listImagesId) {
      type
      url
      title
      assetManagement {
        filename
      }
      createdAt
    }
  }
`;

export default function GalleryDetails() {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const [assets, setAssets] = useState([
    [
    
    ],
  ]);

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }
  const { id } = useParams();
  const { data, loading, error, refetch } = useQuery(GALLERY_LIST_QUERY, {
    variables: { listImagesId: id },
  });

  let asset = [];
  let images = data
    ? data.listbyId
        .filter((res) => {
          return res.type == "Image";
        })
        .map((items, key) => {
          asset.push(
            <Img
              key={key}
              src={items.assetManagement ? items.assetManagement.filename : ""}
              width={880}
              height={880}
            />
          );
          return (
            <div className="card col-12 col-sm-3 col-lg-3 col-md-3 col-lg-3">
              <a onClick={() => openLightboxOnSlide(key)}>
                <Img
                  key={key}
                  src={
                    items.assetManagement ? items.assetManagement.filename : ""
                  }
                  width={280}
                  height={280}
                />
              </a>
            </div>
          );
        })
    : [];

  let videos = data
    ? data.listbyId.filter((res) => {
        return res.type == "Video";
      })
    : [];

    function getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
  
      return (match && match[2].length === 11)
        ? match[2]
        : null;
  }



  return (
    <div id="gallery">
      {asset ? (
        <FsLightbox toggler={lightboxController.toggler} sources={asset} />
      ) : (
        ""
      )}

      <section>
        <div className="container ">
          <h1>Gallery</h1>

          <div className="row">{images}</div>

          <div className="row videos">
            {videos.map((items, key) => {
              return (<> 
                    
                    <iframe width="315" height="560"
                    src= {`https://www.youtube.com/embed/`+getId(items.url) } 
                    title={items.title}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>    
                    </>
                // <div dangerouslySetInnerHTML={{ __html: items.url }}></div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

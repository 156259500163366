import React from "react";

export default function Cancellation() {
  return (
    <>
      <section>
        <div className="cancell">
          <h1>Return and Cancellation Policy</h1>
          <p>please contact admin</p>
        </div>
      </section>
    </>
  );
}

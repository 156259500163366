import React, { useEffect } from 'react'
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import './style.scss'

const VALIDATION_MUTATION = gql`
mutation validateRegistraction(
    $token: String!
){
    validateRegistraction(obj:{
        token: $token
    })
    {
        status
        message
    }
}

`;


export default function AccountVerification() {
    const [accountValidation] = useMutation(VALIDATION_MUTATION);
    const { id } = useParams();

    useEffect(() => {
        if (id != 'null') {
            accountValidation({ variables: { token: id } })
        }
    },
        [])


    console.log(id);
    // accountValidation({variables:{token:id}})

    return (

        <div id="AccountVerification">
            <div className='icon'></div>
            <h2>Account  successfully Validated &  Please wait for Admin approval. </h2>
            {/* <p> please  check  your Email</p> */}
        </div>
    )
}

import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer class="ctdc">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-5">
              <div class="ctdc-head">
                <h3>CALICUT DEFENCE TRUST & CARE</h3>
                <p>Conglomerate of Armed Forces Excellence</p>
              </div>
              <div class="ctdc-paragraph">
                <p>Always with you</p>
              </div>
              <div class="ctdc-btn">
                {/* <button type="button">Donate Now</button> */}
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              {" "}
              <div class="connect-with-us">
                <h6>Connect With Us</h6>

                <p>
                  <a href="tel:9037215011">+91 90372 15011</a>
                </p>
                <p>
                  <a href="mailto:calicutdefenceinfo@gmail.com">
                    calicutdefenceinfo@gmail.com
                  </a>{" "}
                </p>
              </div>
              <div class="last-icons">
                <i class="fab fa-facebook-square"></i>
                <i class="fab fa-whatsapp-square"></i>
                <i class="fab fa-twitter-square"></i>
                <i class="fab fa-youtube"></i>
              </div>{" "}
            </div>
            <div class="col-12 col-sm-12 col-md-1">
              {" "}
              <div class="ctdc-third-colomn">
                <h6>Quick Links</h6>

                <Link to="home">Home </Link>

                <Link to="about">About </Link>

                <Link to="news">News </Link>

                <Link to="gallery">gallery </Link>

                <Link to="groups">Groups </Link>

                <Link to="contact">Contact </Link>

                <Link to="shipping">shipping </Link>
                <Link to="cancellation">cancellation </Link>
              </div>{" "}
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <div class="news-latter">
                <h6>News Letter</h6>
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
                <input type="text" placeholder="Your Email Addres" />

                <button type="button" class="news-latter-btn">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-portion">
          <ul>
            <li class="footer-nav-item">
              <Link to="terms">Terms of use</Link>
            </li>

            <li class="footer-nav-item">
              <Link to="policy">Privacy Environmental Policy</Link>
            </li>
            <li class="footer-nav-item">
              <p>Copyright@2021 All rights reserved </p>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;


import { alignment, defaultDataType } from 'export-xlsx';
import ExcelExport from 'export-xlsx';
import React from 'react'
import exportFromJSON  from 'export-from-json'
export default function Export({obj,fileName}) {
    const exportType = 'csv'

  
    let exportBtn=()=>{
      
      let data = [...obj]
        exportFromJSON({ data, fileName, exportType })

    }
    return (
<button className='export' onClick={()=>exportBtn()}></button>
    )
}

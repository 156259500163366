import React from 'react'
import './style.scss'
export default function PaymentSuccess() {
    return (
        <div id="Success">
            <div className='icon'></div>
       <h2>Payment successfully </h2>
       <p> please  check  your Email</p>
        </div>
    )
}

import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Button,
  Tab,
  Tabs,
  Alert,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { Heading, Widget, Export } from "../../../shared";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "./style.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FilterComponent from "./filter";
import AclPermission from "../../../lib/acl";
import ManulPayment from '../../../shared/manulPayment'
const PAYMENT_QUERY = gql`
  query getHistoryByadmin($startDate: String, $endDate: String) {
    getHistoryByadmin(obj: { startDate: $startDate, endDate: $endDate }) {
      orderCreationId
      razorpayPaymentId
      amount
      month
      type
      validity
      _id
      createdAt
      account_refId {
        email
      }
      profile_refId {
        _id
        userId
        name
      }
    }
  }
`;

const PaymentManagement = () => {
  const [filater, setfilter] = useState([]);
  const [total, setTotal] = useState({
    monthlytotal: 0,
    donation: 0,
    subscription: 0,
  });
  const [memberShip, seMemberShip] = useState(0);
  const [datess, setDate] = useState({
    firstDay: moment().clone().startOf("month").format("YYYY-MM-DD"),
    lastDay: moment().clone().endOf("month").format("YYYY-MM-DD"),
  });
  const [refetch, { loading, error, data }] = useLazyQuery(PAYMENT_QUERY);

  const [manulPaymnet, setManulPaymnet] = useState(false);

  useEffect(() => {
    refetchd(
      moment().clone().startOf("month").format("YYYY-MM-DD"),
      moment().clone().endOf("month").format("YYYY-MM-DD")
    );
    // refetch({
    //   variables: {
    //     startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    //     endDate: moment().clone().endOf("month").format("YYYY-MM-DD"),
    //   },
    // }).then((res) => {
    //   console.log(res.data.getHistoryByadmin);
    //   setfilter(res.data.getHistoryByadmin);
    // });
  }, []);
  // const { data, loading, error } = useQuery(
  //   PAYMENT_QUERY,

  //   {
  //     variables: { startDate: new Date(), endDate: new Date() },
  //     onCompleted: (data) => {

  //     },
  //   }
  // );
  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return `Error! ${error.message}`;

  const columns = [
    {
      name: "",
      cell: (row) => (
        <Link
          className="userPf"
          to={"/admin/userProfile/" + row.profile_refId?._id}
        >
          {" "}
          <i class="fa fa-user" aria-hidden="true"></i>
        </Link>
      ),
      sortable: true,
      width: 20,
    },

    {
      name: "createdAt",
      cell: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => <span> {row.profile_refId?.name} </span>,
    },
    {
      name: "User ID",
      selector: (row) => <span> {row.profile_refId?.userId} </span>,
    },
    {
      name: "Email",
      selector: (row) => <span> {row.account_refId?.email} </span>,
      width: 200,
    },
    {
      name: "month of sub",
      selector: (row) => <span> {row.month} </span>,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Validity",
      selector: (row) => <span> {row.validity} Month</span>,
    },

    {
      name: "orderCreationId",
      cell: (row) => row.orderCreationId,
      sortable: true,
    },

    {
      name: "razorpayPaymentId",
      selector: (row) => row.razorpayPaymentId,
    },
    {
      name: "Type",
      selector: (row) => <div className={row.type}> {row.type}</div>,
    },
    {
      name: "Invoice",
      cell: (row) => (
        <Link to={"/invoice/" + row._id}>
          <i class="fas fa-file-invoice"></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const refetchd = (firstDay, lastDay) => {
    refetch({
      variables: {
        startDate: firstDay,
        endDate: lastDay,
      },
    }).then((res) => {
      setfilter(res.data.getHistoryByadmin);
      widgetFilter(res.data.getHistoryByadmin);
    });
  };

  const widgetFilter = (arry) => {

    if (arry.length) {
      let total = [];
      let monthly = 0;
      let donation = [];
      let subscription = [];
      // arry.forEach((element) => {
      //   total.push(Number(element.amount.replace("₹", " ")));
      // });
      ///console.log(arry)

      arry.forEach((element) => {
        if (element.type === "monthly") {
          monthly += Math.trunc(Number(element.amount))
        }
      });

      // arry.forEach((element) => {
      //   if (element.type === "MemberShip") {
      //     subscription.push(Number(element.amount.replace("₹", " ")));
      //   }
      // });

      // arry.forEach((element) => {
      //   if (element.type === "Donation") {
      //     donation.push(Number(element.amount.replace("₹", " ")));
      //   }
      // });

     // let totals = total.reduce((a, b) => a + b);
      //let donations = donation.reduce((a, b) => a + b);
     // let subscriptions = subscription.reduce((a, b) => a + b);
      setTotal({
        monthlytotal: monthly,
      //  donation: donations,
      //  subscription: subscriptions,
      });
      //seMemberShip(members);
    }
  };

  const filterbyDate = () => {
    refetchd(datess.firstDay, datess.lastDay);
  };

  const dateChane = (d, type) => {
    const values = { ...datess };
    values[type] = moment(d).format("YYYY-MM-DD");
    setDate(values);
  };

  return (
    <div className="tbl" id="membership-Manage">
      <AclPermission
        isActive={[
          "admin",
          "President",
          "VP, Sec, jt Sec, treasurer",
          "Counsilor",
        ]} showMessage={false}
      >
        <Heading title="Payment Management" />
        <div className="row">
          <div className="col-md-3">
            <Widget title="Monthly Membership" value={total.monthlytotal} />
          </div>
          {/* <div className="col-md-3">
            <Widget title="Monthly Paid Members" value={memberShip} />
          </div>
          <div className="col-md-3">
            <Widget title="Monthly Donation" value={total.donation} />
          </div>
          <div className="col-md-3">
            <Widget title="Monthly subscription" value={total.subscription} />
          </div> */}
        </div>

        <div className="card mt-3">
          <div className="tableheader">
            <h4>
              <span>{datess.firstDay} </span>
              To
              <span>{datess.lastDay}</span>
            </h4>
            <div className="filterbyDate">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Date of Marriage"
                selected={new Date(datess.firstDay)}
                onChange={(date, e) => dateChane(date, "firstDay")}
              />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Date of Marriage"
                selected={new Date(datess.lastDay)}
                onChange={(date, e) => dateChane(date, "lastDay")}
              />
              <button className="btn btn-primary" onClick={filterbyDate}>
                {" "}
                Search
              </button>
            </div>
            <Export obj={filater} fileName="Members List" />

            <button type="button" class="add-btn btn btn-primary" onClick={()=>setManulPaymnet(true)}>
            Cash Paymnet
            </button>
          </div>

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={filater}
            //data={data ? data.getHistoryByadmin : []}
            //subHeaderComponent={subHeaderComponent}
          />

<Modal show={manulPaymnet} onHide={()=>setManulPaymnet(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Cash Paymnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>


      <ManulPayment amountt={20000}  validity={'2'}  type={'cash'}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>setManulPaymnet(false)}>
              Close
            </Button>
         
          </Modal.Footer>
        </Modal>

        </div>
      </AclPermission>
    </div>
  );
};

export default PaymentManagement;

import React, { Fragment, useState } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Heading, Img, AdminPanel } from "../../../shared";
import AclPermission from "../../../lib/acl";
import { Spinner, Modal, Button, Alert } from "react-bootstrap";
import Add from "./Add";
import "./style.scss";
const ADS_QUERY = gql`
  {
    ads_list {
      title
      _id
      assetManagement {
        filename
      }
    }
  }
`;

const ADS_DELETE_MUTATION = gql`
  mutation ads_deleteById($id: ID!) {
    ads_deleteById(id: $id) {
      status
    }
  }
`;

const Ads = (props, ref) => {
  const [deleteads] = useMutation(ADS_DELETE_MUTATION);
  const { data, loading, error, refetch } = useQuery(ADS_QUERY);

  const [editAble, setEditAble] = useState(false);
  const [show, setShow] = useState(false);
  const [_id, setId] = useState(null);
  const [obj, setobj] = useState({
    title: null,
    body: null,
    seo_title: null,
    meta_description: null,
    meta_keyword: null,
    files: null,
  });
  const [isValid, setIsValid] = useState("");

  // const histrory = useNavigate ()
  // if (loading) return <Spinner animation="border" size="sm" />;
  // if (error) return `Error! ${error.message}`;

  const handleDeleteClick = (_id) => {
    deleteads({ variables: { id: _id } })
      .then((res) => {
        setIsValid("Deleted Successfully");
      })
      .then((res) => {
        refetch();
        setTimeout(() => {
          setIsValid(null);
        }, 2000);
      });
  };

  const handleEdit = (obj) => {
    //setId(_id);
    // getViewData({ variables: { id: _id } }).then(res => {

    //if(res.data.activity_viewById){
    setobj({ ...obj });
    setShow(true);
    setEditAble(true);
    // }

    // })
  };

  const columns = [
    {
      name: "Title",
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
    },
    {
      name: "image",
      cell: (row) => (
        <span>
          {" "}
          <Img
            src={row.assetManagement ? row.assetManagement.filename : ""}
          />{" "}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Delete",
      cell: (row) => (
        <div
          className="trash"
          onClick={(_id) => handleDeleteClick(row._id)}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleShow = () => {
    setobj({
      title: null,
      body: null,
      seo_title: null,
      meta_description: null,
      meta_keyword: null,
      files: null,
    });

    setEditAble(false);
    setId(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditAble(false);
  };
  return (
    <div id="ads">
      <AclPermission isActive={["admin", "President"]} showMessage={false}>
        <div className="">
          {isValid ? <Alert variant="success">{isValid}</Alert> : null}

          <Button className={"add-btn"} onClick={handleShow}>
            create
          </Button>

          <AdminPanel
            show={show}
            handleClose={(e) => {
              handleClose();
            }}
            title="create ads"
          >
            <Add
              editAble={editAble}
              obj={obj}
              refetch={(e) => {
                refetch();
                handleClose();
              }}
              handleClose={(e) => {
                handleClose();
              }}
            />
          </AdminPanel>
          <Heading title="ads Details" />
          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data ? data.ads_list : []}
          />
        </div>
      </AclPermission>
    </div>
  );
};

export default Ads;

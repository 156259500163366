import React from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { Link } from 'react-router-dom';
import {News_list} from'../../shared';
import Slider from "react-slick";
import './style.scss'

// const NEWS_QUERY = gql`
//   {
//     news_list(obj:{page:3,  skip:0}){
//       title
//       body
//       createdAt
//       _id
//       assetManagement{
//         filename
//       }
//     }
//   }
// `;
const ACTIVITIES_QUERY = gql`
  {
    todayList{
      title
      body
      start_date
      end_date
      _id
      assetManagement{
        filename
      }
    }
  }
`;
const ACTIVITIES_PREV_QUERY = gql`
  {
    prevList{
      title
      body
      start_date
      end_date
      _id
      assetManagement{
        filename
      }
    }
  }
`;

const ACTIVITIES_UP_QUERY = gql`
  {
    upcoming{
      title
      body
      start_date
      end_date
      _id
      assetManagement{
        filename
      }
    }
  }
`;



export default function NewsItems() {
  const { data, loading, error, refetch } = useQuery(ACTIVITIES_QUERY);


  
  const { data:prv } = useQuery(ACTIVITIES_PREV_QUERY);
  const { data:up } = useQuery(ACTIVITIES_UP_QUERY);
  

      const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000
    };
  let newss=data?data.todayList:[];
  const today=newss.map((data,id)=>{
    return <News_list key={id} obj={data}  />
  })


  let prvs=prv?prv.prevList:[];
  const prva=prvs.map((data,id)=>{
    return <News_list key={id} obj={data}  />
  })
  
  let upa=up?up.upcoming:[];
  const uplista=upa.map((data,id)=>{
    return <News_list key={id} obj={data}  />
  })
  


    return (
        <section className='news' >
        <div className="container">
          <h6>Events</h6>
          <p>Events happening under Calicut Defence Trust and Care</p>
          
          
          <div className='home-event'>
  <div className="row">

  <div className="col-lg-4 event">

    <h6>PREVIOUS</h6>
    <Slider {...settings}> 
   {prva}
  </Slider>
    </div>

  <div className="col-lg-4 event">
  <h6>TODAY</h6>
  <Slider {...settings}> 
  {today}
  </Slider>
    </div>

    <div className="col-lg-4 event">
    <h6>UP-COMING</h6>
    <Slider {...settings}> 
   {uplista}
  </Slider>
    </div>
  
   
  </div>
   
  {/* <Slider {...settings}>  */}
 
  </div>
  {/* </Slider> */}


          {/* <div className="row">
            {news} */}
          {/* {data?data.news_list:[].map((data,id)=>{
      return <News_list className={"col-4 col-sm-4 col-md-4"}  />
    })} */}
    
            
          {/* </div> */}
          <button className='more-news'><Link to="event">More</Link></button>
        </div>
      </section>


    )
}

import React, { useState } from 'react';
import './profile.scss';
import { Button, Tab, Tabs, Alert, Modal,Form,  Row, Col, } from 'react-bootstrap';
import {Img,Rrazopay,RrazopayUserSubscripction}from '../../shared'
import paisa from 'paisa.js';
function Subscrib() {
    const [show, setShow] = useState(false);
    const [sucess, setSucess] = useState(false);
    const [inputForm, setForm] = useState({
        amount: 0.00,
   
    
      })
    const handleClose = () => {
        setShow(false)
        setSucess(false)
        setForm({amount:0.00})
    }
    const handleShow = () => setShow(true);
    let formChange = (event) => {
       
        const values = { ...inputForm };
        values['month'] = event.target.value
        values[event.target.name] = parseFloat((100*event.target.value)*100) //
     
        setForm(values)
    
      }
      const submitForm = () => {
        setSucess(true)

    }
      
    return (
        <div className='subscrib'>
            <Button className='Subscribe_btn' onClick={handleShow}>
            monthly Subscription
            </Button>

            <Modal className="subscrib_form" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>monthly subscription </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {sucess?
                    <div id="Success">
            <div className='icon'></div>
       <h2>monthly subscription  successfully  </h2>
       <p> please  check  your Email</p>
        </div>:<>
        <div className='Subscribe_list'>
                        <ul>
                            <li>
                                <span className='pakgName'>Basic  </span>
                                <span className='amount'> <i class="fas fa-rupee-sign"></i>100 <p>+Gst</p> </span>
                                <span className='validity'> 1 month</span>
                                <Form.Check type="radio" id="custom-switch1" value={1} name="amount" onChange={(e) => formChange(e)} />
                            </li>
                            <li>
                                <span className='pakgName'>Starter </span>
                                <span className='amount'> <i class="fas fa-rupee-sign"></i>300 <p>+Gst</p> </span>
                                <span className='validity'> 3 month</span>
                                <Form.Check type="radio" id="custom-switch1" value={3} name="amount" onChange={(e) => formChange(e)} />
                            </li>
                            <li>
                                <span className='pakgName'>Standard </span>
                                <span className='amount'> <i class="fas fa-rupee-sign"></i>600<p>+Gst</p> </span>
                                <span className='validity'> 6 month</span>
                                <Form.Check type="radio" id="custom-switch1" value={6} name="amount" onChange={(e) => formChange(e)} />
                            </li>

                            <li>
                                <span className='pakgName'>Premium </span>
                                <span className='amount'> <i class="fas fa-rupee-sign"></i>1200<p>+Gst</p> </span>
                                <span className='validity'> 12 month</span>
                                <Form.Check type="radio" id="custom-switch1" value={12} name="amount" onChange={(e) => formChange(e)} />
                            </li>
                        </ul>
                    </div>
                    <div className="total"> 
                    <span> Amount : {paisa.formatWithSymbol(inputForm.amount,2)}</span>
                    <span> Gst : 0 %</span>
                    <span> Total:{paisa.formatWithSymbol(inputForm.amount,2)}</span>
                    </div>
                    </>
                    
                    }


                </Modal.Body>
                <Modal.Footer>
                <RrazopayUserSubscripction validity={inputForm.month} type="Monthly MemberShip" amountt={inputForm.amount} />
                  
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Subscrib